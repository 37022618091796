<template>
  <div class="pt-release__withData">
    <pageheader title="软件发布管理">
      <div class="flex-class">
        <el-button type="primary" icon='el-icon-plus' @click="newRelease" v-if="isPublishVersion">发布新版本</el-button>
      </div>
    </pageheader>
		<div class="pt-release-table-div">
    <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="tableData">
      <el-table-column prop="version" label="主机软件版本">
        <template slot-scope="scope">
          <span :class='scope.row.status === 0 ? "default-color" : "cancel-color"'>{{scope.row.version}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createdDate" label="上线发布时间">
        <template slot-scope="scope">
          <span :class='scope.row.status === 0 ? "default-color" : "cancel-color"'>{{formatDateObject(scope.row.createdDate)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="versionText" label="版本说明">
        <template slot-scope="scope">
          <span :class='scope.row.status === 0 ? "default-color" : "cancel-color"'>{{scope.row.versionText}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="当前状态">
        <template slot-scope="scope">
          <span :class='scope.row.status === 0 ? "default-color" : "cancel-color"'>{{scope.row.status === 0 ? '提供下载' : '已停止'}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="isLatest" label="最新版本">
        <template slot-scope="scope">
          <span :class='scope.row.isLatest === 1 ? "default-color" : "cancel-color"'>{{scope.row.isLatest === 1 ? '是' : '否'}}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="" label="下载丨操作" width='180px'>
        <template slot-scope="scope">
          <div class="flex-class">
            <div :class='scope.row.status === 0 ? "edit-btn-class sure-color" : "edit-btn-class cancel-color"' @click="downloadRelease(scope.row)">下载</div>
            <template v-if="isStopDownload">
              <el-divider direction="vertical"></el-divider>
              <div :class='scope.row.status === 0 ? "edit-btn-class error-color" : "edit-btn-class sure-color"' @click="downloadstate(scope.row)">{{scope.row.status === 0 ? '停止下载' : '提供下载'}}</div>
            </template>
            <template v-if="isDelete">
              <el-divider direction="vertical"></el-divider>
              <div :class='scope.row.status === 0 ? "del-btn-class cancel-color" : "del-btn-class error-color"' @click="delRelease(scope.row)">删除</div>
            </template>
          </div>
        </template>
      </el-table-column>
    </el-table>
	</div>
    <ptPagination ref="ptPagination" @paginationchange='paginationchange'></ptPagination>
  </div>
</template>

<script>
import pageheader from '../base/pt-page-header/index'
import { getReleases, delReleases, updateReleases } from '../../utils/releaseService'
import { formatDateObject } from '../../utils/formatUtils/date'
import ptPagination from '../base/pt-pagination/index'
export default {
  name: 'pt-release__withData',
  components: {
    pageheader,
    ptPagination
  },
  data(){
      return{
          form: {
              example:{},
              pageable:{
                  page: 0,
                  size: 10,
                  sort: ['createdDate,desc']
              }
          },
          total: 0,
          roleList: [],
          tableData: []
      }
	},
	computed:{
		isStopDownload(){
			//是否可停止下载（超管才可进行此操作）
			return this.$store.getters.isSuporAdmin
		},
		//是否可发布新版本（超级管理员访客发布）
		isPublishVersion(){
			return this.$store.getters.isSuporAdmin
		},
		isDelete(){
			//是否可删除（超管才可进行此操作）
			return this.$store.getters.isSuporAdmin
		},
	},
  created (){
     
  },
  methods:{
      getData(){
           getReleases(this.form).then((res)=>{
                this.tableData = res.data
                this.$refs.ptPagination.totalMethod(res)
            })
      },
      formatDateObject(val){
          return formatDateObject(val)
      },
      paginationchange(pageable){
          this.form.pageable = pageable
          this.getData()
      },
      newRelease(){
          this.$router.push('/releaseadd')
      },
      downloadRelease(row){
          if (row.status === 0){
            let url = row.filePath
            window.open(this.$privateResourcesUrlDownload + url)
            // fetch(url).then(res => res.blob()).then(blob => {
            //     let a = document.createElement("a");
            //     a.href = URL.createObjectURL(blob)
            //     a.download = row.version + '_' + url.split('/')[url.split('/').length -1] //  // 下载文件的名字
            //     document.body.appendChild(a)
            //     a.click()
            // })
          }
      },
      downloadstate(row){
          let form = JSON.parse(JSON.stringify(row))
          form.status = (row.status === 0 ? 1 : 0)
          updateReleases(form).then(res=>{
              this.getData()
          }).catch(err=>{
                this.$message.error(err||'获取数据失败')
            })
      },
      delRelease(row){
        if (row.status === 0){
            return
        }
        this.$confirm('确认删除该版本?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            delReleases(row.id).then(res=>{
                this.$message({
                message: '删除成功',
                type: 'success'
                });
                this.form.pageable = {page: 0, size: 10,sort: ['createdDate,desc']}
                this.getData()
            }).catch(err=>{
                this.$message.error(err||'删除失败')
            })
        })
      }
  }
  
}
</script>
<style lang="less">
@import "~less/var.less";
.pt-release__withData{
  max-width: 100%;
  padding: 0 20px 20px 20px;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;
	box-sizing: border-box;
	.pt-release-table-div{
		display: flex;
		flex: 1;
		overflow-y: hidden;
	}
	.el-table{
		height: 100%;
		overflow: auto;
		padding-bottom: 20Px;
	}
  .flex-class{
      display: flex;
      width: 100%;
      height: 38PX;
			align-items: center;
  }
  .el-button{
      background-color: @mainColor;
  }
  .input-content-class{
      width: 200px;
  }
  .del-btn-class{
    cursor: pointer;
		display: flex;
		align-items: center;
  }
  .edit-btn-class{
    cursor: pointer;
    color: @mainColor;
		display: flex;
		align-items: center;
  }
  .cancel-color{
      color: @headerBackground;
  }
  .sure-color{
      color: @mainColorDark;
  }
  .error-color{
      color: @warningColor;
  }
  .default-color{
      color: @defaultFontColor;
  }
}
</style>