<template>
    <el-dialog class="is-dialog-class"
        :visible.sync="dialogVisible"
        :show-close='false'
        :title="title">
        <el-divider></el-divider>
        <el-form ref="inputForm" :model="form" size="medium" :rules='rules'>
            <el-form-item prop='inputValue' :label='inputTitle'>
                <div class="pt-input-class">
                    <el-input v-model="form.inputValue" :placeholder='"请输入" + inputTitle'></el-input>
                </div>
                
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <span slot="footer" class="dialog-footer">
            <el-button @click="hidden">取 消</el-button>
            <el-button type="primary" @click="sureClick">确认提交</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props:{
        title:{
            type: String,
            default: ''
        },
        inputTitle:{
            type: String,
            default: ''
        }
    },
    data(){
        return{
            dialogVisible: false,
            form:{
                inputValue: ''
            },
            rules:{
               inputValue:[
                   { required: true, message: '不能为空', trigger: 'blur'}
               ]
           }
        }
    },
    methods:{
        show(){
            this.dialogVisible = true
        },
        hidden(){
            this.dialogVisible = false
            this.form.inputValue = ''
            this.$refs.inputForm.resetFields()
        },
        sureClick(){
            this.$refs.inputForm.validate((valid) => {
                if (valid) {
                    this.$emit('submit', this.form.inputValue)
                } else {
                    return false
                }
            })
        }
    }
}
</script>
<style>
.el-form-item--medium .el-form-item__content, .el-form-item--medium .el-form-item__label {
    float: left !important;
}
.pt-input-class{
    /* width: 400px; */
}
</style>