<template>
<div>
  <pt-institutionsdetail class="pt-institutionsdetail__page"></pt-institutionsdetail>
</div>
</template>

<script>
import PtInstitutionsDetail from 'cps/pt-institutions/detail'
export default {
  name: 'pt-institutionsdetail__view',
  components: {
    'pt-institutionsdetail': PtInstitutionsDetail
  }
}
</script>

