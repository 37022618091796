<template>
  <div :class="[$style.upload,isShowUploadButton?'':$style.noButton]">
    <el-upload ref='upload' action='aaa' :show-file-list="true" :file-list="playUrls" :http-request="uploadSectionFile" :on-remove="onRemove" :multiple="multiple" :before-upload="beforeUpload" :on-preview="handlePreview" :limit='fileType && multiple?4:1'>
      <el-button size="small" type="primary" v-if="isShowUploadButton">{{btnText}}</el-button>
      <!-- <span v-if="fileType" style="font-size:12px;color:red;">（注意：只能上传{{multiple?'两或四':'一'}}个文件）</span> -->
    </el-upload>
  </div>
</template>

<script>
// import { ossUploadBigFileNotLab } from '@/utils/action'
import OssSectionUpload from '@/utils/oss-sectioning'
import { configsHost } from '../../utils/hostServices';
import request from "../../utils/request/index"
function getKey() {
  return request(`/api/sts?resourceName=log`, "GET");
}
export default {
  name: 'pt-upload-big-file',
  props: {
    objectId: {
      type: String,
      default: ''
    },
    sample: {
      type: Object
    },
    value: {
      type: [Array, Object, String],
      default: function () {
        return []
      }
    },
    resourceConfig: {
      type: String,
      default: ''
    },
    isPublic: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isPathUrlAddTime: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: String,
      default: '点击上传'
    },
    fileType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      progress: 0,
      playTime: [],
      playUrls: [],
      currentValue: this.getValue(),
      isUpload: false,
			isUploading:false
    }
  },
  computed: {
    resourceUrl () {
      return this.isPublic ? this.$publicResourcesUrl : this.$privateResourcesUrl
    },
		//是否显示上传按钮
		isShowUploadButton(){
			return this.multiple?true:(this.playUrls&&this.playUrls.length>0?false:true)

		}
  },
  watch: {
    fileType: {
      handler (val) {
        this.playUrls = []
      }
    },
    value: {
      handler: function (val) {
        if (val && JSON.stringify(val) !== '{}') {
					// this.getImageList(val)
          this.currentValue = val
        }
      },
			immediate: true,
			deep:true
    },
    playUrls (val) {
    },
    isUpload (val) {
			if(this.currentValue!==this.value){
				this.$emit('input', this.currentValue)
				this.$emit('isUpload', val)
		}
    }
  },
  created () {
  },
  methods: {
		abort(){
			this.$refs.upload.clearFiles(this.playUrls)
		},
    handlePreview (file) {
      if (this.playUrls.every(item => item.percentage === 100)) {
        this.$message.info('已全部上传完毕')
        return
      }
      this.playUrls.forEach((ele, idx) => {
        if (ele.name === file.name) {
          if (ele.percentage === 100) {
            this.$message.info('已上传完毕')
            return
          }
          if (ele.continueUpload) {
            this.$message.info('恢复上传')
            ele.client.multipartUpload((p) => {
              if (parseFloat((p * 100)) >= 100) {
                this.playUrls[idx].percentage = 100
              } else {
                this.playUrls[idx].percentage = parseFloat((p * 100))
              }
              if (this.playUrls.every(item => item.percentage === 100)) {
                this.isUpload = true
								this.isUploading=false

              }
            })
          } else {
            this.$message.info('暂停上传')
            ele.client.cancleUpload()
          }
          ele.continueUpload = !ele.continueUpload
        }
      })
    },
    beforeUpload (file) {
      const splitArr = file.name.split('.')
      const lastName = splitArr[splitArr.length - 1]
      // 这里可以判断上传文件的类型 ----todo list-----
      // if (this.fileType === 'fastq') {
      //   const afterName = splitArr[splitArr.length - 1]
      //   const afterName2 = splitArr[splitArr.length - 2]
      //   let fastqName = ''
      //   if (afterName2 === 'fastq' || afterName2 === 'fq') {
      //     fastqName = afterName2 + '.' + afterName
      //   } else {
      //     fastqName = afterName
      //   }
      //   if (this.value.length >= 1) {
      //     let every = this.value.every(item => item.name !== file.name)
      //     if (!every) {
      //       this.$message.error('文件不能相同或者文件名不能相同！')
      //       return false
      //     }
      //   }
      //   if (fastqName === 'fq.gz' || fastqName === 'fastq.gz' || fastqName === 'fastq' || fastqName === 'fq') {
      //     return true
      //   } else {
      //     this.$message.error('请选择相对应的文件类型！')
      //     return false
      //   }
      // } else if (this.fileType === 'VCF') {
      //   if (lastName === 'vcf') {
      //     return true
      //   } else {
      //     this.$message.error('请选择相对应的文件类型！')
      //     return false
      //   }
      // } else if (this.fileType === 'BAM') {
      //   if (this.value.length >= 2) {
      //     if (this.value[0].name === file.name) {
      //       this.$message.error('两个文件不能相同或者文件名不能相同！')
      //       return false
      //     }
      //   }
      //   if (lastName === 'bam') {
      //     return true
      //   } else {
      //     this.$message.error('请选择相对应的文件类型！')
      //     return false
      //   }
      // } else if (this.fileType === 'gz') {
      //   if (lastName === 'gz') {
      //     return true
      //   } else {
      //     this.$message.error('请选择相对应的文件类型！')
      //     return false
      //   }
      // } else {
      //   return true
      // }
    },
    getValue () {
      return this.multiple ? [] : {}
    },
    getChangeName () {
      let year = (new Date()).getFullYear()
      let month = (new Date()).getMonth() + 1
      month = month > 9 ? month : '0' + month
      let day = (new Date()).getDate()
      day = day > 9 ? day : '0' + day
      let hour = (new Date()).getHours()
      hour = hour > 9 ? hour : '0' + hour
      let min = (new Date()).getMinutes()
      min = min > 9 ? min : '0' + min
      let second = (new Date()).getSeconds()
      second = second > 9 ? second : '0' + second
      return '' + year + month + day + hour + min + second
    },
    infoClient (changeFileName, params, url) {
      let self = this
      let OSS = require('ali-oss')
      getKey().then((res)=>{
        let data = res.data
        let ossConfig = {
          region: data.region,
          accessKeyId: data.accessKeyId,
          accessKeySecret: data.accessKeySecret,
          bucket: data.bucket,
          stsToken: data.stsToken
        }
        self.dealUploadData(changeFileName, params, url, new OSS(ossConfig))
      })
    },
    dealUploadData (changeFileName, params, url, config) {
			this.isUpload=false
			this.isUploading=true
      let func = new OssSectionUpload(changeFileName, params.file, url, config)
      this.playUrls.push({
        url: this.resourceConfig + '/' + this.objectId + '/' + changeFileName,
        name: changeFileName,
        client: func,
        status: 'uploading',
        percentage: 0,
        continueUpload: false
      })
      func.multipartUpload((p, fileName) => {
        this.playUrls.forEach((ele, idx) => {
          if (fileName === ele.name) {
            if (parseFloat((p * 100)) >= 100) {
              this.playUrls[idx].percentage = 100
            } else {
              this.playUrls[idx].percentage = parseFloat((p * 100))
            }
          }
        })
        if (this.playUrls.every(item => item.percentage === 100)) {
          this.isUpload = true
					this.isUploading=false
        }
      })
      
    },
    async uploadSectionFile (params) {
      let changeFileName =  this.getChangeName() + '_' + params.file.name
      this.playTime.push({
        name: changeFileName,
        time: (new Date()).valueOf()
      })
      this.$emit('playTime', this.playTime)
      let url = this.resourceConfig + '/' + this.objectId + '/' + changeFileName
      this.infoClient(changeFileName, params, url)
      let data = {
        url,
        name: changeFileName
      }
      if (this.multiple) {
        this.currentValue.push(data)
      } else {
        this.currentValue = data
      }
      // let url = await this.$upload(this.resourceConfig, params.file, 'file', ossUploadBigFileNotLab, this.isPathUrlAddTime, this.isPublic, this.objectId, changeFileName)
      // this.$emit('input', this.currentValue.url)
      
    },
    getImageUrl (data) {
      return {
        status: 'uploading',
        name: data.name,
        percentage: this.progress
      }
    },
    getImageList (arr) {
      if (!Array.isArray(arr)) {
        arr = [arr]
      }
      this.playUrls = arr.map(e => {
        return this.getImageUrl(e)
      })
    },
    clearFiles () {
      this.$refs.upload.clearFiles()
    },
    onRemove (file, fileList) {
      if (this.multiple) {
        for (let i = 0; i < this.playUrls.length; i++) {
          if (this.playUrls[i].name === file.name) {
            this.playUrls[i].client.cancleUpload()
            this.currentValue.splice(i, 1)
            this.playUrls.splice(i, 1)
            break
          }
        }
        this.$emit('input', this.currentValue)
      } else {
				this.isUploading=false
        this.$emit('input', '')
        this.playUrls[0].client.cancleUpload()
        this.playUrls = []
      }
    }
	}
}
</script>

<style lang="less" module>
.upload {
  :global {
    :focus {
      outline: none;
    }
    .el-upload {
      width: 100%;
      text-align: left;
    }
    .el-progress {
      flex: 1;
      display: flex;
      margin-right: 20px;
    }
  }
  .progress {
    display: flex;
    align-items: center;
  }
}
.noButton{
	:global{
		.el-upload--text{
			display: none;
		}
	}
}
</style>
