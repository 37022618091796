import Vue from "vue";
import axios from "axios";
import urlApiJson from "./urlApi.json";
// 调用oss中的公用文件
function getResourceJson(path) {
  let url = window.location.origin;
  return axios.get(`${url}/${path}`).then((res) => {
    return res.data;
  });
}
let i = 0;
async function api() {
  i++;
  // 这里是构造一个异步方法，等待获取json中的内容
  // 获取项目的api配置，api配置的json文件需上传到下面调用的对应目录
  try {
    let urlHost = window.location.origin;
    let response =
      urlHost.indexOf("localhost") > 0 || urlHost.indexOf("808") > 0 ?
      urlApiJson :
      (await getResourceJson("urlconfig/api.json")) || {};
    // let response = urlApiJson、
    let config = new Promise((resolve) => {
      let configRes = null;
      var env = process.env;
      for (let key in response) {
        if (key !== "baseUrl") {
          env[key] = response[key];
        }
      }
      configRes = {
        api_server: response.baseUrl,
        api_server_mobile: response.baseUrl,
        env: env
      };
      resolve(configRes);
    });
    return config;
  } catch (error) {
    // 如果请求失败将再次发起请求，如果失败10次以上，将询问用户是否继续重试
    if (i <= 10) {
      api();
    } else {
      Vue.prototype
        .$confirm("请求服务器接口失败，请重试,或退出系统", "重试提示", {
          confirmButtonText: "重试",
          cancelButtonText: "退出",
          type: "warning"
        })
        .then(() => {
          api();
        })
        .catch(() => {
          window.opener = null;
          window.close();
          window.location.href = "about:blank";
        });
    }
  }
}

export default api();