// 将参数序列化到form-data中
export default function (http) {
  let newHttp = function (...rq) {
    let [, , data, extra] = rq
    // 设置header
    // rq[3] = extra.headers = {
    //   ...extra.headers,
    //   'content-type': 'application/x-www-form-urlencoded',
    //   'Access-Control-Allow-Credentials': '*'
    // }
    // form-data
    let formData = new FormData()
    for (let i in data) {
      formData.append(i, data[i])
    }
    rq[2] = formData
    extra = {
      ...extra,
      options: {
        data
      }
    }
    
    return http(...rq, extra)
  }
  return newHttp
}
