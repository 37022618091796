import env from "api"
import router from "./router"
export let config = {
  auth: require("./driver"),
  http: require("@websanova/vue-auth/drivers/http/axios.1.x"),
  router: router,
  // 定义登录接口，并且在登录的时候自动获取用户信息
  loginData: {
    // 平台单点登录后，使用authenticate接口模拟登录
    url: '/api/account',
    method: "GET",
    rememberMe: true,
    fetchUser: true
  },
  // 定义登出接口，目前没有对服务器发起请求
  logoutData: {
    url: '/api/logout',
    method: "POST",
    makeRequest: false,
    // redirect: '/authorize',
    // success(response, params) {
    //   let redirect = response.data;
    //   var domain = document.domain;
    //   var port = location.port;
    //   let url = window.location.protocol + '//' + domain + (port ? ":" + port : '')
    //   if (redirect) {
    //     // 如果服务端返回了跳转链接，跳转到服务端返回的目标地址
    //     window.location.href = redirect.logoutUrl + '?redirect_uri=' + url;
    //   } else {
    //     window.location.href = params.redirect;
    //   }
    // },
    // error() {},
    redirect: '/login',
  },
  authRedirect: false,
  // authRedirect: {
  //   path: "/authorize",
  // },
  // 定义获取用户信息的接口
  fetchData: {
    url: "/api/account",
    method: "GET",
    enabled: true,
    params: {},
    success(params) {
      localStorage.setItem('UserInfo', JSON.stringify(params.data))
        // console.log("vue-params", params)
        //   let { header } = params
        // 保存登录凭证
        // const st = header.
    },
    error(params) {}
  },
  refreshData: {
    // 直接使用authenticate接口，达到刷新token的效果 /uaa/api/refresh  /uaa/api/user/sso/authenticate
    url: "/uaa/api/refresh",
    method: "GET",
    // 暂不开启自动刷新
    enabled: false,
    interval: 1
  },
  // 自定义用户数据解析
  parseUserData: function(data) {
    // 记住当前用户的登录方式
    return data
  }
}
export default config