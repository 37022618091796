<template>
    <div class="pt-user__withData">
        <pageheader title="系统用户管理">
            <div class="flex-class">
                <el-button type="primary" icon='el-icon-plus' @click="addUser">新增用户</el-button>
                <!-- <ptbutton icon='el-icon-plus' title="新增用户" @click="addUser"></ptbutton> -->
                <el-form :model="form" :inline="true" label-width="73px" class="user-select-class">
                        <el-form-item label="用户角色:">
                        <el-select v-model="form.groups" clearable placeholder="请选择">
                        <el-option
                            v-for="item in roleList"
                            :key="item.id"
                            :label="item.displayName"
                            :value="item.id">
                        </el-option>
                        </el-select>
                    </el-form-item>
                  </el-form>
                  <div class="input-content-class">
                      <el-input class="input-class" placeholder='请输入关键字' v-model="form.keyword" :clearable="true" @clear="searchClear"></el-input>
                  </div>
                  <el-button class="search-class" type="primary" @click="search">搜索</el-button>
            </div>
        </pageheader>
				<div class="pt-user-table-div">
         <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="tableData">
              <el-table-column prop="username" label="用户名"></el-table-column>
              <el-table-column prop="mobile" label="手机号"> </el-table-column>
              <el-table-column prop="fullName" label="姓名"> </el-table-column>
              <el-table-column prop="gender" label="性别">
                <template slot-scope="scope">
                    <span v-if="scope.row.gender === 'SECRET'">保密</span>
                    <span v-if="scope.row.gender === 'MALE'">男</span>
                    <span v-if="scope.row.gender === 'FEMALE'">女</span>
                </template>
              </el-table-column>
              <el-table-column prop="serialNumber" label="员工编号"> </el-table-column>
              <el-table-column prop="type" label="角色"> 
                  <template slot-scope="scope">
                      <span>{{scope.row.groupDisplayNames.toString()}}</span>
                  </template>
              </el-table-column>
              <el-table-column prop="createdDate" label="创建时间">
                  <template slot-scope="scope">
                      <span>{{formatDateObject(scope.row.createdDate)}}</span>
                  </template>
              </el-table-column>
              <el-table-column prop="lastModifiedDate" label="最近编辑">
                   <template slot-scope="scope">
                      <span>{{formatDateObject(scope.row.lastModifiedDate)}}</span>
                  </template>
              </el-table-column>
              <el-table-column prop="" label="操作">
                  <template slot-scope="scope">
                      <div class="flex-class">
                          <div class="del-btn-class" @click="delUser(scope.row)">删除</div>
                          <el-divider direction="vertical"></el-divider>
                          <div class="edit-btn-class" @click="editUser(scope.row)">编辑</div>
                      </div>
                  </template>
              </el-table-column>
         </el-table>
				</div>
          <ptPagination ref="ptPagination" @paginationchange='paginationchange'></ptPagination>
    </div>
</template>

<script>
import pageheader from '../base/pt-page-header/index'
import { getUsers, getGroupList, delGroupDetail } from '../../utils/userService'
import { formatDateObject } from '../../utils/formatUtils/date'
import ptPagination from '../base/pt-pagination/index'
export default {
  name: 'pt-user__withData',
  components: {
    pageheader,
    ptPagination
  },
  data(){
      return{
          form: {
              pageable:{
                  page: 0,
                  size: 10
              }
          },
          total: 0,
          roleList: [],
          tableData: []
      }
  },
  created (){
     this.getGroup()
  },
  methods:{
		searchClear(){
			this.form.pageable.page = 0
			if (this.form.groups === ''){
					this.$delete(this.form,'groups')
			}
			this.getData()
		},
      getData(){
           getUsers(this.form).then((res)=>{
                this.tableData = res.data
                this.$refs.ptPagination.totalMethod(res)
            })
      },
      getGroup(){
        getGroupList().then((res)=>{
            this.roleList = res.data
        })
      },
      formatDateObject(val){
          return formatDateObject(val, 'date')
      },
      paginationchange(pageable){
          this.form.pageable = pageable
          this.getData()
      },
      addUser(){
          this.$router.push('/useredit?type=add')
      },
      search(){
          this.form.pageable.page = 0
          if (this.form.groups === ''){
              this.$delete(this.form,'groups')
          }
          this.getData()
      },
      delUser(row){
        this.$confirm('确认删除该用户?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
         // 删除用户
            delGroupDetail(row.id).then(res=>{
                this.$message({
                message: '删除成功',
                type: 'success'
                });
                this.form.pageable = {page: 0, size: 10}
                this.getData()
            }).catch(err=>{
                this.$message.error(err||'删除失败')
            })
        })
      },
      editUser(row){
          this.$router.push('/useredit?type=edit&id=' + row.id)
      }
  }
  
}
</script>
<style lang="less">
@import "~less/var.less";
.pt-user__withData{
max-width: 100%;
  padding: 0 20px 20px 20px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding-bottom: 20px;
	height: 100%;
	.pt-user-table-div{
		display: flex;
		flex: 1;
		overflow-y: hidden;
	}
	.el-table{
		height: 100%;
		overflow: auto;
		padding-bottom: 20Px;
	}
		.el-form-item{
			margin-bottom:0
		}
  .flex-class{
      display: flex;
			align-items: center;
      width: 100%;
      height: 38PX;
  }
  .el-button{
      background-color: @mainColor;
  }
  .input-content-class{
      width: 200px;
      margin-right: 20px;
  }
  .del-btn-class{
    cursor: pointer;
    color: @warningColor;
  }
  .edit-btn-class{
    cursor: pointer;
    color: @mainColor;
  }
  .search-class{
    margin-right: 0;
  }
  .user-select-class{
    margin-right: 20px;
    margin-left: auto !important;
  }
  
}
</style>