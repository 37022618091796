<template>
  <div :class="$style['page-container']">
  <wt-view></wt-view>
  </div>
</template>

<script>
export default {
  name: 'component-nav',
  computed: {
    title (){
      return this.$route.meta.title
    },
    hasBack (){
      return this.$route.meta.hasBack
    },
    menuList (){
      return this.$t('menu')
    }
  },
  data () {
    return {
    }
  },
  
  created () {

  }
}
</script>
<style module lang="less">
@import "~less/var.less";
.page-container{
  overflow: hidden;
  height: 100%;
  // background-color: @backgroundColor;
  display: flex;
  flex-direction: column;
  box-sizing: border-box
}
</style>
