<template>
    <div class="pph-content-class">
        <div class="title-class">{{title}}</div>
        <slot />
    </div>
</template>
<script>
export default {
    props:{
        title:{
            type: String,
            default: ''
        }
    }
}
</script>
<style lang="less">
.pph-content-class{
    display: flex;
    padding-top: 20px;
    align-items: center;
    width: 100%;
    .title-class{
        font-weight: 600;
        // margin-left: 20px;
        margin-right: 20px;
				margin-bottom: 10px;
        min-width: 110PX;
    }
		
}
</style>