<template>
  <div>
    <div :class="$style[classes]">
      <div class="left-div">
        <div class="hrv-tab">
          <img :src="require('@/assets/images/HRV-logo.png')" />
        </div>
        <!-- <div>客户机构管理</div> -->
      </div>
      <div :class="$style['right-div']">
        <div>{{date}}</div>
						<el-dropdown trigger="click">
							<div class="user-message"  style="cursor:pointer;"><img :src="require('@/assets/images/user-img.png')"/>{{name||'管理员'}}</div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item @click.native="goEditPass" v-if="canEditPassword">修改密码</el-dropdown-item>
								<el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
        <!-- <div :class="$style['login-admin']" style="cursor:pointer;">{{name||'管理员'}}</div>
        <ul :class="$style['pt-com-header__content__logout']"  style="cursor:pointer;">
          <li @click="goEditPass" v-if="canEditPassword">修改密码</li>
          <li @click="logout">退出登录</li>
        </ul> -->
      </div>
    </div>
    <el-dialog :visible.sync="isEditPassword" width="50%" :class="$style['edit-password-dialog']" @close="closeDialog">
      <div slot="title" :class="$style['dialog-title']">
        <img :src="require('@/assets/images/password-self.png')" />修改密码</div>
      <edit-password-dialog v-if="isEditPassword" @closeDialog="closeDialog"></edit-password-dialog>
    </el-dialog>
  </div>
</template>

<script>
// 组件class前缀
const prefixCls = 'pt-com-header'
import { formatDateObject } from 'formats/date'
import editPasswordDialog from "./edit-password-dialog"
export default {
	name: 'pt-com-header',
	components:{
		editPasswordDialog
	},
  computed: {
    classes () {
      return `${prefixCls}`
		},
		//是否可以修改当前用户密码(超级管理员不能修改自己的密码)
		canEditPassword(){
			return !this.$store.getters.isSuporAdmin
		}
	},
	props:{
		name:String
	},
  data(){
    return{
			date: "2021-08-20 5:20",
			show:false,
			isEditPassword:false
    }
  },
  methods:{
    closeDialog (){
      this.isEditPassword = false
    },
		goEditPass(){
			this.isEditPassword=true
		},
    toggle (){
      this.show = !this.show
    },
    logout (){
      this.$confirm('此操作将退出当前登录用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$auth.logout()
      })
      },
      getHeaderTime () {
        this.date = formatDateObject(new Date().getTime(), 'shortDatetime')
      }
  },
    created () {
        this.getHeaderTime()
        this.timer=setInterval(this.getHeaderTime,1000)
    },
    destroyed () {
        clearInterval(this.timer)
    }
}
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
.pt-com-header{
  height: 60px;
  background-color: #13C5AF;
  width: 100%;
  display: flex;
  justify-content: space-between;
  :global{
    .left-div{
      .hrv-tab{
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 20px;
				img{
					width: 200px;
				}
      }
    }
  }
}

.login-admin{
		padding: 0 20px;
		align-items: center;
		position: relative;
		cursor: pointer;
      }

.pt-com-header__content__logout{
		position: absolute;
		right: 0;
		top:50px;
		width:20%;
		max-width: 200Px;
		z-index: 30;
		box-shadow:none;
		// color:@mainColor;
		// border-top:2px solid @mainColor;
		font-size: 14px;
		line-height: 40px;
		background:#fff;
		color: #20A1FF;
		list-style: none;
		padding-left: 0;
		padding: 8px 10px;
		border-radius:5px;
		margin:0;
		li{
			+li{
				border-top: solid 1PX #979797
			}
		}
	}

.right-div{
      height: 100%;
      display: flex;
      align-items: center;
			color: #fff;
			>div{
				margin-right: 20px;
			}
			img{
				height: 25px;
				margin-right: 10px;
			}
			:global{
				.user-message{
					display: flex;
					align-items: center;
				}
			.el-dropdown{
				font-size: 17px;
				color: #fff;
			}
			}
    }
.edit-password-dialog{
	:global{
		.el-dialog{
	min-width: 400PX;
	max-width: 600PX;
	height: auto!important;
		}
		.el-dialog__header{
			padding: 20PX 30PX 10PX
		}
		.el-dialog__body{
			padding: 10PX 30PX 30Px;
			overflow: hidden;
			.el-input__inner{
				border: 1px solid #606266
			}
		}
	}
}
.dialog-title{
	display: flex;
	justify-content: flex-start;
		align-items: center;
		font-weight: 600;
		color: #2c3e50;
	img{
		width: 50PX;
		margin-right: 10px
	}
}
</style>
