<template>
<div>
  <pt-release class="pt-release__page"></pt-release>
</div>
</template>

<script>
import PtRelease from 'cps/pt-release/withData'
export default {
  name: 'pt-release__view',
  components: {
    'pt-release': PtRelease
  }
}
</script>

