import Vue from 'vue'

let tempCheckpointObj = {}
export default class OssSectionUpload {
  constructor(fileName, file, url, ossConfig) {
      this.fileName = fileName
      this.file = file
      this.url = url
      this.client = ossConfig
    }
    // 上传文件
  async multipartUpload(callback) {
      try {
        // object-key可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
        let that = this
        await this.client.multipartUpload(this.url, this.file, {
          partSize: 1000 * 1024,
          progress: function(p, checkpoint) {
            // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
            tempCheckpointObj[that.fileName] = checkpoint
            callback(p, that.fileName)
          },
          checkpoint: tempCheckpointObj[that.fileName] || 0,
          meta: { year: (new Date()).getFullYear(), people: Vue.prototype.$auth.user().userName }
        }).then(res => {
          Vue.prototype.$message.success('上传成功')
        }).catch((error) => {
          if (this.client.isCancel()) {
            Vue.prototype.$message.error('上传失败')
          } else {
            Vue.prototype.$message.error('网络异常')
          }
        })
      } catch (e) {}
    }
    // 取消上传
  cancleUpload() {
    this.client.cancel()
  }
}