<template>
    <el-card>
        <div class="vr-flex-class">
            <div class="version-record-class version-record-first">
                <div class="vr-flex-class vr-top-content-class">
                    <div class="sub-title-class">上位机版本更新记录</div>
                    <el-button class="vr-add-btn-class" type="primary" icon='el-icon-plus'
                        @click="addVersionRecord('device')">手动记录</el-button>
                </div>
                <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="tableData">
                    <el-table-column prop="softwareVersion" label="软件版本"> </el-table-column>
                    <el-table-column prop="createdDate" label="更新时间">
                        <template slot-scope="scope">
                            <span>{{formatDateObject(scope.row.createdDate)}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="line-class"></div>
            <div class="version-record-class">
                <div class="vr-flex-class vr-top-content-class">
                    <div class="sub-title-class">终端版本更新记录</div>
                    <el-button class="vr-add-btn-class" type="primary" icon='el-icon-plus'
                        @click="addVersionRecord('terminal')">手动记录</el-button>
                </div>
                <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="tableData2">
                    <el-table-column prop="terminalSn" label="终端序列号"> </el-table-column>
                    <el-table-column prop="hardwareVersion" label="硬件版本"> </el-table-column>
                    <el-table-column prop="softwareVersion" label="软件版本"> </el-table-column>
                    <el-table-column prop="createdDate" label="更新时间">
                        <template slot-scope="scope">
                            <span>{{formatDateObject(scope.row.createdDate)}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- <inputDialog ref="inputDialog" :type="createVersionType" :title='createVersionTitle' inputTitle='硬件版本编号' @submit='addVR' /> -->
        <el-dialog :title='createVersionTitle' :visible.sync="showCreateDialog"
            v-if="showCreateDialog" class="version-dialog-class" width="40%">
            <el-divider></el-divider>
            <el-form ref="versionForm" :model="form" size="medium" :rules='rules'
                label-width="107px">
                <template v-if="isTerminalType">
                    <el-form-item prop='terminalSn' label='终端序列号：'>
                        <div class="pt-input-class">
                            <el-input v-model="form.terminalSn" placeholder='请输入终端序列号'></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item prop='hardwareVersion' label='硬件版本号：'>
                        <div class="pt-input-class">
                            <el-input v-model="form.hardwareVersion"
                                placeholder='请输入硬件版本号'></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item prop='softwareVersion' label='软件版本号：'>
                        <div class="pt-input-class">
                            <el-input v-model="form.softwareVersion"
                                placeholder='请输入软件版本号'></el-input>
                        </div>
                    </el-form-item>
                </template>
                <el-form-item prop='softwareVersion' label='软件版本号：' v-else>
                    <div class="pt-input-class">
                        <el-input v-model="form.softwareVersion" placeholder='请输入软件版本号'></el-input>
                    </div>
                </el-form-item>
            </el-form>
            <el-divider class="divider-bottom"></el-divider>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="sureClick"
                    :loading="loadingInstance">确认提交</el-button>
            </span>
        </el-dialog>
    </el-card>
</template>

<script>
import { hardwareHost, addHardwareHost, softwareHost } from '../../../utils/hostServices'
import { formatDateObject } from '../../../utils/formatUtils/date'
import inputDialog from '../../base/pt-input-dialog/input-dialog'
import { Loading } from 'element-ui'
export default {
    components: {
        inputDialog
    },
    props: {
        item: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    data () {
        return {
            loadingInstance: null,
            tableData: [],
            tableData2: [],
            createVersionType: '',
            showCreateDialog: false,
            form: {},
            rulesTerminal: {
                hardwareVersion: [
                    { required: true, message: '硬件版本号不能为空', trigger: 'blur' }
                ],
                softwareVersion: [
                    { required: true, message: '软件版本号不能为空', trigger: 'blur' }
                ],
                terminalSn: [
                    { required: true, message: '终端序列号不能为空', trigger: 'blur' }
                ]
            },
            ruleDevice: {
                softwareVersion: [
                    { required: true, message: '软件版本号不能为空', trigger: 'blur' }
                ]
            }
        }
    },
    created () {
        this.getData()
        this.getData2()
    },
    computed: {
        id () {
            return this.$route.query.id
        },
        isTerminalType () {
            return this.createVersionType === 'terminal'
        },
        createVersionTitle () {
            return this.isTerminalType ? '新增终端版本记录' : '新增上位机版本记录'
        },
        rules () {
            return this.isTerminalType ? this.rulesTerminal : this.ruleDevice
        }
    },
    methods: {
        sureClick () {
            this.$refs['versionForm'].validate((valid) => {
                if (valid) {
                    this.loadingInstance = true
                    let api = this.isTerminalType ? 'addHardwareHost' : 'addDeviceVersions'
                    let data = {
                        deviceBaseInfoId: this.id,
                        ...this.form
                    }
                    this.$store.dispatch(api, data).then(res => {
                        this.$nextTick(() => {
                            this.loadingInstance = false;
                        });
                        if (this.isTerminalType) {
                            this.getData2()
                        } else {
                            this.getData()
                        }
                        this.$emit('upload')
                        this.showCreateDialog = false
                    }).catch(err => {
                        this.$message.error(err || '新建版本失败')
                    })
                }
            })
        },
        cancel () {
            this.showCreateDialog = false
        },
        getData () {
            let data = {
                example: {
                    deviceBaseInfoId: this.id
                }
            }
            this.$store.dispatch('getDeviceVersions', data).then(res => {
                this.tableData = res
            }).catch(err => {
                this.$message.error(err || '获取上位机版本更新记录失败')
            })
        },
        getData2 () {
            let data = {
                example: {
                    deviceBaseInfoId: this.id
                }
            }
            this.$store.dispatch('getTerminalsVersions', data).then(res => {
                this.tableData2 = res
            }).catch(err => {
                this.$message.error(err || '获取终端版本更新记录失败')
            })
        },
        formatDateObject (val) {
            return formatDateObject(val)
        },
        addVersionRecord (type) {
            this.createVersionType = type
            this.showCreateDialog = true
        },
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.version-dialog-class{
    .el-dialog__body{
        padding: 0 20px;
        overflow: hidden;
        .el-divider--horizontal{
            margin: 15Px 0;
        }
        .divider-bottom{
            margin-top: 0;
        }
    }
    .el-dialog__header{
        text-align: left;
    }
}
.vr-flex-class{
    display: flex;
}
.line-class{
    background-color: #DCDFE6;
    width: 1px;
    min-width: 1px;
    height: auto;
}
    .vr-top-content-class{
        align-items: center;
    }
    .vr-add-btn-class{
        margin-left: 20px !important;
    }
.version-record-class{
    width: 65%;
    padding: 20px;
  .sub-title-class{
        text-align: left;
        font-size: 14px;
        font-weight: 600;
				line-height: 40Px;
    }
  .el-button{
      background-color: @mainColor;
  }
}
.version-record-first{
    width: 35%;
}
</style>