<template>
    <div class="describe-content-class">
        <div class="sub-title-class">主机信息</div>
        <div class="describe-flex-class">
            <div class="describe-half-class">
                <div class="describe-flex-class">
                    <div class="title-class">产品型号:</div>
                    <div class="desc-class">{{item.model}}</div>
                </div>
                <div class="describe-flex-class">
                    <div class="title-class">主机序列号:</div>
                    <div class="desc-class">{{item.sn}}</div>
                </div>
                <!-- <div class="describe-flex-class">
                    <div class="title-class">出厂硬件版本:</div>
                    <div class="desc-class">{{item.initHardwareVersion}}</div>
                </div>
                <div class="describe-flex-class">
                    <div class="title-class">出厂软件版本:</div>
                    <div class="desc-class">{{item.initSoftwareVersion}}</div>
                </div> -->
                <div class="describe-flex-class">
                    <div class="title-class">主机用户名:</div>
                    <div class="desc-class">{{item.userName}}</div>
                </div>

                <div class="describe-flex-class">
                    <div class="title-class">主机密码:</div>
                    <div class="desc-class">{{item.password}}</div>
                </div>
            </div>
            <div class="describe-half-class">
                <div class="describe-flex-class">
                    <div class="title-class">主机运行状态:</div>

                    <div class="desc-class desc-flex-class">
                        <div :class="item.status === 0 ? 'normal-class' : 'warning-class'">
                            {{item.status === 0 ? '正常': '异常'}}</div>
                        <div>{{formatDateObject(item.lastModifiedDate)}}</div>
                    </div>
                </div>
                <div class="describe-flex-class">
                    <div class="title-class">终端数量:</div>
                    <div class="desc-class">{{item.terminalCount}}</div>
                </div>
                <!-- <div class="describe-flex-class">
                    <div class="title-class">当前硬件版本:</div>
                    <div class="desc-class">{{item.lastHardwareVersion}}</div>
                </div> -->
                <div class="describe-flex-class">
                    <div class="title-class">当前软件版本:</div>
                    <div class="desc-class">{{item.lastSoftwareVersion}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { formatDateObject } from '../../../utils/formatUtils/date'

export default {
    props: {
        item: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    data () {
        return {

        }
    },
    mounted () {

    },
    methods: {
        formatDateObject (val) {
            return formatDateObject(val, 'full')
        }
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.describe-content-class{
    text-align: left;
    .sub-title-class{
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .describe-flex-class{
        display: flex;
        margin-bottom: 10px;
    }
    .describe-half-class{
        width: 50%;
        font-size: 14px;
        .title-class{
            width: 30%;
        }
        .desc-class{
            width: 70%;
        }
        .desc-flex-class{
            display: flex;
        }
        .normal-class{
            color: @mainColor;
            width: 40px;
        }
        .warning-class{
            color: @warningColor;
            width: 40px;
        }
    }
}
</style>