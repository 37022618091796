<template>
  <div id="app">
    <router-view :is="curView" />
  </div>
</template>
<script>
import AppMain from '@/layout'
import { getMenuList } from '../src/utils/action'
export default {
  data(){
    return {
      curView:null
    }
  },
  components: {
    ...AppMain
	},
	computed:{
		//是否展示首页（只有超级管理员和系统管理员）
		showHomePage(){
			return this.$store.getters.isSuporAdmin||this.$store.getters.isSystemAdmin
		}
	},
  watch:{
    $route :{
      handler:function (val){
				if(val.name==="Home"||val.name==='Index'){
					if(this.$store.getters.isUser){
						this.$router.replace("/release")
						return
					}
					if(!this.showHomePage){
					this.$router.replace("/institutions")
					return
					}
				}
        // 匹配视图
        let tp = this.$route.meta.view || 'pc'
        if (tp in AppMain) {
          this.curView = tp || null
        } else {
          console.error(
            `layout view ${tp} is unavailable ,make sure ${tp}.vue existed in src/layout and configured in src/layout/index.js`
          )
        }
      },
      deep:true,
      immediate:true
    },
    //权限变更，重新获取菜单，接通后打开
    '$auth.watch.authenticated':{
      handler:function (val){
        if(val){
          this.getMenus()
        }
      },
      immediate:true
    }
  },
  methods:{
    /** 获取菜单，接通数据后打开**/
    getMenus (){
      this.i++
      getMenuList().then(res => {
        this.menuSuccess = true
        let menuList = res.data
        menuList.forEach(etem => {
          this.setMenuItem(etem)
        })
        this.$store.state.menuList = menuList
      }).catch(err => {
        if(this.i < 10){
          this.getMenus()
        }else{
          this.$message.error('请求菜单失败，请稍后重试')
        }
        console.log('err++', err)
      })
    },
    setMenuItem (item){
      item.leftIcon = item.icon ? `iconfont ${item.icon}` : null
      item.text = item.displayName
      item.action = item.url ? item.url : null
      item.children = item.subMenus
      item.icon && delete item.icon
      item.url && delete item.url
      item.enable = true
      if(item.subMenus){
        let subMenus = item.subMenus
        subMenus.forEach(etem => {
          this.setMenuItem(etem)
        });
      }
    }
	},
	beforeMount(){
	}
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
	overflow-y: hidden;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
