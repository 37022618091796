export let departmentEnumList= [
        {
          id: 'CLINIC',
          label: '门诊科'
        },
        {
          id: 'PSYCHIATRY',
          label: '精神科'
        },
        {
          id: 'PSYCHOLOGY',
          label: '心理科'
        },
        {
          id: 'NEUROLOGY',
          label: '神经内科'
        },
        {
          id: 'PAIN_TREATMENT',
          label: '疼痛科'
        },
        {
          id: 'ENDOCRINOLOGY',
          label: '内分泌科'
        },
        {
          id: 'COMMUNITY',
          label: '社区医院'
        },
        {
          id: 'PHYSICAL',
          label: '体检中心'
    }
]