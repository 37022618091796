import Vue from "vue";
import Vuex from "vuex";
import actions from './actions'
import getters from "./getter"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menuList: []
  },
	mutations: {},
	actions,
	getters
});
