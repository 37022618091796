<template>
  <pt-address-select class="pt-address-select__withData" :value="crrentValue" :provincesList="provincesList" :cityList="cityList" :districtList="districtList" @provinceChange="provinceChange" @cityChange="cityChange" @getValue="getValue" @districtChange="districtChange" ref="addressIndex" :propFilds="filds" :disabledObj="disabledObj" :disabled="disabled" :required="required" :validate-text="validateText" :clearable="clearable" :clearableObj="clearableObj" :showElement="showElement" :requiredObj="requiredObj" :districtLoading="districtLoading" :provinceLoading="provinceLoading" :cityLoading="cityLoading"></pt-address-select>
</template>

<script>
import PtAddressSelect from './index'
import { isEmptyObject } from 'formats/formatUtils'
import { getAddress } from '../../../utils/addressService'
export default {
  name: 'pt-address-select__withData',
  components: {
    'pt-address-select': PtAddressSelect
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      }
    },
    // 是否需要验证
    required: {
      type: Boolean,
      default: false
    },
    // 自定义传入的字段名
    propFilds: {
      type: Object,
      default: function () {
        return {
          provinceName: 'provinceName',
          cityName: 'cityName',
          districtName: 'districtName',
          provinceGbCode: 'provinceGbCode',
          cityGbCode: 'cityGbCode',
          districtGbCode: 'districtGbCode',
          postAddress: 'postAddress'
        }
      }
    },
    disabledObj: {
      type: Object,
      default: function () {
        return {
          province: false,
          city: false,
          district: false,
          postAddress: false
        }
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validateText: {
      type: Object,
      default: function () {
        return {
          province: '收货人地址：省',
          city: '收货人地址：市',
          district: '收货人地址：区',
          address: '收货人详细地址'
        }
      }
    },
    requiredObj: {
      type: Object,
      default: function () {
        return {
          province: false,
          city: false,
          district: false,
          address: false
        }
      }
    },
    clearable: {
      type: Boolean,
      default: false
    },
    clearableObj: {
      type: Object,
      default: function () {
        return {
          province: false,
          city: false,
          district: false,
          address: false
        }
      }
    },
    showElement: {
      type: [Object, String]
    },
    labelValue: {
      type: String
    },
    axiosApi: {
      type: String,
      default: 'getDivision'
    }
  },
  computed: {
    address () {
      return this.$refs.addressIndex
    },
    filds (){
      let obj = {
        provinceName: 'provinceName',
        cityName: 'cityName',
        districtName: 'districtName',
        provinceGbCode: 'provinceGbCode',
        cityGbCode: 'cityGbCode',
        districtGbCode: 'districtGbCode',
        postAddress: 'postAddress'
      }
      return {...obj, ...this.propFilds}
    },
    // 传入的数据是否只有name
    isOnlyName () {
      return !(this.crrentValue[this.filds.provinceGbCode] && this.crrentValue[this.filds.cityGbCode] && this.crrentValue[this.filds.districtGbCode])
    }
  },
  data () {
    return {
      provincesList: [],
      cityList: [],
      districtList: [],
      proviceCode: '',
      cityCode: '',
      crrentValue: {},
      districtCode: '',
      districtLoading: false,
      cityLoading: false,
      provinceLoading: false
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.crrentValue = val ? JSON.parse(JSON.stringify(val)) : {}
        let code = this.filds.provinceGbCode || this.filds.cityGbCode || this.filds.districtGbCode
        for (var key in this.crrentValue) {
          if (key === code) {
            this.crrentValue[key] = typeof (val[code]) === 'string' ? Number(val[key]) : val[key]
          }
				}
        if (this.provincesList&&this.provincesList.length > 0) {
          if (!isEmptyObject(this.crrentValue)) {
            if (this.isOnlyName) {
              this.getNameToCode(this.provincesList, 'province')
            }
          }
        } else {
          let data = {
            provinceGbCode: 0,
            cityGbCode: 0
          }
          this.getDivision(data)
        }
      },
      immediate: true
    }
  },
  methods: {
    getValue (val) {
      this.$emit('change', val, this.labelValue)
      let value = JSON.parse(JSON.stringify(val))
      for(let key in value){
        if(!value[key]){
          this.$delete(value, key)
        }
      }
      this.$emit('input', value)
    },
    districtChange (val) {
		},
    provinceChange (val) {
      this.proviceCode = val
      let data = {
        provinceGbCode: val,
        cityGbCode: 0
      }
      this.getDivision(data)
    },
    cityChange (val) {
      let data = {
        provinceGbCode: this.proviceCode,
        cityGbCode: val
      }
      this.getDivision(data)
    },
    // 如果传入数据只有name获取相关code
    getNameToCode (Arry, name) {
      Arry.forEach(item => {
        if (this.isOnlyName && item[name] === this.crrentValue[this.filds[name + 'Name']]) {
          this.$set(this.crrentValue, name + 'GbCode', item.gbCode)
        }
      })
    },
    getDivision (data) {
      if (data.provinceGbCode === 0) {
        this.provinceLoading = true
      } else if (data.cityGbCode === 0) {
        this.cityLoading = true
      } else {
        this.districtLoading = true
			}
      getAddress(data).then(res => {
        if (data.provinceGbCode === 0) {
					this.provincesList = res.data
          this.provinceLoading = false
          this.getNameToCode(res.data, 'province')
        } else if (data.cityGbCode === 0) {
          this.cityList = res.data
          this.cityLoading = false
          this.getNameToCode(res.data, 'city')
        } else {
          this.districtList = res.data
          this.districtLoading = false
          this.getNameToCode(res.data, 'district')
        }
      }).catch(err => {
        this.$message.error(err || '获取省/市/区列表失败')
      })
    }
  },
  created () {
  }
}
</script>
