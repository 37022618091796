<template>
    <div class="pt-hostadd__withData">
        <pageheader :title="title"></pageheader>
        <el-card>
            <div class="form-content-class">
                <el-form ref="ruleForm" label-position='left' :model="form" :rules="rules"
                    label-width="150px" size="medium">
                    <el-form-item label="客户机构:" prop='organizationInfo' class="sub-title">
                        <el-button type="primary" icon='el-icon-plus'
                            @click="addInstitutions">选择机构</el-button>
                    </el-form-item>
                    <el-table style="width: 100%;margin-top:20px;" :stripe="true"
                        :data="organizationInfoList">
                        <el-table-column prop="name" label="机构名称"></el-table-column>
                        <el-table-column prop="departmentStr" label="科室"></el-table-column>
                        <el-table-column prop="rank" label="等级">
                            <template slot-scope="scope">
                                <span>{{getRankValue(scope.row.rank)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="region" label="地区">
                            <template slot-scope="scope">
                                <span>{{scope.row.region.city}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="uniformCode" label="企业统一社会信用代码" width='170px'>
                        </el-table-column>
                        <el-table-column prop="contacts" label="联系人"> </el-table-column>
                        <el-table-column prop="contactsPhone" label="手机号"> </el-table-column>
                    </el-table>
                    <el-divider></el-divider>
                    <el-form-item label="主机信息" class="sub-title">
                    </el-form-item>
                    <el-form-item label="产品型号:" prop='model'>
                        <el-input placeholder='请输入产品型号' :disabled='true'
                            v-model="form.model"></el-input>
                    </el-form-item>
                    <el-form-item label="主机序列号:" prop='sn'>
                        <el-input placeholder='请输入主机序列号' v-model="form.sn"
                            @keyup.native="onkeyup('sn')"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="出厂硬件版本:" prop="initHardwareVersion">
            <el-input placeholder='请输入出厂硬件版本' v-model="form.initHardwareVersion"
              @keyup.native="onkeyup('initHardwareVersion')"></el-input>
          </el-form-item>
          <el-form-item label="出厂软件版本:" prop="initSoftwareVersion">
            <el-input placeholder='请输入出厂软件版本' v-model="form.initSoftwareVersion"
              @keyup.native="onkeyup('initSoftwareVersion')"></el-input>
          </el-form-item> -->
                    <el-form-item>
                        <el-button v-if="type === 'edit'"
                            @click="delForm('ruleForm')">删除</el-button>
                        <el-button @click="resetForm('ruleForm')">取消</el-button>
                        <el-button type="primary" @click="submitForm('ruleForm')">确认提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
        <institutionsSelect ref="institutionsSelect" v-model="organizationInfoList" />
    </div>
</template>

<script>
import pageheader from '../base/pt-page-header/index'
import { add, edit, del, detail } from '../../utils/hostServices'
import institutionsSelect from '../pt-institutions/institutions-select'
import { Loading } from 'element-ui'
var hardwareVersionNumber = (rule, value, callback) => {
    if (/^(([1-9]{1}\d*)|(0{1}))((.\d{0,10})?)$/.test(value)) {
        callback();
    } else {
        callback(new Error('请输入正确的硬件版本号'));
    }
}
var softVersionNumber = (rule, value, callback) => {
    if (/^[0-9]{1,5}.[0-9]{1,5}.[0-9]{1,5}.[0-9]{1,5}$/.test(value)) {
        callback();
    } else {
        callback(new Error('请输入正确的软件版本号'));
    }
}
var sn = (rule, value, callback) => {
    if (/[A-Za-z0-9]$/.test(value) || !value) {
        callback();
    } else {
        callback(new Error('主机序列号只能包含数字和字母'));
    }
}
export default {
    name: 'pt-hostadd__withData',
    components: {
        pageheader,
        institutionsSelect
    },
    data () {
        return {
            loadingInstance: null,
            type: 'add',
            title: '新增客户主机',
            rules: {
                organizationInfo: [
                    //    { required: true, message: '客户机构不能为空', trigger: 'blur'}
                ],
                model: [
                    { required: true, message: '产品型号不能为空', trigger: 'blur' }
                ],
                sn: [
                    { required: true, message: ' 主机序列号不能为空', trigger: 'blur' },
                    { validator: sn, trigger: 'blur' }
                ],
                initHardwareVersion: [
                    { required: true, message: ' 出厂硬件版本不能为空', trigger: 'blur' },
                    { validator: hardwareVersionNumber, trigger: 'blur' }
                ],
                initSoftwareVersion: [
                    { required: true, message: ' 出厂软件版本不能为空', trigger: 'blur' },
                    { validator: softVersionNumber, trigger: 'blur' }
                ]
            },
            rankList: [
                {
                    id: 'FIRST_A',
                    title: '一级甲等'
                },
                {
                    id: 'FIRST_B',
                    title: '一级乙等'
                },
                {
                    id: 'FIRST_C',
                    title: '一级丙等'
                },
                {
                    id: 'SECOND_A',
                    title: '二级甲等'
                },
                {
                    id: 'SECOND_B',
                    title: '二级乙等'
                },
                {
                    id: 'SECOND_C',
                    title: '二级丙等'
                },
                {
                    id: 'THIRD_A',
                    title: '三级甲等'
                },
                {
                    id: 'THIRD_B',
                    title: '三级乙等'
                },
                {
                    id: 'THIRD_C',
                    title: '三级丙等'
                },
                {
                    id: 'OTHER',
                    title: '其他'
                }
            ],
            id: '',
            form: {
                model: 'CHRV-9'
            },
            organizationInfoList: []
        }
    },
    created () {
        this.type = this.$route.query.type
        if (this.type === 'add') {
            this.title = '新增客户主机'
        } else if (this.type === 'edit') {
            this.title = '编辑客户主机'
            this.id = this.$route.query.id
            // 获取用户详情
            this.getDetail()
        }
    },
    methods: {
        onkeyup (name) {
            let vm = this
            setTimeout(function () {
                vm.$refs.ruleForm.validateField(name)
            }, 1000);
        },
        getRankValue (rank) {
            for (let index in this.rankList) {
                let item = this.rankList[index]
                if (item.id === rank) {
                    return item.title
                }
            }
            return ''
        },
        addInstitutions () {
            this.$refs.institutionsSelect.show()
        },
        getDetail () {
            detail(this.id).then(res => {
                this.form = res.data
                if (this.form.organizationInfo) {
                    this.organizationInfoList = [this.form.organizationInfo]
                }
            }).catch(err => {
                this.$message.error(err || '获取数据失败')
            })
        },

        submitForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitData()
                } else {
                    return false;
                }
            })
        },
        submitData () {
            if (this.organizationInfoList.length > 0) {
                this.form.organizationInfo = this.organizationInfoList[0]
            } else {
                this.$message({
                    message: '请选择客户机构',
                    type: 'warning'
                });
                return
            }
            if (this.type === 'add') {
                this.loadingInstance = Loading.service({ text: '请稍等' })
                add(this.form).then(res => {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    this.$nextTick(() => {
                        this.loadingInstance.close();
                    });
                    this.$router.push('/host')
                }).catch(err => {
                    this.$nextTick(() => {
                        this.loadingInstance.close();
                    });
                    this.$message.error(err || '保存失败')
                });
            } else {
                this.loadingInstance = Loading.service({ text: '请稍等' })
                edit(this.form).then(res => {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.$nextTick(() => {
                        this.loadingInstance.close();
                    });
                    this.$router.push('/host')
                }).catch(err => {
                    this.$nextTick(() => {
                        this.loadingInstance.close();
                    });
                    this.$message.error(err || '保存失败')
                })
            }

        },
        resetForm (formName) {
            this.$refs[formName].resetFields();
            this.$router.push('/host')
        },
        delForm (formName) {
            this.$confirm('确认删除该主机?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 删除用户
                this.loadingInstance = Loading.service({ text: '请稍等' })
                del(this.id).then(res => {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.$nextTick(() => {
                        this.loadingInstance.close();
                    });
                    this.$router.push('/host')
                }).catch(err => {
                    this.$nextTick(() => {
                        this.loadingInstance.close();
                    });
                    this.$message.error(err || '删除失败')
                })
            })
        }
    }

}
</script>
<style lang="less">
@import "~less/var.less";
.pt-hostadd__withData{
  max-width: 100%;
  padding: 0 20px 20px 20px;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  .el-card{
    overflow-y: auto!important;
  }
  .form-content-class{
      margin: 20px 0 0 20px;
			.sub-title{
				.el-form-item__label{
				font-size: 15px;
				font-weight: 600;
				width: 100Px!important;
				}
			}
  }
	.el-table th.el-table__cell.is-leaf{
		border-bottom:solid 1Px #bdbdbd;
		border-top:solid 1Px #bdbdbd;
		border-bottom-color: rgba(0, 0, 0, 0.1);
		border-top-color: rgba(0, 0, 0, 0.1);
		background: rgba(0,0,0,0.04);
		color: #282828;
	}
}
</style>