import _ from "./typeof";
import Vue from "vue";
/*获取某数组中某字段的最大值
 *第一个参数（arr）为需判断的数组
 *第二个参数（filed）为需比较的字段名
 */
export function maxValueMethod(arr, filed) {
  let maxValue = Math.max.apply(
    Math,
    arr.map(function (o) {
      return o[filed] || o;
    })
  );
  return maxValue;
}
/*获取某数组中某字段的最小值
 *第一个参数（arr）为需判断的数组
 *第二个参数（filed）为需比较的字段名
 */
export function minValueMethod(arr, filed) {
  let minValue = Math.min.apply(
    Math,
    arr.map((o) => {
      return o[filed] || o;
    })
  );
  return minValue;
}
//性别处理
export function genderFormat(code) {
  switch (String(code)) {
    case "1":
    case "MALE":
      return "男";
    case "0":
    case "FEMALE":
      return "女";
    default:
      return "保密";
  }
}

/* 上传/获取的时候，金额（钱）数乘以/除以100,当get为true时，则为获取，除以100
 ****val---当前金额（number|string）
 ****get---是否为获取值（boolean）true为获取值，需/100,false为上传值，需*100
 ****number---保留金额后多少位，一版为获取值的时候使用较多
 ****unit---单位（string），如：unit=¥，返回数据：¥300
 ****seat---金额单位的位置（string），before/after。传before或不传，返回单位在前。如¥300。传after的时候，返回”金额+unit“，如：300元
 */
export function cashCount(val, get, number, unit, seat) {
  number = number || 0;
  val = typeof val === "number" ? val : parseFloat(val);
  let value;
  if (get) {
    value = number ? (val / 100).toFixed(number) : val / 100;
  } else {
    value = val * 100;
  }
  return seat === "after" ? value + "" + unit : unit + "" + value;
}
// 判断空对象
export function isEmptyObject(obj) {
  return Object.keys(obj).length ? false : true;
}
/* 获取某时间戳所在日期的0点0分0秒
 *** 第一个参数date为指定时间，缺省值为当前时间
 ***第二个type为传入时间的类型，缺省值为timestamp
 */
export function dateZero(date, type) {
  let data = date || new Date();
  if (type === "timestamp" || !type) {
    data = new Date(data);
  }
  data.setHours(0);
  data.setMinutes(0);
  data.setSeconds(0);
  data.setMilliseconds(0);
  if (type === "timestamp" || !type) {
    data = data.getTime();
  }
  return data;
}
/* 数组指定位置插入数据，但不改变原数组
 ****第一个参数arr（array）---原数组
 ****index（number）---指定的位置
 ****insertData（object/array/string）---需插入的数据
 */
export function arrInsert(arr, index, insertData) {
  let arrFirst;
  let arrSecond;
  arrFirst = arr.slice(0, index);
  arrSecond = arr.slice(index, arr.length);
  let newArr = [];
  newArr = arrFirst.concat(insertData, arrSecond);
  return newArr;
}
/* 生成随机字符串(包含符号，字母和数字)
 ***min---生成多少位的随机字符串，必填
 ***randomFlag---是否生成随机长度的字符串（boolean）。如果为true,max为必传值，意为生成min位-max位的随机长度字符串；如果为false，将生成指定长度（min）的随机字符串。缺省值为false
 ***max---与randomFlag搭配，如果randomFlag为false将不会读入该参数，如果randomFlag为true，将作为随机字符串位数的上限
 ***randomFlag---是否生成随机长度的随机字符串
 ***data---随机字符串包含的字符
 */
export function randomWord(min, randomFlag, max, data) {
  if (!min) {
    console.error("请确定随机字符串的位数”min“");
    return;
  }
  let str = "";
  let range = min;
  let arr = data || [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "-",
    ".",
    "~",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "_",
    ":",
    "<",
    ">",
    "?",
  ];

  if (randomFlag && max) {
    range = Math.round(Math.random() * (max - min)) + min; // 任意长度
  }
  for (let i = 0; i < range; i++) {
    let pos = Math.round(Math.random() * (arr.length - 1));
    str += arr[pos];
  }
  return str;
}
/* 将字符串某几个位置的字符替换成符号或固定样式，如星号’*‘
 ***str---传入字符，即需要隐藏部分内容的字符串
 ***afterLocation---保留字符串尾部多少位的显示。如：*****5555
 ***beforeLocation---保留字符串头部多少位的显示。如：5555****
 ***symbol---隐藏字符的替换字符，缺省值为：“*”
 */
export function strReplace(str, afterLocation, beforeLocation, symbol = "*") {
  if (beforeLocation) {
    return (
      str.substr(0, beforeLocation) +
      Array(str.length).join(symbol) +
      str.substr(-afterLocation)
    );
  } else {
    return Array(str.length).join(symbol) + str.substr(-afterLocation);
  }
}
/*将数字格式化成多少位，不足的位数用0补齐
 ***number(number)---要格式化的数字
 ***digit(number)---需转化成多少位。如：digit=2,5->05
 */
export function formatZero(number, digit) {
  return (Array(digit).join(0) + number).slice(-digit);
}
/*判断摸某个元素是否存在于数组或数组对象中
 ***arr(array)---目标数组
 ***key（string/number）---如果您需要的是判断一个对象数组中是否有某个元素，key为该元素的名称。如果是一个字符串数组，key为数组中包含的元素
 */
export function existenceOfArrOrObj(arr, key) {
  return arr.some(function (a) {
    return a[key] || a === key;
  });
}
/*
 ***str（string）---需格式化的字符串
 ***isAll（boolean）---是否需去除字符串中所有空格，缺省值为false，默认情况下只去除字符串末尾的空格
 */
/* 去除字符串中所有空格/收尾空格 */
export function getDeleteSpace(str, isAll) {
  let result;
  if (isAll) result = str.replace(/\s+/g, "");
  else result = str.trim();
  return result;
}
// 清空对象里的内容,传入对象，返回空对象
export function emptyObject(obj) {
  for (var key in obj) {
    Vue.delete(obj, key);
  }
}
