<template>
<div>
  <pt-user class="pt-user__page"></pt-user>
</div>
</template>

<script>
import PtUser from 'cps/pt-user/withData'
export default {
  name: 'pt-user__view',
  components: {
    'pt-user': PtUser
  }
}
</script>

