// https://github.com/baianat/vee-validate
// 示例页面：https://codesandbox.io/s/y3504yr0l1?initialpath=%2Fform&module=%2Fsrc%2Fcomponents%2FForm.vue
// 框架自带验证规则：https://baianat.github.io/vee-validate/guide/rules.html
import Vue from "vue";
import {
  extend,
  localize,
  ValidationProvider,
  ValidationObserver,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
// import VeeValidate, { Validator as _validator } from 'vee-validate'
import zh_CN, { messages } from "vee-validate/dist/locale/zh_CN.json";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("validate-form", ValidationObserver);
// import IDValidator from 'utils/IDValidator'

// 重写extend方法
for (let rule in rules) {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
}
extend("required", { message: "请填写{_field_}" });
extend("phone", {
  validate: (value) => {
    return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value);
  },
  message: "{_field_}格式错误",
});
extend("address", {
  validate: (value) => {
    return value.provinceGbCode &&
      value.cityGbCode &&
      value.districtGbCode &&
      value.postAddress ?
      true :
      false;
  },
  message: "请填写完整的{_field_}信息",
});
// 拓展验证器用户名类型（可输入小写字母下划线和点）
extend('username', {
  validate: value => {
    return (/^[0-9a-zA-Z_]{3,20}$/g.test(value))
  },
  message: "{_field_}只能由3-20位字母、数字、下划线组成"
});
// 拓展验证器phone类型
extend("bankcard", {
  validate: (value) => /^\d+$/.test(value),
  message: "{_field_}格式错误",
});
// 拓展验证器passport类型
extend("passport", {
  validate: (value) => /^[a-zA-Z0-9_]{0,}$/.test(value),
  messages: "{_field_}格式错误",
});
// 拓展验证器before类型
extend("militaryID_before", {
  validate: (value) => /^[\u4e00-\u9fa5]{1}$/.test(value),
  messages: "{_field_}格式错误",
});
// 拓展验证器before类型
extend("militaryID_after", {
  validate: (value) => /^\d{8}$/.test(value),
  messages: "{_field_}格式错误",
});
// 重写extend方法

// 拓展验证器长度类型
extend("length-6", {
  validate: (value) => String(value).length === 6,
  messages: `{_field_}必须为6位数字`,
});

// 数组长度必须大于0
extend("requiredArray", {
  validate: (value) => value.length > 0,
  messages: "{_field_}不能为空",
});
//纳税人识别号校验(统一社会信用代码由18或15位数字或者大写字母组成，但是字母不包括 I、O、Z、S、V)
extend('taxpayerNumber', {
  validate: (value) => {
    return /^[A-HJ-NP-RT-UW-Y0-9]{15}$|^[A-HJ-NP-RT-UW-Y0-9]{18}$/.test(value)
  }
});
//联系人校验(不能包含特殊字符)
extend('contacts', {
    validate: (value) => /(^(([\u4e00-\u9fa5]|[a-zA-Z0-9|@?:!,.\\\/\'\"——+-=*&%￥#@《》……^])+)$)/.test(value)
  })
  //联系电话格式（手机和座机，包含数字和中划线）
extend('contactsPhone', {
    validate: (value) => {
      return /^(\({0,1}\d{3,4})\){0,1}(-){0,1}(\d{7,8})$/.test(value) || /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value)
    }
  })
  //通用编号格式（字母和数字）
extend('currencyNumber', {
    validate: (value) => {
      return /^[A-Za-z0-9]$/.test(value)
    }
  })
  //硬件版本编号限制，只能有数字和点（A.B）
extend('hardwareVersionNumber', {
  validate: (value) => {
    return /^(([1-9]{1}\d*)|(0{1}))((.\d{0,10})?)$/.test(value)
  }
})

//硬件版本编号限制，只能有数字和点（A.B.C.D）
extend('softVersionNumber', {
  validate: (value) => {
    return /^(([1-9]{1}\d*)|(0{1}))((.\d{0,10})?)((.\d{0,10})?)((.\d{0,10})?)$/.test(value)
  }
})
extend('passWord', {
  validate: (value) => {
    return /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{8,30}$/.test(value)
  }
})
localize("zh_CN", zh_CN);
// 拓展验证器长度类型
// _validator.extend('idcard', {
//   validate: value => IDValidator.isValid(value),
//   messages: {
//     zh_CN: field => `${field}错误`,
//     en: field => `The ${field} error`
//   }
// })
// usage in component
// this.$validator.validateAll().then(result => {
// 	return new Promise((resolve, reject) => {
// 		if (result) {
// 			resolve(this.form)
// 		} else {
// 			reject()
// 		}
// 	})
// })