import Vue from 'vue'
import VueAuth from '@websanova/vue-auth'
import router from '../router'
import {config} from './config'

Vue.router = router
// 初始化auth模块


Vue.use(VueAuth, config)
// 是否已经登录 返回一个promise对象
Object.defineProperty(Vue.prototype.$auth, 'hasLogined', {
  get () {
    let authVM = Vue.prototype.$auth
    return authVM.watch.authenticated
  }
})
