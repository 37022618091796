import request from "./request";
import router from "@/router";
import env from "../api";
// 主机列表
export function getPage(data) {
    return request(`/services/installbase/api/device-base-infos/page-by-query`, "POST", data);
}
// 授权列表
export function getPage2(data) {
    return request(`/services/installbase/api/device-authorize-infos/page-by-query`, "POST", data);
}
// 主机终端列表
export function deviceTerminalsPage(data) {
    return request(`/services/installbase/api/device-terminals/page-by-query`, "POST", data);
}
// 主机绑定终端
export function bindingTerminals(data) {
    return request(`/services/installbase/api/device-terminals`, "POST", data);
} 
// 主机绑定终端解绑
export function unbindingTerminals(id) {
    return request(`/services/installbase/api/device-terminals/${id}`, "DELETE");
}
// 主机授权列表
export function authorizationHost(data) {
    return request(`/services/installbase/api/device-authorize-infos/page-by-query`, "POST", data);
}
// 主机新增授权
export function addAuthorizationHost(data) {
    return request(`/services/installbase/api/device-authorize-infos`, "POST", data);
}
// 主机授权删除
export function delAuthorizationHost(id) {
    return request(`/services/installbase/api/device-authorize-infos/${id}`, "DELETE");
}
// 主机授权列表
export function configsHost(data) {
    return request(`/services/installbase/api/function-configs/list`, "POST", data);
}
// 获取激活码
export function getLicenseCode(id) {
    return request(`/services/installbase/api/device-authorize-infos/activate-license/${id}`, "GET");
}
// 主机案例数据列表
export function caseHost(data) {
    return request(`/services/installbase/api/device-case-infos/page-by-query`, "POST", data);
}
// 主机案例数据添加
export function addCaseHost(data) {
    return request(`/services/installbase/api/device-case-infos`, "POST", data);
}
// 主机案例数据删除
export function delCaseHost(id) {
    return request(`/services/installbase/api/device-case-infos/${id}`, "DELETE");
}
// 主机运维日志数据列表
export function logHost(data) {
    return request(`/services/installbase/api/device-logs/page-by-query`, "POST", data);
}
// 主机运维日志数据删除
export function delLogHost(id) {
    return request(`/services/installbase/api/device-logs/${id}`, "DELETE");
}
// 主机硬件版本列表
export function hardwareHost(data) {
    return request(`/services/installbase/api/device-hardware-versions/page-by-query`, "POST", data);
}
// 主机硬件版本添加
export function addHardwareHost(data) {
    return request(`/services/installbase/api/device-hardware-versions`, "POST", data);
}
// 主机软件版本列表
export function softwareHost(data) {
    return request(`/services/installbase/api/device-software-versions/page-by-query`, "POST", data);
}

export function add(data) {
    return request(`/services/installbase/api/device-base-infos`, "POST", data);
}
export function detail(id) {
    return request(`/services/installbase/api/device-base-infos/${id}`, "GET");
}
export function del(id) {
    return request(`/services/installbase/api/device-base-infos/${id}`, "DELETE");
}
export function edit(data) {
    return request(`/services/installbase/api/device-base-infos`, "PUT", data);
}
export function delAuthorize(id) {
    return request(`/services/installbase/api/device-authorize-infos/${id}`, "DELETE");
}



