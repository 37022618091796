import request from "./request";
import router from "@/router";
import env from "../api";

export function getPage(data) {
  return request(`/services/hpd/api/hospitals-manager/admin/page-by-query/all`, "POST", data);
}
export function add(data) {
    return request(`/services/hpd/api/hospitals-manager/admin/save`, "POST", data);
}
export function detail(id) {
    return request(`/services/hpd/api/hospitals/${id}`, "GET");
}
export function del(id) {
    return request(`/services/hpd/api/hospitals/${id}`, "DELETE");
}


