<template>
    <el-dialog class="is-dialog-class"
        :visible.sync="dialogVisible"
        :show-close='false'
        :title="title"
        width='800px'>
        <div>
            <el-divider></el-divider>
            <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="dataList">
                <el-table-column prop="authorizationType" label="授权类型">
                    <template slot-scope="scope">
                        <span>{{getTypeValue(scope.row.authorizationType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="caseCount" label="次数上限">
                    <template slot-scope="scope">
                        <span>{{getCaseCountValue(scope.row)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="effectiveDate" label="生效日期">
                    <template slot-scope="scope">
                        <span>{{formatDateObject(scope.row.effectiveDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="invalidDate" label="失效日期">
                    <template slot-scope="scope">
                        <span>{{getInvalidDateValue(scope.row)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="functionConfig" label="功能配置">
                    <template slot-scope="scope">
                        <span>{{scope.row.functionConfig.configName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createdDate" label="授权创建日期">
                    <template slot-scope="scope">
                        <span>{{formatDateObject(scope.row.createdDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="当前状态"> 
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 0">未生效</span>
                        <span v-if="scope.row.status == 1">有效</span>
                        <span v-if="scope.row.status == 2">已完成</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="license-class">{{license}}</div>
            <!-- <el-divider></el-divider> -->
        </div>
        
       
        <span slot="footer" class="dialog-footer">
            <el-button :disabled='license === ""' type="primary" v-clipboard:copy="license" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { formatDateObject } from '../../../utils/formatUtils/date'
import {getLicenseCode} from '../../../utils/hostServices'
export default {
    props:{
        title:{
            type: String,
            default: ''
        }
    },
    data(){
        return{
            dialogVisible: false,
            dataList: [],
            license: ''
        }
    },
    methods:{
        show(dataList){
            this.dataList = dataList
            if (dataList && dataList.length > 0){
                let id = this.dataList[0].id
                getLicenseCode(id).then((res)=>{
                    this.license = res.data.activeCode
                })
            }
            this.dialogVisible = true
        },
        hidden(){
            this.dialogVisible = false
        },
        onCopy(){
            this.$message({
                message: '复制成功',
                type: 'success'
            })
            this.hidden()
        },
        onError(){
            this.$message({
                message: '复制失败',
                type: 'error'
            })
        },
        formatDateObject(val){
            return formatDateObject(val, 'date')
        },
        getTypeValue(type){
          if (type === 'EXAMINATION_TIMES'){
              return '按检查次数'
          }
          if (type === 'PERIOD_OF_VALIDITY'){
              return '按有效期限'
          }
          if (type === 'UNLIMITED'){
              return '无限制使用授权'
          }
          return ''
      },
      getCaseCountValue(row){
          if (row.authorizationType === 'PERIOD_OF_VALIDITY'){
              return '无限制'
          }
          if (row.authorizationType === 'UNLIMITED'){
              return '无限制'
          }
          return row.caseCount
      },
      getInvalidDateValue(row){
          if (row.authorizationType === 'UNLIMITED'){
              return '永久有效'
          }
          return this.formatDateObject(row.invalidDate)
      }
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.license-class{
    color: @mainColor;
    text-align: left;
    float: left;
    font-size: 18px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 30px;
}
</style>