<template>
    <el-pagination
        :current-page="pages+1"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next"
        :total="totalSize" @current-change='pageChange' @prev-click='pageChange' @next-click='pageChange' @size-change='pageSizeChange'>
    </el-pagination>
</template>
<script>
import { getTotalNumber } from '../../../store/totalMethod'
export default {
    props:{
        page:Number,
				pageSize:Number
    },
    data(){
        return{
            pages:this.page,
            size:10,
            totalSize: 0
        }
    },
		watch:{
			page(val){
				this.pages=val
			},
			pageSize(val){
				this.size=val
			}
		},
    mounted(){
        this.$emit('paginationchange',{page:this.pages,size:this.size})
    },
    methods:{
        totalMethod(res){
            this.totalSize = getTotalNumber(res)
        },
        pageSizeChange(val){
            this.size = val
            this.pages = 0
            this.$emit('paginationchange',{page:this.pages,size:this.size})
            this.$emit('changePage',{page:this.pages,size:this.size})
        },
        pageChange(val){
						this.pages = val - 1//分页显示的页码需减一得到传个后端的页码数据。显示上面记得将显示页码加回来
            this.$emit('paginationchange',{page:this.pages,size:this.size})
            this.$emit('changePage',{page:this.pages,size:this.size})
        }
    }
}
</script>
<style>
.el-pagination__sizes{
    width: 100px;
}
.el-pagination__jump{
    margin-right: 40px;
}
</style>