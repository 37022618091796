import request from "./request";
import router from "@/router";
import env from "../api";
// export function getUserMessage(login) {
//   return request(`/api/users/`, "GET", login);
// }
export function getReleases(data) {
  return request(`/services/installbase/api/software-releases/page-by-query`, "POST", data);
}
export function addReleases(data) {
  return request(`/services/installbase/api/software-releases/`, "POST", data);
}
export function delReleases(id) {
    return request(`/services/installbase/api/software-releases/${id}`, "DELETE");
  }
  export function updateReleases(data) {
    return request(`/services/installbase/api/software-releases/`, "PUT", data);
  }
// export function getGroupList() {
//   return request(`/api/user-manager/group-info-list`, "GET");
// }
// export function getGroupDetail(id) {
//   return request(`/services/hpd/api/doctors/detail/${id}`, "GET");
// }
// export function delGroupDetail(id) {
//   return request(`/services/hpd/api/doctors/${id}`, "DELETE");
// }
