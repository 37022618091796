import { ossUploadBigFile, getOssSignature } from './action' //需在action中定义两个接口，ossUploadBigFile为获取oss签名。getOssSignatur为根据签名上传文件(useFormData)
import _ from 'utils/typeof'

export async function uploadBigFile(resourceConfig, file, fileKey = 'file', api = ossUploadBigFile, addTime = true, isPublic = false) {
  let params = {
    resourceConfig,
    type: isPublic ? 'PUBLIC_RESOURCE' : 'PRIVATE_RESOURCE'
  }
  if (!_.isFile(file)) {
    throw (new Error(`uploadBigFile（or this.$upload）方法参数file并非一个有效的文件`))
  }
  let response = await api(params)

  let fullPath = addTime ? response.data.dir + '/' + new Date().getTime() + '-' + file.name : response.data.dir + '/' + file.name
  let config = {
    key: fullPath,
    policy: response.data.policy,
    Signature: response.data.signature,
    OSSAccessKeyId: response.data.accessid,
    file
  }
  let uploadRes = await getOssSignature(
    response.data.host,
    config
  )
  if (uploadRes.status === 204) {
    return fullPath
  } else {
    return null
  }
}