import { render, staticRenderFns } from "./terminal-manger.vue?vue&type=template&id=c72b65e6&"
import script from "./terminal-manger.vue?vue&type=script&lang=js&"
export * from "./terminal-manger.vue?vue&type=script&lang=js&"
import style0 from "./terminal-manger.vue?vue&type=style&index=0&id=c72b65e6&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports