<template>
<div>
  <pt-institutions class="pt-institutions__page"></pt-institutions>
</div>
</template>

<script>
import PtInstitutions from 'cps/pt-institutions/withData'
export default {
  name: 'pt-institutions__view',
  components: {
    'pt-institutions': PtInstitutions
  }
}
</script>

