<template>
    <el-card class="pt-abstract-class">
        <div class="sub-title-class">授权信息</div>
        <el-form ref="ruleForm" label-position='left' :rules='rules' :model="form"
            label-width="120px" size="medium">
            <el-form-item label="授权形式:" prop='authorizationType'>
                <el-radio-group v-model="form.authorizationType" @change="typeChange">
                    <el-radio label="EXAMINATION_TIMES">按检查次数</el-radio>
                    <el-radio label="PERIOD_OF_VALIDITY">按有效期限</el-radio>
                    <el-radio label="UNLIMITED">无限制使用授权</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="授权使用例数:" prop='caseCount'>
                <el-input v-if="caseCountDisabled" :placeholder='caseCountDesc'
                    :disabled='caseCountDisabled'></el-input>
                <el-input v-else v-model="form.caseCount" :placeholder='caseCountDesc'
                    :disabled='caseCountDisabled'></el-input>
            </el-form-item>
            <el-form-item label="授权生效日期:" prop='effectiveDate'>
                <el-date-picker v-model="form.effectiveDate" type="date"
                    placeholder="请选择授权生效日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="授权失效日期:" prop='invalidDate'>
                <el-date-picker v-if="invalidDateDisabled" type="date" :disabled='true'
                    :placeholder="invalidDateDesc"></el-date-picker>
                <el-date-picker v-else v-model="form.invalidDate" type="date"
                    :placeholder="invalidDateDesc"></el-date-picker>
            </el-form-item>
            <el-form-item label="科室模型:" prop='officeModelStr'>
                <el-select v-model="form.officeModelStr" placeholder=" 请选择科室模型" filterable
                    allow-create>
                    <el-option v-for="item in modelList" :key="item.id" :value="item.name">
                        <span style="float: left">{{ item.name }}</span>
                        <i class="el-icon-error" @click.stop="deleteDepartment(item)"
                            style="float: right;height: 100%;display: flex;align-items: center;"></i>
                    </el-option>
                </el-select>
                <template v-if="showAddDepartment">
                    <i class="el-icon-circle-plus" @click="addDepartment"
                        style="margin-left: 10px;height: 100%;font-size: 20px;"></i>
                    <span style="color: red;margin-left: 5px;">点击“+”添加科室</span>
                </template>
            </el-form-item>
            <el-form-item label="备注:" prop='remark'>
                <el-input placeholder='请输入备注' v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item label="功能配置:" prop='functionConfig'>

            </el-form-item>
        </el-form>
        <el-table ref="tableref" style="width: 100%;" highlight-current-row :stripe="true"
            :data="dataList" @row-click="handleRowClick">
            <el-table-column prop="configName" label="配置名称" width="100"></el-table-column>
            <el-table-column prop="hardwareCongfigDesc" label="硬件通道配置">
                <template slot-scope="scope">
                    <span class="desc-title-class" v-html="scope.row.hardwareCongfigDesc"></span>
                </template>
            </el-table-column>
            <el-table-column prop="softwareCongfigDesc" label="软件功能配置">
                <template slot-scope="scope">
                    <span class="desc-title-class" v-html="scope.row.softwareCongfigDesc"></span>
                </template>
            </el-table-column>
            <el-table-column prop="softwareCongfigDesc" label="设备型号">
                <template slot-scope="scope">
                    <span class="desc-title-class" v-html="scope.row.model"></span>
                </template>
            </el-table-column>
        </el-table>
        <div class="flex-class fotter-class">
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="submit">确认提交</el-button>
        </div>
    </el-card>
</template>
<script>
import { authorizationHost, configsHost, addAuthorizationHost } from '../../../utils/hostServices'
import { formatDateObject } from '../../../utils/formatUtils/date'
import { Loading } from 'element-ui'
export default {
    props: {
        item: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    data () {
        return {
            loadingInstance: null,
            dataList: [],
            selectList: [],
            rules: [],
            caseCountDisabled: false,
            invalidDateDisabled: false,
            caseCountDesc: '请输入授权使用例数',
            invalidDateDesc: '请选择授权失效日期',
            modelList: [
            ],
            form: {
                authorizationType: 'EXAMINATION_TIMES'
            },
            rules: {
                authorizationType: [{ required: true, message: '请选择授权形式', trigger: 'blur' }],
                caseCount: [{ required: true, message: '请输入授权使用例数', trigger: 'blur' }],
                effectiveDate: [{ required: true, message: '请选择授权生效日期', trigger: 'blur' }],
                invalidDate: [{ required: true, message: '请选择授权失效日期', trigger: 'blur' }],
                officeModelStr: [{ required: true, message: '请选择科室模型', trigger: 'change' }],
            }
        }
    },
    mounted () {

        this.getData()
        this.getDepartmentsByConfigured()
    },
    computed: {
        showAddDepartment () {
            let isNewDepartment = true
            this.modelList.forEach(item => {
                if (item.name === this.form.officeModelStr) {
                    isNewDepartment = false
                }
            });
            return this.form.officeModelStr && isNewDepartment
        }
    },
    methods: {
        deleteDepartment (item) {
            this.$store.dispatch('deleteDepartment', item.id).then(res => {
                this.$message.success('删除科室成功')
                this.getDepartmentsByConfigured()
            }).catch(err => {
                this.$message.error(err || '删除科室失败')
            })
        },
        addDepartment () {
            let data = [{
                name: this.form.officeModelStr
            }]
            this.$store.dispatch('addDepartments', data).then(res => {
                this.$message.success('添加科室成功')
                this.getDepartmentsByConfigured()
            }).catch(err => {
                this.$message.error(err || '添加科室失败')
            })
        },
        getDepartmentsByConfigured () {
            this.$store.dispatch('getDepartmentsByConfigured').then(res => {
                this.modelList = res
                this.modelList.forEach(item => {
                    if (item.name === this.form.departmentEnum) {
                        this.form.departmentEnum = item.id
                    }
                });
            }).catch(err => {
                this.$message.error(err || '获取科室数据失败')
            })
        },
        cancel () {
            this.$emit('click', 0)
        },
        submit () {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.submitForm()
                } else {
                    return false;
                }
            })
        },
        submitForm () {
            if (this.showAddDepartment) {
                this.$message.error('请先提交新增科室')
                this.loadingInstance.close()
                return
            }
            if (this.selectList.length === 0) {
                this.$message.error('请选择功能配置')
                return
            }
            this.form.functionConfig = this.selectList[0]
            // this.form.caseCount = ''
            // this.form.invalidDate = null
            let deviceBaseInfoId = this.form.deviceBaseInfoId
            let example = this.form.example
            this.loadingInstance = Loading.service({ text: '请稍等' })
            addAuthorizationHost(this.form).then((res) => {
                this.form = {
                    authorizationType: 'EXAMINATION_TIMES',
                    deviceBaseInfoId: deviceBaseInfoId,
                    example: example
                }
                this.$nextTick(() => {
                    this.loadingInstance.close();
                });
                this.typeChange()
                this.$refs.tableref.setCurrentRow()
                this.$emit('click', 1)
            }).catch(err => {
                this.$nextTick(() => {
                    this.loadingInstance.close();
                });
                this.$message.error(err)
            })
        },
        getData () {
            this.form.deviceBaseInfoId = this.item.deviceBaseInfoId
            this.form.example = { deviceBaseInfoId: this.item.deviceBaseInfoId }
            configsHost(this.form).then((res) => {
                this.dataList = res.data
            })
        },
        formatDateObject (val) {
            return formatDateObject(val)
        },
        typeChange () {

            if (this.form.authorizationType === 'EXAMINATION_TIMES') {
                this.caseCountDisabled = false
                this.invalidDateDisabled = false
                this.caseCountDesc = '请输入授权使用例数'
                this.invalidDateDesc = '请选择授权失效日期'
                this.rules = {
                    authorizationType: [{ required: true, message: '请选择授权形式', trigger: 'blur' }],
                    caseCount: [{ required: true, message: '请输入授权使用例数', trigger: 'blur' }],
                    effectiveDate: [{ required: true, message: '请选择授权生效日期', trigger: 'blur' }],
                    invalidDate: [{ required: true, message: '请选择授权失效日期', trigger: 'blur' }],
                    officeModelStr: [{ required: true, message: '请选择科室模型', trigger: 'change' }],
                }
                this.$refs['ruleForm'].clearValidate()
                return
            }
            if (this.form.authorizationType === 'PERIOD_OF_VALIDITY') {
                this.caseCountDisabled = true
                this.invalidDateDisabled = false
                this.caseCountDesc = '无限制'
                this.invalidDateDesc = '请选择授权失效日期'
                this.rules = {
                    authorizationType: [{ required: true, message: '请选择授权形式', trigger: 'blur' }],
                    effectiveDate: [{ required: true, message: '请选择授权生效日期', trigger: 'blur' }],
                    invalidDate: [{ required: true, message: '请选择授权失效日期', trigger: 'blur' }],
                    officeModelStr: [{ required: true, message: '请选择科室模型', trigger: 'change' }],
                }
                this.$refs['ruleForm'].clearValidate()
                return
            }
            if (this.form.authorizationType === 'UNLIMITED') {
                this.caseCountDisabled = true
                this.invalidDateDisabled = true
                this.caseCountDesc = '无限制'
                this.invalidDateDesc = '永久有效'
                this.rules = {
                    authorizationType: [{ required: true, message: '请选择授权形式', trigger: 'blur' }],
                    effectiveDate: [{ required: true, message: '请选择授权生效日期', trigger: 'blur' }],
                    officeModelStr: [{ required: true, message: '请选择科室模型', trigger: 'change' }],
                }
                this.$refs['ruleForm'].clearValidate()
                return
            }
        },
        handleRowClick (val) {
            this.$refs.tableref.setCurrentRow()
            let isFind = false
            for (let index in this.selectList) {
                let item = this.selectList[index]
                if (item.id === val.id) {
                    isFind = true
                    this.selectList = []
                }
            }
            if (isFind === false) {
                this.selectList = [val]
                this.$refs.tableref.setCurrentRow(val)
            }
        }
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.pt-abstract-class{
    height: 100%;
    .sub-title-class{
        text-align: left;
        font-size: 14px;
        font-weight: 600;
    }
    .fotter-class{
        margin-top: 40px;
    }
    .desc-title-class{
        white-space:pre-wrap;
    }
    .el-table th.el-table__cell.is-leaf{
        border-bottom:0px solid #F1F1F1;
    }
    .el-table__body tr.current-row>td{
        background-color: @mainColor !important;
        color: #fff;
    }
}
</style>