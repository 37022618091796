import Vue from "vue"
const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
const ROLE_SYSTEM_ADMIN = 'ROLE_SYSTEM_ADMIN'
const ROLE_ADMIN = 'ROLE_ADMIN'
const ROLE_USER = 'ROLE_USER'
let getter = {
    //用户权限获取
    /*是否超级管理员*/
    isSuporAdmin() {
        return Vue.auth.user() && Vue.auth.user().authorities && Vue.auth.user().authorities.indexOf(ROLE_SUPER_ADMIN) > -1
    },
    /*是否系统管理员*/
    isSystemAdmin() {
        return Vue.auth.user() && Vue.auth.user().authorities && Vue.auth.user().authorities.indexOf(ROLE_SYSTEM_ADMIN) > -1 && !getter.isSuporAdmin()
    },
    /*是否管理员*/
    isAdmin() {
        return Vue.auth.user() && Vue.auth.user().authorities && Vue.auth.user().authorities.indexOf(ROLE_ADMIN) > -1 && !getter.isSuporAdmin() && !getter.isSystemAdmin()
    },
    /*是否普通用户*/
    isUser() {
        return Vue.auth.user() && Vue.auth.user().authorities && Vue.auth.user().authorities.indexOf(ROLE_USER) > -1 && !getter.isSuporAdmin() && !getter.isSystemAdmin() && !getter.isAdmin()
    }
}
export default getter