<template>
  <div class="pt-home__withData">
    <pageheader title="基本情况统计"></pageheader>
    <div class="flex-class home-host-class">
      <div class="item-class">
        <div class="flex-class">
          <i class="iconfont icon-kehujigou"></i>
          <div>客户机构总数</div>
        </div>
        <div class="hh-count-class">{{hostpitalCount}}</div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="item-class">
        <div class="flex-class">
          <i class="iconfont icon-kehuzhujizongshu"></i>
          <div>客户主机总数</div>
        </div>
        <div class="hh-count-class">{{item.deviceCount}}</div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="item-class">
        <div class="flex-class">
          <i class="iconfont icon-zhujishouquanzongshu"></i>
          <div>主机授权总数</div>
        </div>
        <div class="hh-count-class">{{item.deviceAuthorizeTotalCount}}</div>
      </div>
    </div>
    <pageheader title="授权情况统计"></pageheader>
    <div class="flex-class home-authorization-class">
      <div class="item-class">
        <div class="flex-class">
          <i class="iconfont icon-huoyueshouquanshu"></i>
          <div>活跃授权数</div>
        </div>
        <div class="ha-count1-class">{{item.activeAuthorizeCount}}</div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="item-class">
        <div class="flex-class">
          <i class="iconfont icon-weishengxiaoshouquanshu"></i>
          <div>未生效授权数</div>
        </div>
        <div class="ha-count2-class">{{item.unAuthorizedCount}}</div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="item-class">
        <div class="flex-class">
          <i class="iconfont icon-yiwanchengshouquanshu"></i>
          <div>已完成授权数</div>
        </div>
        <div class="ha-count1-class">{{item.completedAuthorizeCount}}</div>
      </div>
    </div>
    <pageheader title="活跃主机连接"></pageheader>
    <div id="echart1" :style="{float:'left',width: '100%', height: '230px'}"></div>
  </div>

</template>

<script>
import pageheader from '../base/pt-page-header/index'
import { getHomeData, getCount } from  '../../utils/homeService'
import { formatDateObject } from '../../utils/formatUtils/date'

export default {
  name: 'pt-home__withData',
  components: {
    pageheader
  },
  data(){
    return{
      item: {},
      hostpitalCount: 0,
      activeDeviceDTOList: []
    }
	},
  methods:{
    getData(){
      getHomeData().then(res=>{
        this.item = res.data
        this.activeDeviceDTOList = this.item.activeDeviceDTOList
        this.createEchart()
      }).catch(err=>{
          this.$message.error(err||'获取数据失败')
      })
      getCount().then(res=>{
        this.hostpitalCount = res.data
      }).catch(err=>{
          this.$message.error(err||'获取数据失败')
      })
    },
    formatDateObject(val){
      return formatDateObject(val,'date2')
    },
    createEchart(){
      let xArray = []
      let dArray = []
      for (let index in this.activeDeviceDTOList){
        let item = this.activeDeviceDTOList[index]
        xArray.push(item.activeDate)
        dArray.push(item.activeDeviceCount)
      }
      let getchart = this.$echarts.init(document.getElementById('echart1'))
      let option = {
        color: ['#13C4AE'],
        xAxis: [
          {
            type: 'category',
            data: xArray,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: [
          {
            name: '',
            type: 'bar',
            data: dArray
          },
          {
            name: '',
            type: 'line',
            data: dArray,
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      }
      getchart.setOption(option)
        window.addEventListener("resize", () => {
          getchart.resize();
      })
    }
  },
  created (){
		this.getData()
	}
}
</script>
<style lang="less">
@import "~less/var.less";
.pt-home__withData{
  width: 100%;
  height: 100%;
  overflow-y: auto;
	.title-class{
		margin-left: 20px;
	}
  .flex-class{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-class{
    width: 33%;
  }
  .home-host-class{
    background-color: #ffffff;
    margin: 20px;
    padding: 20px;
    font-size: 15px;
    i{
      font-size: 30px;
      color: @mainColor;
      margin-right: 20px;
    }
    .hh-count-class{
      color: @mainColor;
      font-size: 30px;
      font-weight: 600;
    }
  }
  .home-authorization-class{
    background-color: @mainColor;
    margin: 20px;
    padding: 20px;
    height: 60px;
    border-radius: 50px;
    color:#ffffff;
    font-size: 15px;
    i{
      font-size: 30px;
      color:#ffffff;
      margin-right: 20px;
    }
    .ha-count1-class{
      font-size: 30px;
      font-weight: 600;
    }
    .ha-count2-class{
      font-size: 30px;
      font-weight: 600;
      color:@orangeColor;
    }
  }
}
</style>