<template>
    <div class="pt-institutionsdetail__withData">
        <pageheader title="客户机构详情"></pageheader>
        <el-card>
            <div class="form-content-class">
                <el-form ref="ruleForm" label-position='left' :model="form" :rules="rules"
                    label-width="100px" size="medium">
                    <el-form-item label="机构类型:" prop='type'>
                        <span>{{getTypeValue(form.type)}}</span>
                    </el-form-item>
                    <el-form-item label="机构名称:" prop='name'>
                        <span>{{form.name}}</span>
                    </el-form-item>
                    <el-form-item label="机构等级:" prop='rank' v-if="form.type === 'HOSPITAL'">
                        <span>{{getRankValue(form.rank)}}</span>
                    </el-form-item>
                    <el-form-item label="科室:" prop='departmentEnum' v-if="form.type === 'HOSPITAL'">
                        <span>{{ form.departmentStr }}</span>
                        <!-- <span>{{getDepartmentValue(form.departmentEnum)}}</span> -->
                    </el-form-item>
                    <el-form-item label="所属地区:" prop='region'>
                        <span>{{form.region.province}}-{{form.region.city}}-{{form.region.district}}</span>
                    </el-form-item>
                    <el-form-item label="通讯地址:">
                        <span>{{form.address}}</span>
                    </el-form-item>
                    <el-form-item label="登记号:" prop='' v-if="form.type === 'HOSPITAL'">
                        <span>{{form.registerNum}}</span>
                    </el-form-item>
                    <el-form-item label="统一代码:" prop='uniformCode'>
                        <span>{{form.uniformCode}}</span>
                    </el-form-item>
                    <el-form-item label="联系人:">
                        <span>{{form.contacts}}</span>
                    </el-form-item>
                    <el-form-item label="联系电话:">
                        <span>{{form.contactsPhone}}</span>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="resetForm('ruleForm')">取消</el-button>
                        <el-button type="primary" @click="editForm('ruleForm')">编辑</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
import pageheader from '../base/pt-page-header/index'
import { detail } from '../../utils/institutionsService'
export default {
    name: 'pt-detail__withData',
    components: {
        pageheader
    },
    data () {
        return {
            rules: {
            },
            rankList: [
                {
                    id: 'FIRST_A',
                    title: '一级甲等'
                },
                {
                    id: 'FIRST_B',
                    title: '一级乙等'
                },
                {
                    id: 'FIRST_C',
                    title: '一级丙等'
                },
                {
                    id: 'SECOND_A',
                    title: '二级甲等'
                },
                {
                    id: 'SECOND_B',
                    title: '二级乙等'
                },
                {
                    id: 'SECOND_C',
                    title: '二级丙等'
                },
                {
                    id: 'THIRD_A',
                    title: '三级甲等'
                },
                {
                    id: 'THIRD_B',
                    title: '三级乙等'
                },
                {
                    id: 'THIRD_C',
                    title: '三级丙等'
                },
                {
                    id: 'OTHER',
                    title: '其他'
                }
            ],
            departmentList: [
                {
                    id: 'CLINIC',
                    label: '门诊科'
                },
                {
                    id: 'PSYCHIATRY',
                    label: '精神科'
                },
                {
                    id: 'PSYCHOLOGY',
                    label: '心理科'
                },
                {
                    id: 'NEUROLOGY',
                    label: '神经内科'
                },
                {
                    id: 'PAIN_TREATMENT',
                    label: '疼痛科'
                },
                {
                    id: 'ENDOCRINOLOGY',
                    label: '内分泌科'
                },
                {
                    id: 'COMMUNITY',
                    label: '社区医院'
                },
                {
                    id: 'PHYSICAL',
                    label: '体检中心'
                }
            ],
            id: '',
            type: '',
            deviceBaseInfoId: '',
            form: {
                region: {
                    city: '',
                    cityGbCode: '',
                    gbCode: '',
                    district: '',
                    provinceGbCode: '',
                    province: ''
                }
            }
        }
    },
    created () {
        this.id = this.$route.query.id
        this.type = this.$route.query.type
        this.deviceBaseInfoId = this.$route.query.deviceBaseInfoId
        this.getDetail()
    },
    methods: {
        getDetail () {
            detail(this.id).then(res => {
                this.form = res.data
            }).catch(err => {
                this.$message.error(err || '获取数据失败')
            })
        },
        getTypeValue (type) {
            if (type === 'HOSPITAL') {
                return '医疗机构'
            }
            return '非医疗机构'
        },
        getRankValue (rank) {
            for (let index in this.rankList) {
                let item = this.rankList[index]
                if (item.id === rank) {
                    return item.title
                }
            }
            return ''
        },
        getDepartmentValue (departmentEnum) {
            for (let index in this.departmentList) {
                let item = this.departmentList[index]
                if (item.id === departmentEnum) {
                    return item.label
                }
            }
            return ''
        },

        editForm (formName) {
            this.$router.push('/institutionsedit?type=edit&id=' + this.id)
        },
        resetForm (formName) {
            this.$refs[formName].resetFields();

            if (this.type === 'host') {
                this.$router.push('/hostdetail?id=' + this.deviceBaseInfoId)
            } else {
                this.$router.push('/institutions')
            }
        }
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.pt-institutionsdetail__withData{
  max-width: 100%;
  padding: 0 20px 20px 20px;
  height: 100%;
  overflow-y: auto;
  .form-content-class{
      margin: 20px 0 0 20px;
      float: left;
  }
}
</style>