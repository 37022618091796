<template>
    <div class="pt-releaseadd__withData">
        <pageheader title="发布主机软件"></pageheader>
        <el-card>
        <div class="form-content-class">
            <el-form ref="ruleForm" label-position='left' :model="form" :rules="rules" label-width="180px" size="medium">
                <el-form-item label="主机软件版本:" prop='version'>
                    <el-input placeholder='请输入主机软件版本' v-model="form.version"></el-input>
                </el-form-item>
                <el-form-item label="软件版本说明:" prop='versionText'>
                    <el-input type='textarea' placeholder='请简要描述版本更新内容(100 字以内)' v-model="form.versionText"></el-input>
                </el-form-item>
               <el-form-item label="软件安装包文件:" prop='filePath'>
								<el-input  v-model="form.filePath" v-show="false"></el-input>
                    <uploadBigFile 
                        resourceConfig="releases"
                        objectId='a1'
                        v-model="form.filePath" 
												ref="uploadFile"
                        @isUpload="isUpload" 
                        :isPathUrlAddTime="true" 
                        @input='fileInput'>
                    </uploadBigFile>
                </el-form-item>
                <el-form-item label="是否提供下载:" class="no-requery-left-class">
                    <el-select v-model="form.status" placeholder="请选择" :disabled="!isSetDownload">
                        <el-option
                            v-for="item in statusList"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="resetForm('ruleForm')">取消</el-button>
                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                </el-form-item>
            </el-form>
        </div>
        </el-card>
    </div>
</template>

<script>
import pageheader from '../base/pt-page-header/index'
import { addReleases } from '../../utils/releaseService'
import uploadBigFile from '../base/pt-upload-big-file'
import { Loading } from 'element-ui'
export default {
  name: 'pt-releaseadd__withData',
  components: {
    pageheader,
    uploadBigFile
  },
  data(){
      return{
           rules:{
							version:[
								{ required: true, message: '请填写主机软件版本', trigger: 'blur'}
							],
               versionText:[
                   { required: true, message: '请简要描述版本更新内容', trigger: 'blur'}
               ],
                filePath:[
										{ required: true, message: '请选择安装包文件', trigger: 'change'}
               ]
           },
          statusList: [
              {
                  id: 0,
                  title: '是'
              },
              {
                  id: 1,
                  title: '否'
              }
          ],
          form: {
              filePath: null,
              status: 0
          },
          loadingInstance: null,
					isUploading:false,
					isValidate:false
      }
	},
	computed:{
		isSetDownload(){//只有超级管理员可设置是否可下载的选项
			return this.$store.getters.isSuporAdmin
		},
	},
	watch:{
		isUploading(val){
			if(val){
					if(this.$refs&&this.$refs.ruleForm&&this.$refs.ruleForm.rules.filePath[0].message){
						this.$refs.ruleForm.rules.filePath[0].message='文件正在上传';
						if(this.isValidate){//如果保存过，将在编辑后直接显示新的错误信息
						this.$refs.ruleForm.validateField('filePath')
						}
					}
					
				}else{
					if(this.$refs&&this.$refs.ruleForm&&this.$refs.ruleForm.rules.filePath[0].message){
						this.$refs.ruleForm.rules.filePath[0].message='请选择安装包文件'
						if(this.isValidate){
						this.$refs.ruleForm.validateField('filePath')
						}
					}
				}

		},
	},
  created (){
     
  },
	mounted(){
		this.$watch(
      () => {
       return this.$refs.uploadFile.isUploading
      },
      (val) => {
      this.isUploading = val
      }
    )
	},
  methods:{
      getDetail(){
        getGroupDetail(this.id).then(res=>{
            this.form = res.data
        }).catch(err=>{
            this.$message.error(err||'获取数据失败')
        })
      },
      isUpload(val){
				console.log('val--==-=',val)
				if(val&&!this.form.filePath){
				}
        // if (val){
        //     let options = {text: '努力上传中'}
        //     this.loadingInstance = Loading.service(options)
        // } else {
        //     this.$nextTick(() => {
        //         this.loadingInstance.close();
        //     });
        // }
      },
      fileInput(val){
          this.form.filePath = val.url
          this.form.name = val.name
      },
      submitForm(formName){
				this.isValidate=true
        this.$refs[formName].validate((valid) => {
            if (valid) {
                this.loadingInstance = Loading.service({text: '请稍等'})
                addReleases(this.form).then(res=>{
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.$nextTick(() => {
                        this.loadingInstance.close();
                    });
                    this.$router.push('/release')
                }).catch(err=>{
                    this.$nextTick(() => {
                      this.loadingInstance.close();
                    });
                    this.$message.error(err||'保存失败')
                })
            } else {	
							if(this.isUploading){
								this.$message.error('软件安装包正在上传，请不要离开该页面')
							}
                return false;
            }
        })
				
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
				this.$refs.uploadFile.abort()
        this.$router.push('/release')
      }
  },
	befostroyed(){
		console.log('222')
	}
}
</script>
<style lang="less">
@import "~less/var.less";
.pt-releaseadd__withData{
  max-width: 100%;
  padding: 0 20px 20px 20px;
  .form-content-class{
      margin: 20px 0 0 20px;
      float: left;
  }
}
</style>