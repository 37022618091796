import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import host from '../views/host.vue'
import hostadd from '../views/hostadd.vue'
import hostdetail from '../views/hostdetail.vue'
import institutions from '../views/institutions.vue'
import institutionsedit from '../views/institutionsedit.vue'
import institutionsdetail from '../views/institutionsdetail.vue'
import user from '../views/user.vue'
import useredit from '../views/useredit.vue'
import release from '../views/release.vue'
import releaseadd from '../views/releaseadd.vue'
import page404 from "../views/page404.vue"
Vue.use(Router);
let performInitialCheck = true;
let router = new Router({
  mode: "history",
  routes: [{
      path: "/",
      name: "Index",
      redirect: "/Home",
      // component: Home,
      meta: { view: 'pc', auth: true }
    },
    {
      path: "/Home",
      name: "Home",
      component: Home,
      meta: { view: 'pc', auth: true }
    },
    {
      path: "/host",
      name: "host",
      component: host,
      meta: { view: 'pc', auth: true }
    },
    {
      path: "/hostadd",
      name: "hostadd",
      component: hostadd,
      meta: { view: 'pc', auth: true }
    },
    {
      path: "/hostdetail",
      name: "hostdetail",
      component: hostdetail,
      meta: { view: 'pc', auth: true }
    },
    {
      path: "/institutions",
      name: "institutions",
      component: institutions,
      meta: { view: 'pc', auth: true }
    },
    {
      path: "/institutionsedit",
      name: "institutionsedit",
      component: institutionsedit,
      meta: { view: 'pc', auth: true }
    },
    {
      path: "/institutionsdetail",
      name: "institutionsdetail",
      component: institutionsdetail,
      meta: { view: 'pc', auth: true }
    },
    {
      path: "/user",
      name: "user",
      component: user,
      meta: { view: 'pc', auth: true }
    },
    {
      path: "/useredit",
      name: "useredit",
      component: useredit,
      meta: { view: 'pc', auth: true }
    },
    {
      path: "/release",
      name: "release",
      component: release,
      meta: { view: 'pc', auth: true }
    },
    {
      path: "/releaseadd",
      name: "releaseadd",
      component: releaseadd,
      meta: { view: 'pc', auth: true }
    },
    {
      path: "/login",
      name: "login",
      component: resolve => require(['views/login.vue'], resolve),
      meta: { view: "defaultView" }
      },
      {
        path: "page404",
        name: "page404",
        component: resolve => require(['views/page404.vue'], resolve),
        meta: { view: "defaultView" }
      }
  ]
});

router.beforeEach((to, from, next) => {
    let routeExists = router.options.routes.some(route => route.path === to.path)
    if (!routeExists) {
        next({
        path: "page404",
        name: "page404",
        meta: { view: "defaultView" }
      })
        return
    }
  router.lastRouter = from.fullPath;
  let _auth = Vue.prototype.$auth;
  // 目标路由的auth标记
  let targetRouteAuth = to.meta.auth;
  // 在跳转授权登录前进行预登录检查
  if (targetRouteAuth || performInitialCheck) {
    if (performInitialCheck) {
      performInitialCheck = false;
    }
    if (!_auth.check()) {
      // 尝试一次自动登录
      _auth.login({
        redirect: to,
        success() {
          // 强行打开当前路由的auth, 否则vue-auth会自动重定向到404, 这是vue-auth代码里的一个问题
          to.meta.auth = true;
          // 不需要调用next，login方法会自动redirect
          // next(to)
        },
        error() {
          if (targetRouteAuth) {
            // 记录触发登录的路由信息 - 登录页面需要用到该信息
            router.loginTriggerRoute = to;
            next({ name: "login" });
          } else {
            next(to);
          }
        }
      });
    } else {
      let userInfo = _auth.user();
      next();
    }
  } else {
    if (_auth.check()) {
      // 强行打开当前路由的auth, 否则vue-auth会自动重定向到404, 这是vue-auth代码里的一个问题
      to.meta.auth = true;
    } else {
      if (targetRouteAuth) {
        // 记录触发登录的路由信息 - 登录页面需要用到该信息
        router.loginTriggerRoute = to;
      } else {
        if (to.name === "login" && from != null) {
          if (router.loginTriggerRoute && from.path !== "/") {
            // 记录触发登录的路由信息 - 登录页面需要用到该信息
            router.loginTriggerRoute = from;
          }
        }
      }
    }
    next();
  }
});
export default router;