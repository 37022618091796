<template>
    <el-drawer title="高级检索" :visible.sync="drawer" direction="rtl" :before-close="handleClose"
        class="institution-searchSrawer">
        <el-divider></el-divider>
        <el-form v-model="form" class="ss-form-class">
            <el-form-item label='机构类型'>
                <el-select v-model="form.type" placeholder="请选择">
                    <el-option v-for="item in typeList" :key="item.id" :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='所属地区'>
                <addressSelect
                    :showElement='{ province: true,city: false,district: false,postAddress: false}'
                    :key='addressSelectKey' @change='regionChange' />
            </el-form-item>
            <el-form-item label='机构等级'>
                <el-select v-model="form.rank" placeholder="请选择">
                    <el-option v-for="item in rankList" :key="item.id" :label="item.title"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='科室'>
                <el-select v-model="form.departmentStr" placeholder="请选择">
                    <el-option v-for="item in departmentList" :key="item.id" :label="item.name"
                        :value="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label='社区医疗机构'>
                 <el-select v-model="form.communityCenter" placeholder="请选择">
                    <el-option label="是" :value="1"></el-option>
                    <el-option label="否" :value="0"></el-option>
                </el-select>
             </el-form-item> -->
        </el-form>
        <el-divider></el-divider>
        <div style="display:flex;align-items:center;justify-content:center;padding-bottom: 10px;">
            <div class="clear-btn-class" @click="clear">清除筛选条件</div>
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="search">检索</el-button>
        </div>
    </el-drawer>
</template>
<script>
import addressSelect from '../base/pt-address-select/withData'
export default {
    components: {
        addressSelect
    },
    data () {
        return {
            addressSelectKey: 1,
            drawer: false,
            form: {},
            typeList: [
                {
                    id: 'HOSPITAL',
                    label: '医疗机构'
                },
                {
                    id: 'ORGANIZATION',
                    label: '非医疗机构'
                }
            ],
            rankList: [
                {
                    id: 'FIRST_A',
                    title: '一级甲等'
                },
                {
                    id: 'FIRST_B',
                    title: '一级乙等'
                },
                {
                    id: 'FIRST_C',
                    title: '一级丙等'
                },
                {
                    id: 'SECOND_A',
                    title: '二级甲等'
                },
                {
                    id: 'SECOND_B',
                    title: '二级乙等'
                },
                {
                    id: 'SECOND_C',
                    title: '二级丙等'
                },
                {
                    id: 'THIRD_A',
                    title: '三级甲等'
                },
                {
                    id: 'THIRD_B',
                    title: '三级乙等'
                },
                {
                    id: 'THIRD_C',
                    title: '三级丙等'
                },
                {
                    id: 'OTHER',
                    title: '其他'
                }
            ],
            departmentList: [
            ]
        }
    },
    methods: {
        show () {
            this.drawer = true
        },
        handleClose () {
            this.drawer = false
            this.clear()
        },
        search () {
            this.$emit('search', this.form)
            this.drawer = false
        },
        clear () {
            this.form = {}
            this.addressSelectKey += 1
        },
        regionChange (val) {
            if (val.provinceGbCode) {
                this.form.region = {}
                this.form.region.provinceGbCode = 0
                this.form.region.cityGbCode = 0
                this.form.region.gbCode = val.provinceGbCode
                this.form.region.province = val.provinceName
            }
        },
        getDepartmentsByConfigured () {
            this.$store.dispatch('getDepartmentsByConfigured').then(res => {
                this.departmentList = res
            }).catch(err => {
                this.$message.error(err || '获取科室数据失败')
            })
        },
    },
    created () {
        this.getDepartmentsByConfigured()
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.institution-searchSrawer{
    .el-drawer{
    min-width: 300Px;
    }
}
.el-drawer__wrapper{
	position: absolute;
}
.clear-btn-class{
    color: @mainColor;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
}
.ss-form-class{
    margin-left: 20px;
		.province-city{
			width: 200px!important;
		}
		.el-select{
			width: 200px!important;
			.el-input{
				width: 100%;
			}
		}
    & .el-form-item__label{
        text-align:left;
        float: none;
        word-break: break-word;
    }
    & .el-form-item{
        text-align:left;
        margin-bottom: 10px;
    }
    .wt-address-select .province-district .province-city .el-select{
        width: 193px;
    }
    .wt-address-select .province-district .province-city{
        width: 193px;
    }
}

</style>