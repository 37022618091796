<template>
<div>
  <pt-home class="pt-home__page"></pt-home>
</div>
</template>

<script>
import PtHome from 'cps/pt-home/withData'
export default {
  name: 'pt-home__view',
  components: {
    'pt-home': PtHome
  }
}
</script>

