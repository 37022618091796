<!--左侧菜单组件-->
<template>
  <div class="pt-side-component-nav">
    <div class="pt-side-component--top">
			<div>HRV管理平台</div>
      <!-- <router-link to="/" ><img src="@/assets/logo.png" alt="" class="logo"></router-link> -->
      <div class="side-component-user-info">
        <!-- <wt-avatar size="60px"><img :src="headImg||require('@/assets/images/avatar.png')" alt="" class="avatar"></wt-avatar> -->
        <span>{{this.$auth.user().fullName}}</span>
      </div>
    </div>
    <wt-menu-tree :config="config" :class="$style['menu-tree']" defaultActive="/" action="url" text="displayName"></wt-menu-tree>
    <!-- <img src="@/assets/images/bottom_logo.png" alt="" class="side-component-nav__img--background"> -->
    <!-- <el-button type="primary">test</el-button> -->
  </div>
</template>

<script>
import { menuConfig } from './menu-config.js'
export default {
  name: 'pt-side-component-nav',
  data () {
    return {
    /**当前使用假数据，暂时注释该部分，从后端获取后打开 */
      config: this.$store.state.menuList,
      // config:menuConfig,
      headImg: (this.$auth.user() && this.$auth.user().headImage) || null
    }
  },
  watch: {
    /**当前使用假数据，暂时注释该部分，从后端获取后打开 */
    '$store.state.menuList': {
      handler: function (val) {
				if(val&&val.length){
        this.config = val
				}
      },
      immediate: true
    }
  },
  methods:{
    
  }

}
</script>
<style module lang="less">
.menu-tree{
}
</style>
<style  lang="less">
@import "~less/var.less";
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
.pt-side-component-nav{
  position: relative;
  overflow-y: hidden;
  min-height: 100%;
  box-sizing: border-box;
  background-color: @menuBackgroundColor
}
img.side-component-nav__img--background{
  position: absolute;
  left: -10px;
  bottom: -40px;
  width: 95%;
  z-index: 1;
}
.pt-side-component--top{
  display: flex;
  // flex-direction: column;
  align-items: center;
  text-align: center;
	justify-content: center;
	color: #13C4AE;
	font-weight: 900;
	font-size: 25px;
	height: 100px;
	font-style: italic;
  img.logo{
    width: 46%;
    padding-top: 7px;
  }
  .side-component-user-info{
    align-items: center;
    flex-direction: column;
    display: flex;
    margin:40PX 0 30PX;
    color: #fff;
    font-size: 16PX;
    span{
      margin-top:10px;
    }
  }
}
// 覆盖默认菜单树样式
.pt-side-component-nav{
    z-index: 2;
  .wt-menu-tree .el-menu .el-menu-item:hover,
  .wt-menu-tree .el-menu .el-menu-item:focus,
  .wt-menu-tree .el-menu .el-submenu__title:hover{
    background-color: #60CCBE;
    color: #fff;
	}
	.el-menu-vertical-demo{
		overflow-y:hidden
	}
  .wt-menu-tree .el-menu .el-menu-item{
      li{
        &.is-active{
        color:#fff;
        background: @mainColor;
        .iconfont{
          color:#fff;
        }
        }
      }
  }
  .wt-menu-tree .iconfont{
    margin-right: 10px!important;
    font-size: 1.2rem;
    color: @mainColor;
  }
  // .el-menu-item:focus, .el-menu-item:hover{
  //     background-color: #E7F8FF;
  //     color: #20A1FF;
  //   i{
  //     color: #20A1FF;
  //   }
  // }
  .el-menu-item.is-active{
     color:#fff!important;
    background: @mainColor!important;
    .iconfont{
       color:#fff!important;
    }
  }
  .el-menu-item,
  .el-submenu .el-submenu__title{
    border-bottom:none
  }
  .wt-menu-tree-item .el-menu-item{
    border-bottom:none
  }
}

</style>
