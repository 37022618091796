import request from "./request";
import router from "@/router";
import env from "../api";
// export function getUserMessage(login) {
//   return request(`/api/users/`, "GET", login);
// }
// 创建患者信息
export function getUsers(data) {
  return request(`/services/hpd/api/doctors/page-by-query`, "POST", data);
}
export function getGroupList() {
  return request(`/api/user-manager/groups`, "GET");
}
export function getGroupDetail(id) {
  return request(`/services/hpd/api/doctors/detail/${id}`, "GET");
}
export function delGroupDetail(id) {
  return request(`/services/hpd/api/doctors/${id}`, "DELETE");
}
export function createGroupDetail(data) {
  return request(`/services/hpd/api/doctors/create`, "POST", data);
}
export function updateGroupDetail(data) {
  return request(`/services/hpd/api/doctors/update`, "PUT", data);
}