/**
 * 配置需要挂载到vue.prototype的字段
 */
import apiFunction from './api'
import { join } from 'formats/url'
import { uploadBigFile } from 'utils/oss'
import Vue from 'vue'
// 针对api调用的配置，所有需要环境判定的api参数配置，需等api异步获取成功后方可赋值
export let prototype = null
async function apiReturn() {
  let api = await apiFunction || { env: {} }
  prototype = {
    // 私有图片获取接口
    $privateResourcesUrl: join(api.api_server, api.env.VUE_APP_PRIVATE_RESOURCES),
    // 公开图片获取接口
    $publicResourcesUrl: (api.env.VUE_APP_PUBLIC_RESOURCES && api.env.VUE_APP_PUBLIC_RESOURCES.indexOf('/api') > -1) ? join(api.api_server, api.env.VUE_APP_PUBLIC_RESOURCES) : api.env.VUE_APP_PUBLIC_RESOURCES,
    // 公开图片下载
    $publicResourcesUrlDownload: join(api.api_server, api.env.VUE_APP_PUBLIC_RESOURCES_DOWNLOAD),
    // 私有图片获取接口
    $privateResourcesUrlDownload: join(api.api_server, api.env.VUE_APP_PRIVATE_RESOURCES_DOWNLOAD),
    // 以上两个接口还可以添加styleName参数，指定图片大小，压缩等情况
    // 服务器地址api
    $apiVueAppDomain: api.vue_app_domain,
    $apiUserSystemAppkey: api.system_app_key,
    $apiUserReservationAppkey: api.reservation_app_key,
    $apiUserFieldAppkey: api.field_app_key,
    $apiServer: api.api_server,
    // 图片上传api
    $uploadApi: api.api_server + '/services/hpd/api/upload',
    // 图片上传，可通过key参数指定返回数据里的字段名称
    $uploadApiKey: api.api_server + '/integration/api/upload-json',
    // 上传文件工具方法
    $upload: uploadBigFile,
    // 包含所有环境配置的$api对象
    $envConfig: api
  }
  Object.keys(prototype).map(key => {
    Vue.prototype[key] = prototype[key]
  })
}
export default apiReturn()