<template>
    <div class="pt-hostdetail__withData">
        <pageheader title="客户主机详情">
            <tab :dataList='tabList' @change='tabChange'/>
        </pageheader>
        <div style="height:20px;"></div>
        <div v-show="selectTab === 0">
          <hostAbstract :item='item' @upload='uploadMessage'/>
        </div>
        <div v-show="selectTab === 1">
          <terminalManger :item='item' @upload='uploadMessage'/>
        </div>
        <div v-show="selectTab === 2">
          <authorizationManager :item='item'/>
        </div>
        <div v-show="selectTab === 3">
          <caseData :item='item'/>
        </div>
        <div v-show="selectTab === 4">
          <operationsLog :item='item'/>
        </div>
        <div v-show="selectTab === 5">
          <versionRecord :item='item' @upload='uploadMessage'/>
        </div>
    </div>
</template>

<script>
import pageheader from '../base/pt-page-header/index'
import tab from '../base/pt-tab/index'
import hostAbstract from './detail-componens/host-abstract'
import terminalManger from './detail-componens/terminal-manger'
import authorizationManager from './detail-componens/authorization-manager'
import caseData from './detail-componens/case-data'
import operationsLog from './detail-componens/operations-log'
import versionRecord from './detail-componens/version-record'
import { detail } from '../../utils/hostServices'
export default {
  name: 'pt-hostdetail__withData',
  components: {
    tab,
    pageheader,
    hostAbstract,
    terminalManger,
    authorizationManager,
    caseData,
    operationsLog,
    versionRecord
  },
  data(){
      return{
          id: '',
          tabList:[
            {
              id: 0,
              title: '主机摘要'
            },
            {
              id: 1,
              title: '终端管理'
            },
            {
              id: 2,
              title: '授权管理'
            },
            {
              id: 3,
              title: '案例数据'
            },
            {
              id: 4,
              title: '运维日志'
            },
            {
              id: 5,
              title: '版本记录'
            }
          ],
          selectTab: 0,
          item: {}
      }
  },
  created (){
     this.id = this.$route.query.id
     this.item.deviceBaseInfoId = this.id
     this.getData()
  },
  methods:{
    tabChange(index){
      this.selectTab = index
    },
    uploadMessage(){
      this.getData()
    },
    getData(){
      detail(this.id).then((res)=>{
          this.item = res.data
      })
    }
  }
  
}
</script>
<style lang="less">
@import "~less/var.less";
.pt-hostdetail__withData{
  max-width: 100%;
	height: 100%;
	overflow-y: auto;
  padding: 0 20px 20px 20px;
  .flex-class{
      display: flex;
      width: 100%;
      height: 38PX;
  }
  .el-button--primary{
      background-color: @mainColor;
  }
  .input-content-class{
      width: 200px;
  }
  .del-btn-class{
    cursor: pointer;
    color: @warningColor;
  }
  .edit-btn-class{
    cursor: pointer;
    color: @mainColor;
  }
}
</style>