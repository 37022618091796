<template>
<div>
  <pt-hostdetail class="pt-hostdetail__page"></pt-hostdetail>
</div>
</template>

<script>
import PtHostDetail from 'cps/pt-host/detail'
export default {
  name: 'pt-hostdetail__view',
  components: {
    'pt-hostdetail': PtHostDetail
  }
}
</script>

