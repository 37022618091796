import Vue from "vue";
import "./axios"
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./register-global-components"
import "./platform/pc"
import "./auth"
import "./prototype"
import env from "./api"
import _typeof from 'formats/typeof.js'
import elementUI from 'element-ui'
import i18n from './i18n'
import "utils/formatUtils/validate"
import './assets/iconfont/iconfont.css'
import './assets/iconfont/iconfont.js'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;
// import '../theme/index.css'
window._ = _typeof

function getUrl () {
  env.then(res => {
    Vue.axios.defaults.baseURL = res && res.api_server
    api()
  })
}
// 让axios在所有请求的时候带上cookie - 微服务平台需要
function api () {
  Vue.axios.defaults.withCredentials = true
  Vue.use(elementUI, {
    i18n: (key, value) => i18n.t(key, value)
  })

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App)
  }).$mount("#app");
}
export default getUrl()