<template>
<div>
  <pt-host class="pt-host__page"></pt-host>
</div>
</template>

<script>
import PtHost from 'cps/pt-host/withData'
export default {
  name: 'pt-host__view',
  components: {
    'pt-host': PtHost
  }
}
</script>

