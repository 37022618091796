<template>
    <div class="pt-institutionsedit__withData">
        <pageheader :title="title"></pageheader>
        <el-card>
            <div class="form-content-class">
                <el-form ref="ruleForm" label-position='left' :model="form" :rules="rules"
                    label-width="100px" size="medium" :validate-on-rule-change="true">
                    <el-form-item label="机构类型:" prop='type'>
                        <el-radio-group v-model="form.type" @change="typeChange">
                            <el-radio label="HOSPITAL">医疗机构</el-radio>
                            <el-radio label="ORGANIZATION">非医疗机构</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <template v-if="!timer">
                        <el-form-item label="机构名称:" prop='name'>
                            <el-input placeholder='请输入机构名称' v-model="form.name"
                                @keyup.native="onkeyup('name')"></el-input>
                        </el-form-item>
                        <template v-if="form.type === 'HOSPITAL'">
                            <el-form-item label="机构等级:" prop='rank'>
                                <el-select v-model="form.rank" placeholder="请选择"
                                    @keyup.native="onkeyup('rank')">
                                    <el-option v-for="item in rankList" :key="item.id"
                                        :label="item.title" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="科室:" prop='departmentStr'>
                                <el-select v-model="form.departmentStr" placeholder="请选择"
                                    @keyup.native="onkeyup('departmentStr')" filterable
                                    allow-create >
                                    <el-option v-for="item in departmentList" :key="item.name"
                                        :value="item.name">
                                        <span style="float: left">{{ item.name }}</span>
                                        <i class="el-icon-error"
                                            @click.stop="deleteDepartment(item)"
                                            style="float: right;height: 100%;display: flex;align-items: center;"></i>
                                    </el-option>
                                </el-select>
                                <template v-if="showAddDepartment">
                                    <i class="el-icon-circle-plus" @click="addDepartment"
                                        style="margin-left: 10px;height: 100%;font-size: 20px;"></i>
                                    <span style="color: red;margin-left: 5px;">点击“+”添加科室</span>
                                </template>
                            </el-form-item>
                        </template>
                        <el-form-item label="所属地区:" prop='region'>
                            <addressSelect
                                :showElement='{ province: true,city: true,district: true,postAddress: false}'
                                :propFilds="{ provinceName: 'provinceName',cityName: 'cityName',districtName: 'districtName',provinceGbCode: 'provinceGbCode',cityGbCode: 'cityGbCode',districtGbCode: 'gbCode'}"
                                :value='form.region' @change='regionChange'
                                @keyup.native="onkeyup('region')">
                            </addressSelect>
                        </el-form-item>
                        <el-form-item label="通讯地址:" prop='address'>
                            <el-input placeholder='请输入通讯地址' v-model="form.address"
                                @keyup.native="onkeyup('address')"></el-input>
                        </el-form-item>
                        <el-form-item label="登记号:" prop='registerNum'
                            v-if="form.type === 'HOSPITAL'" class="no-requery-left-class">
                            <el-input placeholder='请输入登记号' v-model="form.registerNum"
                                @keyup.native="onkeyup('registerNum')"></el-input>
                        </el-form-item>
                        <el-form-item label="统一代码:" prop='uniformCode'>
                            <el-input placeholder='请输入统一代码' v-model="form.uniformCode"
                                @keyup.native="onkeyup('uniformCode')"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人:" prop="contacts" class="no-requery-left-class">
                            <el-input placeholder='请输入联系人' v-model="form.contacts"
                                @keyup.native="onkeyup('contacts')"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话:" prop="contactsPhone"
                            class="no-requery-left-class">
                            <el-input placeholder='请输入联系电话' v-model="form.contactsPhone"
                                @keyup.native="onkeyup('contactsPhone')"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="type === 'edit'"
                                @click="delForm('ruleForm')">删除</el-button>
                            <el-button @click="resetForm('ruleForm')">取消</el-button>
                            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                        </el-form-item>
                    </template>
                </el-form>
            </div>
        </el-card>
    </div>
</template>

<script>
import pageheader from '../base/pt-page-header/index'
import addressSelect from '../base/pt-address-select/withData'
import { add, detail, del } from '../../utils/institutionsService'
import { departmentEnumList } from "../../utils/total-message"
import { Loading } from 'element-ui'
var contactsPhone = (rule, value, callback) => {
    if (/^(\({0,1}\d{3,4})\){0,1}(-){0,1}(\d{7,8})$/.test(value) || /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value) || !value) {
        callback();
    } else {
        callback(new Error('请输入正确格式的电话号码'));
    }
};
var currencyNumber = (rule, value, callback) => {
    if (/^[A-Za-z0-9]{3,20}$/.test(value) || !value) {
        callback();
    } else {
        callback(new Error('请输入3至20位只能包含数字和字母登记号'));
    }
}
var taxpayerNumber = (rule, value, callback) => {
    if (/^[A-HJ-NP-RT-UW-Y0-9]{15}$|^[A-HJ-NP-RT-UW-Y0-9]{18}$/.test(value)) {
        callback();
    } else {
        callback(new Error('请输入正确的统一社会信用代码'));
    }
}
var contacts = (rule, value, callback) => {
    if (!/[^a-zA-Z0-9\u4e00-\u9fa5]/.test(value) || !value) {
        callback();
    } else {
        callback(new Error('联系人不能含有特殊符号'));
    }
}
export default {
    name: 'pt-edit__withData',
    components: {
        pageheader,
        addressSelect
    },
    data () {
        return {
            timer: false,
            loadingInstance: null,
            type: 'add',
            title: '新增客户机构',
            rules: {
                type: [
                    { required: true, message: '请选择机构类型', trigger: 'change' }
                ],
                name: [
                    { required: true, message: '机构名称不能为空', trigger: 'blur' }
                ],
                rank: [
                    { required: true, message: '请选择机构等级', trigger: 'change' }
                ],
                departmentStr: [
                    { required: true, message: '请选择科室', trigger: 'change' }
                ],
                region: [
                    { required: true, message: '请选择所属地区', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '通讯地址不能为空', trigger: 'blur' }
                ],
                uniformCode: [
                    { required: true, message: '统一代码不能为空', trigger: 'blur' },
                    { validator: taxpayerNumber, trigger: 'blur' }
                ],
                contacts: [
                    { validator: contacts, trigger: 'blur' }
                ],
                contactsPhone: [
                    { validator: contactsPhone, trigger: 'blur' }
                ],
                registerNum: [
                    { validator: currencyNumber, trigger: 'blur' }
                ],

            },
            rankList: [
                {
                    id: 'FIRST_A',
                    title: '一级甲等'
                },
                {
                    id: 'FIRST_B',
                    title: '一级乙等'
                },
                {
                    id: 'FIRST_C',
                    title: '一级丙等'
                },
                {
                    id: 'SECOND_A',
                    title: '二级甲等'
                },
                {
                    id: 'SECOND_B',
                    title: '二级乙等'
                },
                {
                    id: 'SECOND_C',
                    title: '二级丙等'
                },
                {
                    id: 'THIRD_A',
                    title: '三级甲等'
                },
                {
                    id: 'THIRD_B',
                    title: '三级乙等'
                },
                {
                    id: 'THIRD_C',
                    title: '三级丙等'
                },
                {
                    id: 'OTHER',
                    title: '其他'
                }
            ],
            departmentList: [],
            departmentStr: "",
            id: '',
            form: {
                type: 'HOSPITAL',
                rank: null,
                departmentEnum: null,
                departmentStr:null,
                region: {
                    city: null,
                    cityGbCode: null,
                    gbCode: null,
                    district: null,
                    provinceGbCode: null,
                    province: null
                }
            }
        }
    },
    watch: {
        'form.type': {
            handler: function (val) {
                if (val !== 'HOSPITAL') {
                    this.form.rank = null
                    this.form.departmentEnum = null
                    this.form.departmentStr=null
                }
            }
        },
        'form.type': {
            handler: function (val) {
                for (let key in this.form) {
                    if (this.form[key] && key !== 'type' && key !== 'region') {
                        let vm = this
                        setTimeout(_ => {
                            vm.$refs.ruleForm.validateField(key)
                        }, 100)
                    }
                }
            }
        },
    },
    computed: {
        showAddDepartment () {
            let isNewDepartment = true
            this.departmentList.forEach(item => {
                if (item.name === this.form.departmentStr) {
                    isNewDepartment = false
                }
            });
            return this.form.departmentStr && isNewDepartment
        }
    },
    created () {
        this.type = this.$route.query.type
        if (this.type === 'add') {
            this.title = '新增客户机构'
        } else if (this.type === 'edit') {
            this.title = '编辑客户机构'
            this.id = this.$route.query.id
            // 获取用户详情
            this.getDetail()
        }
        this.getDepartmentsByConfigured()
    },
    methods: {
        deleteDepartment (item) {
            this.$store.dispatch('deleteDepartment', item.id).then(res => {
                this.$message.success('删除科室成功')
                this.getDepartmentsByConfigured()
            }).catch(err => {
                this.$message.error(err || '删除科室失败')
            })
        },
        addDepartment () {
            let data = [{
                name: this.form.departmentStr
            }]
            this.$store.dispatch('addDepartments', data).then(res => {
                this.$message.success('添加科室成功')
                this.getDepartmentsByConfigured()
            }).catch(err => {
                this.$message.error(err || '添加科室失败')
            })
        },
        getDepartmentsByConfigured () {
            this.$store.dispatch('getDepartmentsByConfigured').then(res => {
                this.departmentList = res
                this.departmentList.forEach(item => {
                    if (item.name === this.form.departmentEnum) {
                        this.form.departmentEnum = item.id
                    }
                });
            }).catch(err => {
                this.$message.error(err || '获取科室数据失败')
            })
        },
        onkeyup (name) {
            let vm = this
            setTimeout(function () {
                vm.$refs.ruleForm.validateField(name)
            }, 1000);
        },
        getDetail () {
            detail(this.id).then(res => {
                this.form = res.data
                // 地区组件赋值,不起作用,需要看看
                // this.region.cityName = this.form.region.city
                // this.region.cityGbCode = this.form.region.cityGbCode
                this.form.region.districtGbCode = this.form.region.gbCode
                // this.region.districtName = this.form.region.district
                // this.region.provinceGbCode = this.form.region.provinceGbCode
                // this.region.provinceName = this.form.region.province
                // this.region.cityName = this.form.region.city
            }).catch(err => {
                this.$message.error(err || '获取数据失败')
            })
        },
        typeChange (val) {
            if (val === 'HOSPITAL') {
                this.rules = {
                    type: [
                        { required: true, message: '请选择机构类型', trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '机构名称不能为空', trigger: 'blur' }
                    ],
                    rank: [
                        { required: true, message: '请选择机构等级', trigger: 'change' }
                    ],
                    departmentStr: [
                        { required: true, message: '请选择科室', trigger: 'change' }
                    ],
                    region: [
                        { required: true, message: '请选择所属地区', trigger: 'blur' }
                    ],
                    address: [
                        { required: true, message: '通讯地址不能为空', trigger: 'blur' }
                    ],

                    uniformCode: [
                        { required: true, message: '统一代码不能为空', trigger: 'blur' },
                        { validator: taxpayerNumber, trigger: 'blur' }
                    ],
                    contacts: [
                        { validator: contacts, trigger: 'blur' }
                    ],
                    contactsPhone: [
                        { validator: contactsPhone, trigger: 'blur' }
                    ],
                    registerNum: [
                        { validator: currencyNumber, trigger: 'blur' }
                    ],
                }
            } else {
                this.rules = {
                    type: [
                        { required: true, message: '请选择机构类型', trigger: 'blur' }
                    ],
                    name: [
                        { required: true, message: '机构名称不能为空', trigger: 'blur' }
                    ],
                    region: [
                        { required: true, message: '请选择所属地区', trigger: 'blur' }
                    ],
                    address: [
                        { required: true, message: '通讯地址不能为空', trigger: 'blur' }
                    ],

                    uniformCode: [
                        { required: true, message: '统一代码不能为空', trigger: 'blur' },
                        { validator: taxpayerNumber, trigger: 'blur' }
                    ],
                    contacts: [
                        { validator: contacts, trigger: 'blur' }
                    ],
                    contactsPhone: [
                        { validator: contactsPhone, trigger: 'blur' }
                    ],
                    registerNum: [
                        { validator: currencyNumber, trigger: 'blur' }
                    ],
                }
            }
            this.timer = true
            setTimeout(() => {
                this.timer = false
            });
            // this.$refs['ruleForm'].clearValidate()

        },
        regionChange (val) {
            this.form.region.city = val.cityName
            this.form.region.cityGbCode = val.cityGbCode
            this.form.region.gbCode = val.gbCode
            this.form.region.district = val.districtName
            this.form.region.provinceGbCode = val.provinceGbCode
            this.form.region.province = val.provinceName
        },
        submitForm (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitData()
                } else {
                    return false;
                }
            })
        },
        submitData () {
            //   console.log(this.form)
            //   return
            if (this.showAddDepartment) {
                this.$message.error('请先提交新增科室')
                return
            }
            this.loadingInstance = Loading.service({ text: '请稍等' })
            if (this.form.type === 'ORGANIZATION') {
                if (this.form.rank) {
                    this.$delete(this.form, 'rank')
                }
            }
            add({ hospital: this.form }).then(res => {
                this.$message({
                    message: '操作成功',
                    type: 'success'
                });
                this.$nextTick(() => {
                    this.loadingInstance.close();
                });
                this.$router.push('/institutions')
            }).catch(err => {
                this.$nextTick(() => {
                    this.loadingInstance.close();
                });
                this.$message.error(err || '操作失败')
            })
        },
        resetForm (formName) {
            this.$refs[formName].resetFields();
            this.$router.push('/institutions')
        },
        delForm (formName) {
            this.$confirm('提示：删除机构将自动移除已关联的客户主机，确认删除机构？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 删除
                this.loadingInstance = Loading.service({ text: '请稍等' })
                del(this.id).then(res => {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.$nextTick(() => {
                        this.loadingInstance.close();
                    });
                    this.$router.push('/institutions')
                }).catch(err => {
                    this.$nextTick(() => {
                        this.loadingInstance.close();
                    });
                    this.$message.error(err || '删除失败')
                })
            })
        }
    }

}
</script>
<style lang="less">
@import "~less/var.less";
.pt-institutionsedit__withData{
  max-width: 100%;
  padding: 0 20px 20px 20px;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
	.province-district{
		.province-city{
		width: 100%;
		}
	}
    .el-card{
      overflow-y: auto!important;
    }
    .el-form-item{
        display: flex;
        align-items: center;
    }
    .el-form-item__content{
        display: flex;
        align-items: center;
    }
  .form-content-class{
      margin: 20px 0 0 20px;
      float: left;
			.el-input {
				width: 200px;
			}
  }
}
</style>