import request from "./request";
import router from "@/router";
import env from "../api";
// 首页统计数据
export function getHomeData() {
    return request(`/services/installbase/api/home/portal-data`, "GET");
}
export function getCount() {
    return request(`/services/hpd/api/hospitals-manager/admin/page-by-query/all/count`, "POST",{HospitalQueryVO: {}});
}
