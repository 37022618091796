let _router = {
  _init: function() {
    if (!this.options.Vue.router) {
      return 'vue-router.2.x.js : Vue.router must be set.'
    }
  },

  _bindData: function(data, ctx) {
    var error, success

    data = data || {}

    error = data.error
    success = data.success

    data.query = (ctx._routerRoot && ctx.$route.query) || {}

    if (data.success) { data.success = function(res) { success.call(ctx, res) } }
    if (data.error) { data.error = function(res) { error.call(ctx, res) } }

    return data
  },

  _beforeEach: function(routerBeforeEach, transitionEach) {
    var _this = this
    this.options.Vue.router.beforeEach(function(transition, location, next) {
      _this.options.setTransitions.call(this, transition)

      routerBeforeEach.call(_this, function() {
        var auth = _this.options.getAuthMeta(transition)

        transitionEach.call(_this, transition, auth, function(redirect) {
          if (!redirect) {
            (next || transition.next)()
            return
          }

          // router v2.x
          if (next) {
            next(redirect)
          } else {
            this.options.router._routerReplace.call(this, redirect)
          }
        })
      })
    })
  },

  _routerReplace: function(data) {
    // var router = this.options.Vue.router
    // router.replace.call(router, data)
  },

  _routerGo: function(data) {
    var router = this.options.Vue.router;

    (router.push || router.go).call(router, data)
  }
}

export default _router