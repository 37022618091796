<template>
  <div :class="$style['page-container']">
    <pt-com-header :name='name'></pt-com-header>
    <div class="page-main-content">
      <div class="page-left">
        <pt-left-menu></pt-left-menu>
      </div>
      <wt-view class="page-main"></wt-view>
    </div>
  </div>
</template>
<script>
import ptComHeader from "base/pt-com-header"
import ptLeftMenu from "base/pt-left-menu"
export default {
  name: 'component-nav',
  data (){
    return{
      menuList:this.$t('menu'),
      menuHeight:900,
      ctx:null,
      name: ''
    }
  },
  components:{
    ptComHeader,
    ptLeftMenu
  },
  computed:{

  },
  methods:{
  },
  created (){
    setTimeout(()=>{
      let UserInfo = localStorage.getItem("UserInfo")
      if (UserInfo){
        let str = JSON.parse(UserInfo)
        this.name = str.firstName
      }
    },500)
  }
}
</script>
<style module lang="less">
@import "~less/var.less";
.page-container{
  width: 100%;
  background-color: @backgroundColor;
  height: 100%;
  box-sizing: border-box;
  // color: white;
  display: flex;
  flex-direction: column;
  :global{
    .page-main-content{
      top: 60px;
      display: flex;
      flex: 1;
			height: 100%;
			overflow-y:hidden ;
      .page-left{
        width: 240PX;
        min-width: 240PX;
  // background-color:#454545;
  overflow-y: auto;
      }
    }
    .page-main{
      // padding: 10px 20px;
      height: 100%;
      width: 100%;
			overflow: hidden;
			position: relative;
			>div{
				height: 100%;
				overflow: hidden;
			}
    }
  }
}
</style>