<template>
    <el-card>
        <div class="pt-case-data-class">
            <div class="sub-title-class">运维日志</div>
            <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="tableData">
                <el-table-column prop="sn" label="主机序列号" width='300'></el-table-column>
                <el-table-column prop="hardwareVersion" label="硬件版本"> </el-table-column>
                <el-table-column prop="softwareVersion" label="软件版本"> </el-table-column>
                <el-table-column prop="createdDate" label="上传时间">
                    <template slot-scope="scope">
                        <span>{{formatDateObject(scope.row.createdDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="操作">
                    <template slot-scope="scope">
                        <div class="flex-class">
                            <div class="del-btn-class" @click="delCase(scope.row)">删除</div>
                            <el-divider direction="vertical"></el-divider>
                            <div class="edit-btn-class" @click="downloadCase(scope.row)">下载</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <ptPagination ref="ptPagination" @paginationchange='paginationchange'></ptPagination>
        </div>
    </el-card>
</template>

<script>
import pageheader from '../../base/pt-page-header/index'
import { logHost, delLogHost } from '../../../utils/hostServices'
import { formatDateObject } from '../../../utils/formatUtils/date'
import ptPagination from '../../base/pt-pagination/index'
export default {
  components: {
    pageheader,
    ptPagination
  },
  props:{
        item: {
            type: Object,
            default(){
                return {}
            }
        }
    },
    data(){
        return{
            form: {
                pageable:{
                    page: 0,
                    size: 10
                }
            },
            total: 0,
            tableData: []
        }
    },
    created (){
        
    },
    methods:{
        getData(){
            this.form.example = {deviceBaseInfoId:this.item.deviceBaseInfoId ? this.item.deviceBaseInfoId : this.item.id}
            logHost(this.form).then((res)=>{
                this.tableData = res.data
                this.$refs.ptPagination.totalMethod(res)
            })
        },
        formatDateObject(val){
            return formatDateObject(val)
        },
        paginationchange(pageable){
            this.form.pageable = pageable
            this.getData()
        },
        delCase(row){
            this.$confirm('确认删除该日志?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delLogHost(row.id).then(res=>{
                    this.$message({
                    message: '删除成功',
                    type: 'success'
                    });
                    this.form.pageable = {page: 0, size: 10}
                    this.getData()
                }).catch(err=>{
                    this.$message.error(err||'删除失败')
                })
            })
        },
        downloadCase(row){
            if (row.filePath){
                let url = row.filePath
                window.open(this.$privateResourcesUrlDownload + url)
                // fetch(url).then(res => res.blob()).then(blob => {
                //     let a = document.createElement("a");
                //     a.href = URL.createObjectURL(blob)
                //     a.download = url.split('/')[url.split('/').length -1]
                //     document.body.appendChild(a)
                //     a.click()
                // })
            }
        }
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.pt-case-data-class{
max-width: 100%;
  padding: 20px;
  .flex-class{
      display: flex;
  }
  .sub-title-class{
        text-align: left;
        font-size: 14px;
        font-weight: 600;
    }
  .el-button{
      background-color: @mainColor;
  }
  .input-content-class{
      width: 200px;
  }
  .del-btn-class{
    cursor: pointer;
    color: @warningColor;
  }
  .edit-btn-class{
    cursor: pointer;
    color: @mainColor;
  }
}
</style>