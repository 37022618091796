<template>
  <div :class="$style['edit-password-dialog']">
		<el-form ref="ruleForm" label-position='left' :model="form"  label-width="90px" :rules="rules" size="medium">
			<el-form-item label="旧密码:" prop='oldPassword'>
				<el-input v-model="form.oldPassword" @keyup.native="onkeyup('oldPassword')" class="password-show"></el-input>
				</el-form-item>
				<el-form-item label="新密码:" prop='newPassword'>
				<el-input v-model="form.newPassword" class="password-show" @keyup.native="onkeyup('newPassword')"></el-input>
				</el-form-item>
				<el-form-item label="再次输入:" prop='newAgainPassword'>
				<el-input v-model="form.newAgainPassword" class="password-show" @keyup.native="onkeyup('newAgainPassword')"></el-input>
				</el-form-item>
		</el-form>
    <div class="button-div">
      <div class="footer">
        <el-button type="plain" @click="cancel">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>

var password=(rule, value, callback) => {
	if(/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*.`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*.`~()-+=]+$)(?![0-9\\W_!@#$%^&*.`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*.`~()-+=]{8,30}$/.test(value)){
		callback();
	}else{
		callback(new Error('请输入8至32位至少包含大写、小写字母，数字和特殊字符其中三种作为密码'));
	}
}
export default {
  data (){
    return {
      form:{},
      widthMax:'80',
			rules:{
				oldPassword:[
					{ required: true, message: '旧密码不能为空', trigger: 'blur'}
				],	
				newPassword:[
					{ required: true, message: '新密码不能为空', trigger: 'blur'},
					{validator:password, trigger: 'blur'}
				],
				newAgainPassword:[
					{ required: true, message: '请再次输入新密码', trigger: 'blur'},
					{validator:password, trigger: 'blur'}
				],
			}
    }
  },
  computed:{
    login (){
      return this.$auth.user() && this.$auth.user().login
    }
  },
  methods:{
		onkeyup(name){
			let vm=this
			setTimeout(function(){
				vm.$refs.ruleForm.validateField(name)
			}, 1000);
		},
    cancel (){
      this.$emit('closeDialog')
    },
    submit (){
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					if(this.form.newPassword !== this.form.newAgainPassword){
						this.$message.error('请确认2次密码输入一致')
						return
					}
					this.getLogin()
				}else {
                return false;
				}
		})
    },
    getLogin (){
      let data = {
        username:this.login,
        password:this.form.oldPassword
      }
      this.$store.dispatch('getLogin', data).then(res => {
        this.editCurrentPassword()
      }).catch(err => {
        this.$message.error('旧密码不正确')
      })
    },
    editCurrentPassword (){
      let data = {
        newPassword:this.form.newPassword
      }
      this.$store.dispatch('editCurrentPassword', data).then(res => {
        this.$message.success('修改密码成功')
        this.$emit('closeDialog')
      }).catch(err => {
        this.$message.error(err || '修改密码失败')
      })
    }
  }
}
</script>
<style module lang="less">
.edit-password-dialog{
:global{
	.password-show{
		-webkit-text-security:disc
	}
	.el-form-item{
		display: flex;
		.el-form-item__content{
			display: flex;
			flex: 1;
		}
	}
	.button-div{
		margin-top: 20Px;
		display: flex;
		justify-content: center;
		.footer{
			.el-button{
				padding: 12PX 30PX;
				font-size: 16PX
			}
		}
	}
	
}
}
</style>