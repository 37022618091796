<template>
    <div class="auth-manager">
        <el-card class="pt-abstract-class" v-show="!isAdd">
            <describe :item='item' />
            <el-divider></el-divider>
            <div class="flex-class am-top-content-class">
                <div class="sub-title-class">授权管理</div>
                <el-button class="am-add-btn-class" type="primary" icon='el-icon-plus'
                    @click="add">新增授权</el-button>
            </div>
            <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="dataList">
                <el-table-column prop="authorizationType" label="授权类型">
                    <template slot-scope="scope">
                        <span>{{getTypeValue(scope.row.authorizationType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="caseCount" label="次数上限">
                    <template slot-scope="scope">
                        <span>{{getCaseCountValue(scope.row)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="effectiveDate" label="生效日期">
                    <template slot-scope="scope">
                        <span>{{formatDateObject(scope.row.effectiveDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="invalidDate" label="失效日期">
                    <template slot-scope="scope">
                        <span>{{getInvalidDateValue(scope.row)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="functionConfig" label="授权型号">
                    <template slot-scope="scope">
                        <span>{{scope.row.functionConfig.model}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createdDate" label="授权日期">
                    <template slot-scope="scope">
                        <span>{{formatDateObject(scope.row.createdDate)}}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="terminalCount" label="终端数量">
                    <template slot-scope="scope">
                        <span>{{scope.row.terminalCount}}</span>
                    </template>
                </el-table-column> -->

                <el-table-column prop="terminalCount" min-width="180" label="终端序列号">
                    <template slot-scope="scope">
                        <template v-if="scope.row.terminalList&&scope.row.terminalList.length">
                            <div v-for="(item,index) in scope.row.terminalList" :key="index">
                                {{item}}
                            </div>
                        </template>
                        <div v-else>--</div>
                        <!-- <span>{{scope.row.terminalCount}}</span> -->
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="当前状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 0">未生效</span>
                        <span v-if="scope.row.status == 1">有效</span>
                        <span v-if="scope.row.status == 2">已完成</span>
                        <span v-if="scope.row.isUsing" class='use-tab'>使用中</span>
                    </template>
                </el-table-column>
                <el-table-column prop="authUserName" label="授权人员"></el-table-column>
                <el-table-column label="备注">
                    <template slot-scope="scope">
                        <span>{{ scope.row.remark }}</span>
                        <i class="el-icon-edit-outline" style="margin-left: 5px;"
                            @click="editRemark(scope.row)"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="" min-width="120" label="操作">
                    <template slot-scope="scope">
                        <div class="edit-btn-class" @click="activationCode(scope.row)">在线激活码</div>
                        <div class="del-btn-class" @click="del(scope.row)"
                            v-if="canDeleteAuthorization">删除</div>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="" label="授权下载">
                    <template slot-scope="scope">
                        <div class="edit-btn-class" @click="activationCode(scope.row)">在线激活码</div>
                    </template>
                </el-table-column> -->
            </el-table>
            <ptPagination ref="ptPagination" @paginationchange='paginationchange'></ptPagination>
            <authorizationDialog title='在线激活码' ref="authorizationDialog" />
        </el-card>
        <authorizationAdd v-show="isAdd" :item='item' @click="refresh" />
        <el-dialog title="编辑备注" width="30%" v-if="isEditRemarkDialog"
            :visible.sync="isEditRemarkDialog">
            <el-input v-model="authRemark"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelEditRemark">取 消</el-button>
                <el-button type="primary" @click="saveRemark">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import describe from './describe'
import ptPagination from '../../base/pt-pagination/index'
import { authorizationHost, delAuthorizationHost, edit } from '../../../utils/hostServices'
import { formatDateObject } from '../../../utils/formatUtils/date'
import authorizationDialog from '../../base/pt-authorization-dialog/index'
import authorizationAdd from './authorization-add'
export default {
    components: {
        describe,
        ptPagination,
        authorizationDialog,
        authorizationAdd
    },
    props: {
        item: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    computed: {
        //只有超级管理员可以删除授权
        canDeleteAuthorization () {
            return this.$store.getters.isSuporAdmin
        }
    },
    data () {
        return {
            editRemarkId: false,
            isEditRemarkDialog: false,
            isAdd: false,
            dataList: [],
            form: {
                pageable: { page: 0, size: 10, sort: ['createdDate,desc'] }
            },
            authRemark: '',
            selectedRow: {}
        }
    },
    mounted () {

    },
    methods: {
        cancelEditRemark () {
            this.isEditRemarkDialog = false
        },
        saveRemark () {
            let data = {
                remark: this.authRemark,
                id: this.selectedRow.id
            }
            this.$store.dispatch('renewAuthorizeInfosRemark', data).then(res => {
                this.$message.success('更新备注成功')
                this.isEditRemarkDialog = false
                this.getData()
            }).catch(err => {
                this.$message.error(err || '更新备注失败')
            })
        },
        editRemark (row) {
            this.selectedRow = row;
            this.authRemark = row.remark;
            this.isEditRemarkDialog = true
        },
        add () {
            if (this.item.terminalCount === 0) {
                this.$message({
                    message: '请至少绑定一个终端后再授权',
                    type: 'warning'
                });
                return
            }
            this.isAdd = true
        },
        del (row) {
            this.$confirm('确认删除该授权?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delAuthorizationHost(row.id).then((res) => {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.paginationchange({})
                })
            })
        },
        activationCode (row) {
            this.$refs.authorizationDialog.show([row])
        },
        paginationchange (pageable) {
            this.form.pageable = { ...this.form.pageable, ...pageable }
            this.getData()
        },
        refresh (val) {
            this.authorizationAdd(val)
        },
        authorizationAdd (val) {
            this.isAdd = false
            if (val === 1) {
                this.form.pageable = { page: 0, size: 10, sort: ['createdDate,desc'] }
                this.getData()
            }
        },
        getData () {
            if (this.item.deviceBaseInfoId) {
                this.form.example = { deviceBaseInfoId: this.item.deviceBaseInfoId }
            } else {
                this.form.example = { deviceBaseInfoId: this.item.id }
            }
            authorizationHost(this.form).then((res) => {
                this.dataList = res.data
                this.$refs.ptPagination.totalMethod(res)
            })
        },
        formatDateObject (val) {
            return formatDateObject(val, 'date')
        },
        getTypeValue (type) {
            if (type === 'EXAMINATION_TIMES') {
                return '按检查次数'
            }
            if (type === 'PERIOD_OF_VALIDITY') {
                return '按有效期限'
            }
            if (type === 'UNLIMITED') {
                return '无限制使用授权'
            }
            return ''
        },
        getCaseCountValue (row) {
            if (row.authorizationType === 'PERIOD_OF_VALIDITY') {
                return '无限制'
            }
            if (row.authorizationType === 'UNLIMITED') {
                return '无限制'
            }
            return row.caseCount
        },
        getInvalidDateValue (row) {
            if (row.authorizationType === 'UNLIMITED') {
                return '永久有效'
            }
            return this.formatDateObject(row.invalidDate)
        }
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.auth-manager{
    .el-dialog__header{
        display: flex;
    }
    .el-dialog__body{
        padding: 10Px;
    }
}
.pt-abstract-class{
    height: 100%;
    .el-table__body{
        .el-input__inner{
            height: 30px;
            line-height: 30px;
            padding: 0 10Px;
        }
    }
    .am-top-content-class{
        align-items: center;
    }
    .sub-title-class{
        text-align: left;
        font-size: 14px;
        font-weight: 600;
    }
    .am-add-btn-class{
        margin-left: 20px;
    }
		.use-tab{
				background: rgba(19,196,174,0.3);
				margin-left: 5px;
				border-radius: 5px;
				color: @mainColor;
				padding: 0 3px;
				font-size: 10Px;
				border: 1Px solid @mainColor;
			}
}
</style>