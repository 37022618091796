<template>
  <div class="wt-address-select">
    <wt-box class="province-district" direction="row">
      <wt-box direction="row" class="province-city">
        <wt-selector v-if="!showElement||showElement.province" :disabled="disabled||disabledObj.province" class="first-select" :options="provincesList" v-model="currentValue.provinceGbCode" :props="provinceProps" @change="provinceChange" :data-vv-as="validateText.province" name="name" :clearable="clearable||clearableObj.province" :loading="provinceLoading"></wt-selector>
        <wt-selector v-if="!showElement||showElement.city" :loading="cityLoading" :disabled="disabled||disabledObj.city" :options="cityList" v-model="currentValue.cityGbCode" :props="cityProps" @change="cityChange" :data-vv-as="validateText.city" name="name" :clearable="clearable||clearableObj.city"></wt-selector>
        <wt-selector :loading="districtLoading" v-if="!showElement||showElement.district" :disabled="disabled||disabledObj.district" :options="districtList" v-model="currentValue.districtGbCode" :props="districtProps" @change="districtChange" :data-vv-as="validateText.district" name="name" :clearable="clearable||clearableObj.district"></wt-selector>
      </wt-box>
      <!-- <wt-box direction="row" class="province-city" v-else>
			<wt-selector class="first-select" :options="provincesList" v-model="currentValue.provinceName" :props="provinceProps" @change="provinceChange" data-vv-as="收货人地址:省" name="name" v-validate="'required'"></wt-selector>
			<wt-selector :options="cityList" v-model="currentValue.cityName" :props="cityProps" @change="cityChange" data-vv-as="收货人地址：市" name="name" v-validate="'required'"></wt-selector>
			<wt-selector :options="districtList" v-model="currentValue.districtName" :props="districtProps" @change="districtChange"  data-vv-as="收货人地址：区" name="name" v-validate="'required'"></wt-selector>
		</wt-box> -->
      <wt-box class="area-detail">
        <wt-input v-if="!showElement||showElement.postAddress" :disabled="disabled||disabledObj.postAddress" v-model="currentValue.postAddress" @blur="blur" :data-vv-as="validateText.address" name="name" :clearable="clearable||clearableObj.address"></wt-input>
      </wt-box>
    </wt-box>
  </div>
</template>

<script>
// 组件class前缀
import formMixins from 'mixins/form'
const prefixCls = 'pt-address-select'
export default {
  mixins: [formMixins],
  name: 'pt-address-select',
  computed: {
    classes () {
      return `${prefixCls}`
    }
  },
  props: {
    value: {
      type: Object,
      default: function () {
        return {}
      }
    },
    provincesList: {
      type: Array,
      default: function () {
        return []
      }
    },
    cityList: {
      type: Array,
      default: function () {
        return []
      }
    },
    districtList: {
      type: Array,
      default: function () {
        return []
      }
    },
    propFilds: {
      type: Object,
      default: function () {
        return {}
      }
    },
    disabledObj: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    validateText: {
      type: Object,
      default: function () {
        return {}
      }
    },
    requiredObj: {
      type: Object,
      default: function () {
        return {}
      }
    },
    clearable: {
      type: Boolean,
      default: false
    },
    clearableObj: {
      type: Object,
      default: function () {
        return {}
      }
    },
    showElement: {
      type: [Object, String]
    },
    provinceLoading: Boolean,
    cityLoading: Boolean,
    districtLoading: Boolean
  },
  data () {
    return {
      currentValue: JSON.parse(JSON.stringify(this.value)),
      provinceProps: {
        label: 'province',
        value: 'gbCode'
      },
      cityProps: {
        label: 'city',
        value: 'gbCode'
      },
      districtProps: {
        label: 'district',
        value: 'gbCode'
      }
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.currentValue = JSON.parse(JSON.stringify(this.getFilds()))
        let fileds = this.propFilds
        if (val.provinceGbCode) {
          if (this.provincesList.length < 1) {
            if (typeof (val[fileds.provinceGbCode]) === 'string') {
              this.$set(this.currentValue, 'provinceGbCode', Number(val[fileds.provinceGbCode]))
            }
          }
          if (this.cityList.length < 1) {
            this.provinceChange(this.currentValue.provinceGbCode, true)
            // this.$emit('provinceChange', this.currentValue.provinceGbCode)
          }
        }
        if (val.cityGbCode) {
          if (this.cityList.length < 1) {
            if (typeof (val[fileds.cityGbCode]) === 'string') {
              this.$set(this.currentValue, 'cityGbCode', Number(val[fileds.cityGbCode]))
            }
          }
          if (this.districtList.length < 1) {
            this.cityChange(this.currentValue.cityGbCode, true)
          }
        }
        if (val.districtGbCode||val.gbCode) {
          this.$set(this.currentValue, 'districtGbCode', Number(val[fileds.districtGbCode]))
        }
      },
      deep: true,
      immediate: true
    },
    cityList (val) {
			if(val&&val.length){
      	this.formData()
			}
    },
    districtList (val) {
			if(val&&val.length){
				this.formData()
			}
		},
		provincesList(val){
			if(val&&val.length){
				this.formData()
			}
		}
  },
  methods: {
    // 第一个参数为“省”的gbcode，第二个参数为是否是初始获取的数据，如果是将不清空已选的市和区的选项
    provinceChange (val, isDefault) {
      if (val) {
        this.$emit('provinceChange', val)
      }
      if (!isDefault) {
        this.$set(this.currentValue, 'cityGbCode', '')
        if (!this.showElement || this.showElement.district) {
          this.$set(this.currentValue, 'districtGbCode', '')
        }
      }
    //   this.formData()
    },
    cityChange (val, isDefault) {
      if (val) {
        this.$emit('cityChange', val)
      }
      if (!isDefault) {
        if (!this.showElement || this.showElement.district) {
          this.$set(this.currentValue, 'districtGbCode', '')
        }
      }
    //   this.formData()
    },
    districtChange (val) {
      this.currentValue.districtGbCode = val && Number(val)
      this.formData()
      this.$emit('districtChange', val)
    },
    blur (val) {
      this.formData()
    },
    // 根据gbCode查询省/市/区名称
    getName (list, gbCode, name) {
			let addressName
      list.forEach(item => {
        if (gbCode === item.gbCode) {
          addressName = item[name]
        }
			})
      return addressName
    },
    // 返回currentValue中的省市区相关字段
    getFilds () {
      let city = {
        provinceGbCode: this.value[this.propFilds.provinceGbCode],
        provinceName: this.value[this.propFilds.provinceName],
        cityGbCode: this.value[this.propFilds.cityGbCode],
        cityName: this.value[this.propFilds.cityName],
        districtGbCode: this.value[this.propFilds.districtGbCode],
        districtName: this.value[this.propFilds.districtName],
        postAddress: this.value[this.propFilds.postAddress]
      }
      return city
    },
    // 返回数据按接口数据重组
    formData () {
      let data = { ...this.currentValue }
			let fild = this.propFilds || {}
      data[fild.provinceName] = this.currentValue.provinceGbCode ? this.getName(this.provincesList, this.currentValue.provinceGbCode, 'province') : this.currentValue.provinceName
      data[fild.cityName] = this.currentValue.cityGbCode ? this.getName(this.cityList, this.currentValue.cityGbCode, 'city') : this.currentValue.cityName
      data[fild.districtName] = this.currentValue.districtGbCode ? this.getName(this.districtList, this.currentValue.districtGbCode, 'district') : this.currentValue.districtName
      data[fild.provinceGbCode] = this.currentValue.provinceGbCode
      data[fild.cityGbCode] = this.currentValue.cityGbCode
			data[fild.districtGbCode] = this.currentValue.districtGbCode
      this.$emit('getValue', data)
    }
  }
}
</script>

<style lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
.wt-address-select{
.province-district{
    justify-content: flex-start;
    align-items: flex-start;
    // max-width: 600Px;
    display: flex;
        .province-city {
          line-height: normal!important;
          width: 70%;
        .el-select {
            margin-left: 0.1rem;
            margin-left: 10px;
            width: 33%
        }
        .first-select {
            margin-left: 0;
        }
    }
    .area-detail {
        // width: 100%;
        flex: 1;
        padding: 0;
        justify-content: center;
        margin-left: 10px;
        align-items: flex-start;
        display: flex;
        .wt-input{
          width: 100%;
          line-height: normal!important
        }
    }
}
}
</style>
