<template>
<div>
  <pt-hostadd class="pt-hostadd__page"></pt-hostadd>
</div>
</template>

<script>
import PtHostAdd from 'cps/pt-host/add'
export default {
  name: 'pt-hostadd__view',
  components: {
    'pt-hostadd': PtHostAdd
  }
}
</script>

