import request from "./request";
import router from "@/router";
import env from "../api";

export function getUserMessage(login) {
    return request(`/api/users/`, "GET", login);
}
// 创建患者信息
export function createPatients(data) {
    return request(`/services/hpd/api/patients`, "POST", data);
}
// 获取患者列表
export function getPatientsList(data) {
    return request(`/services/hpd/api/patients/by-example`, "POST", data);
}
// 获取患者详情
export function getPatientsDetail(data) {
    return request(`/services/hpd/api/internal/patients/details/${data}`, "GET");
}
// 完成登记
export function finishRegister(data) {
    return request(
        `/services/examination/api/orders/create/Hrv_OrderCreateWorkflow`,
        "POST",
        data
    );
}
//获取设备数量模拟接口
export function getDeviceCont() {
    return request(`/api/device/license-context`, "GET");
}
/**
 * oss直传获取签名
 * @param {*} data
 */
export function ossUploadBigFile(data) {
    return request(`/services/installbase/api/upload-policy`, 'POST', data)
}
import useFormData  from './request/plugins/useFormData'
/**
 * 根据签名上传文件(useFormData)
 * @param {*} data
 */
export function getOssSignature(url, data) {
    return useFormData(request)(url, 'multipleFILE', data)
}
// 获取用户菜单
export function getMenuList() {
    return request(`/api/user/menus`, "GET");
}
//获取角色列表
export function getManagerGroups(){
	return request(`/api/user-manager/groups`,'GET')
}
//登录接口
export function getLogin (data) {
  return request(`/auth/login`, 'POST', data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } })
}
//修改当前用户的密码
export function editCurrentPassword (data) {
  return request(`/services/hpd/api/doctors/change-password/by-current`, 'PUT', data)
}
//获取科室信息
export function getDepartmentsByConfigured () {
    return request(`/services/hpd/api/departments/configured`, 'GET')
}
//添加科室
export function addDepartments (data) {
    return request(`/services/hpd/api/departments/configured`, 'POST', data)
}
//删除科室
export function deleteDepartment (id) {
    return request(`/services/hpd/api/departments/${id}`, 'DELETE')
}
export function renewAuthorizeInfosRemark (data) {
    return request(`/services/installbase/api/device-authorize-infos-remark`,'PUT',data)
}
//上位机版本更新记录
export function getDeviceVersions (data) {
    return request(`/services/installbase/api/device-software-versions/page-by-query`,'POST',data)
}
//获取终端版本更新记录列表
export function getTerminalsVersions (data) {
    return request(`/services/installbase/api/device-hardware-versions/page-by-query`,'POST',data)
}
// 终端版本添加
export function addHardwareHost(data) {
    return request(`/services/installbase/api/device-hardware-versions`, "POST", data);
}
//上位机版本添加
export function addDeviceVersions (data) {
    return request(`/services/installbase/api/device-software-versions`,'POST',data)
}
//获取上位机版本（所有）
export function getDeviceSoftwareVersionList () {
    return request(`/services/installbase/api/device-base-infos/software-version-list`,'POST')
}