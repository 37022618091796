<template>
    <el-drawer
    title="高级检索"
    :visible.sync="drawer"
    direction="rtl"
    class="el-drawer-searchSrawer"
    :before-close="handleClose" :size='500'>
     <el-divider></el-divider>
        <el-form v-model="form" class="ss-form-class">
            <el-row :gutter='20'>
							<template v-if="isHostPage">
                <el-col :span='12'>
                    <el-form-item label='机构类型'>
                        <el-select v-model="form.type" placeholder="请选择">
                            <el-option
                                v-for="item in typeList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='所属地区'>
                        <addressSelect :showElement='{ province: true,city: false,district: false,postAddress: false}' :key='addressSelectKey' @change='regionChange'/>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span='12'>
                     <el-form-item label='机构等级'>
                        <el-select v-model="form.rank" placeholder="请选择">
                            <el-option
                                v-for="item in rankList"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                    <el-form-item label='设备型号'>
                        <el-select v-model="form.model" placeholder="请选择" :disabled='true'>
                            
                        </el-select>
                    </el-form-item>
                </el-col> -->
                <el-col :span='12'>
                     <!-- <el-form-item label='硬件版本'>
                        <el-select v-model="form.hardwareVersion" placeholder="请选择">
                            <el-option
                                v-for="(item, index) in hardwareList"
                                :key="index"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                </el-col>
                <el-col :span='12'>
                     <el-form-item label='软件版本'>
                        <el-select v-model="form.softwareVersion" placeholder="请选择">
                            <el-option
                                v-for="(item, index) in softwareList"
                                :key="index"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
							</template>
                <el-col :span='12' v-if="isAuthPage">
                     <el-form-item label='授权类型'>
                        <el-select v-model="form.authorizationType" placeholder="请选择">
                            <el-option
                                v-for="item in authorizationTypeList"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span='12' v-if="isHostPage">
                     <el-form-item label='运行状态'>
                        <el-select v-model="form.status" placeholder="请选择">
                            <el-option label="正常" :value="0"></el-option>
                            <el-option label="异常" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
								<template  v-if="isAuthPage">
                <el-col :span='12'>
                     <el-form-item label='终端数量'>
                        <el-select v-model="form.terminalCount" placeholder="请选择">
                            <el-option
                                v-for="item in terminalCountList"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span='12'>
                     <el-form-item label='授权型号'>
                        <el-select v-model="form.functionConfig.model" placeholder="请选择">
                            <el-option
                                v-for="(item, index) in configsList"
                                :key="index"
                                :label="item.model"
                                :value="item.model">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                     <el-form-item label='状态'>
                        <el-select v-model="form.status" placeholder="请选择">
                            <el-option
                                v-for="(item, index) in statusList"
                                :key="index"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span='24'>
                     <el-form-item label='创建授权'>
                        <el-date-picker
                            v-model="time1"
                            type="daterange"
                            value-format='yyyy-MM-dd'
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span='24'>
                     <el-form-item label='授权生效'>
                        <el-date-picker
                            v-model="time2"
                            type="daterange"
                             value-format='yyyy-MM-dd'
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span='24'>
                     <el-form-item label='授权失效'>
                        <el-date-picker
                            v-model="time3"
                            type="daterange"
                             value-format='yyyy-MM-dd'
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
							</template>
            </el-row>
            
        </el-form>
        <el-divider></el-divider>
        <div style="display:flex;align-items:center;justify-content:center;padding-bottom: 10px;">
            <div class="clear-btn-class" @click="clear">清除筛选条件</div>
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="search">检索</el-button>
        </div>
    </el-drawer>
</template>
<script>
import addressSelect from '../base/pt-address-select/withData'
import { hardwareHost, softwareHost } from '../../utils/hostServices'
import { configsHost } from '../../utils/hostServices'

export default {
     components:{
        addressSelect
    },
		props:{
			selectTab:String
		},
		computed:{
			isHostPage(){
				return this.selectTab==='host'
			},
			isAuthPage(){
				return this.selectTab==='auth'
			}
		},
    data(){
        return {
             addressSelectKey: 1,
            drawer: false,
            form: {
                functionConfig:{}
                // model: 'CHRV-9'
            },
            typeList:[
                {
                    id: 'HOSPITAL',
                    label: '医疗机构'
                },
                {
                    id: 'ORGANIZATION',
                    label: '非医疗机构'
                }
            ],
            rankList: [
              {
                  id: 'FIRST_A',
                  title: '一级甲等'
              },
              {
                  id: 'FIRST_B',
                  title: '一级乙等'
              },
              {
                  id: 'FIRST_C',
                  title: '一级丙等'
              },
              {
                  id: 'SECOND_A',
                  title: '二级甲等'
              },
              {
                  id: 'SECOND_B',
                  title: '二级乙等'
              },
              {
                  id: 'SECOND_C',
                  title: '二级丙等'
              },
              {
                  id: 'THIRD_A',
                  title: '三级甲等'
              },
              {
                  id: 'THIRD_B',
                  title: '三级乙等'
              },
              {
                  id: 'THIRD_C',
                  title: '三级丙等'
              },
              {
                  id: 'OTHER',
                  title: '其他'
              }
          ],
          hardwareList: [],
          softwareList: [],
            authorizationTypeList: [
                {
                    id: 'EXAMINATION_TIMES',
                    title: '按检查次数'
                },
                {
                    id: 'PERIOD_OF_VALIDITY',
                    title: '按有效期限'
                },
                {
                    id: 'UNLIMITED',
                    title: '无限制使用授权'
                }
            ],
            terminalCountList:[],
            configsList:[],
            time1: [],
            time2: [],
            time3: [],
            statusList: [
                {
                    value: 0,
                    label:"未生效"
                },
                {
                    value: 1,
                    label:"有效"
                },
                {
                    value: 2,
                    label:"已完成"
                }
            ]
        }
    },
    methods:{
        show(){
            this.drawer = true
            this.getData()
            this.terminalCountList = []
            for (let i = 1; i < 6; i++) {
                this.terminalCountList.push(i)
            }
        },
        handleClose(){
            this.drawer = false 
            this.clear()
        },
        search(){
            if (this.time1.length === 2){
                this.form.createDateStart = this.time1[0]
                this.form.createDateEnd = this.time1[1]
            }
            if (this.time2.length === 2){
                this.form.effectiveDateStart = this.time2[0]
                this.form.effectiveDateEnd = this.time2[1]
            }
            if (this.time3.length === 2){
                this.form.invalidDateStart = this.time3[0]
                this.form.invalidDateEnd = this.time3[1]
            }
            this.$emit('search', this.form)
            this.handleClose()
        },
        clear(){
            this.form = {functionConfig:{}}
            this.time1 = []
            this.time2 = []
            this.time3 = []
            this.addressSelectKey += 1
        },
        regionChange(val){
					if(val.provinceGbCode){
            this.form.region = {}
            this.form.region.provinceGbCode = 0
            this.form.region.cityGbCode = 0
            this.form.region.gbCode = val.provinceGbCode
            this.form.region.province = val.provinceName
					}
        },
        getData () {
            this.$store.dispatch('getDeviceSoftwareVersionList').then(res => {
                this.softwareList=res
            })
            configsHost({example:{},pageable:{page: 0,size: 100000}}).then((res)=>{
                this.configsList = res.data
            })
        }
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.el-drawer-searchSrawer{
    .el-drawer__body{
        overflow-x: hidden;
    }
}
.el-drawer__wrapper{
	position: absolute;
}
.clear-btn-class{
    color: @mainColor;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 20px;
    margin-right: 20px;
}
.ss-form-class{
    margin-left: 20px;
    & .el-form-item__label{
        text-align:left;
        float: none;
        word-break: break-word;
    }
    & .el-form-item{
        text-align:left;
        margin-bottom: 10px;
    }
    & .el-form-item__content{
        width: 180px !important;
        line-height: 0 !important;
    }
    .wt-address-select .province-district .province-city .el-select{
        width: 193px;
    }
    .wt-address-select .province-district .province-city{
        width: 193px;
    }
}

</style>