// 分页获取数据时，取出分页的total-number
export function getTotalNumber (response) {
  let { data, headers } = response
  let totalNumber = typeof headers['x-total-count'] === 'string' ? Number(headers['x-total-count']) : headers['x-total-count']
  return totalNumber
}
export function downloadFilename (response) {
  let { data, headers } = response
  let fileName = decodeURIComponent(
    headers['content-disposition'].split(';')[1].split('filename=')[1]
  )
  return { data, fileName }
}
