<template>
    <div class="pt-institutions__withData">
        <pageheader title="客户机构管理">
            <div class="flex-class">
                <el-button type="primary" icon='el-icon-plus' @click="add">新增机构</el-button>
                <el-button class="senior-search-class" type="primary"
                    @click="seniorSearch">高级检索</el-button>
                <div class="input-content-class">
                    <el-input class="input-class" placeholder='请输入关键字' v-model="form.keyword"
                        :key='keywordKey' :clearable="true" @clear="searchClear"></el-input>
                </div>
                <el-button class="search-class" type="primary" @click="search">搜索</el-button>
            </div>
        </pageheader>
        <div class="pt-institutions-table-div">
            <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="tableData">
                <el-table-column prop="name" label="机构名称"></el-table-column>
                <el-table-column prop="rank" label="等级">
                    <template slot-scope="scope">
                        <span>{{getRankValue(scope.row.rank)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="departmentStr" label="科室">
                    <template slot-scope="scope">
                        {{ scope.row.departmentStr }}
                        <!-- <span>{{getDepartmentValue(scope.row.departmentStr)}}</span> -->
                    </template>
                </el-table-column>
                <el-table-column prop="region" label="地区">
                    <template slot-scope="scope">
                        <span>{{scope.row.region.city}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="地址"> </el-table-column>
                <el-table-column prop="contacts" label="联系人"> </el-table-column>
                <el-table-column prop="createName" label="创建人"> </el-table-column>
                <el-table-column prop="contactsPhone" label="手机号"> </el-table-column>
                <el-table-column prop="" label="操作">
                    <template slot-scope="scope">
                        <div class="flex-class">
                            <div class="edit-btn-class" @click="detail(scope.row)">详情</div>
                            <el-divider direction="vertical"></el-divider>
                            <div class="edit-btn-class" @click="edit(scope.row)">编辑</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <ptPagination ref="ptPagination" @paginationchange='paginationchange'></ptPagination>
        <searchSrawer ref="searchSrawer" @search='searchForm' />
    </div>
</template>

<script>
import pageheader from '../base/pt-page-header/index'
import { getPage } from '../../utils/institutionsService'
import { formatDateObject } from '../../utils/formatUtils/date'
import ptPagination from '../base/pt-pagination/index'
import searchSrawer from './searchSrawer'
export default {
    name: 'pt-institutions__withData',
    components: {
        pageheader,
        ptPagination,
        searchSrawer
    },
    data () {
        return {
            form: {
                pageable: {
                    page: 0,
                    size: 10
                }
            },
            keywordKey: 1,
            total: 0,
            rankList: [
                {
                    id: 'FIRST_A',
                    title: '一级甲等'
                },
                {
                    id: 'FIRST_B',
                    title: '一级乙等'
                },
                {
                    id: 'FIRST_C',
                    title: '一级丙等'
                },
                {
                    id: 'SECOND_A',
                    title: '二级甲等'
                },
                {
                    id: 'SECOND_B',
                    title: '二级乙等'
                },
                {
                    id: 'SECOND_C',
                    title: '二级丙等'
                },
                {
                    id: 'THIRD_A',
                    title: '三级甲等'
                },
                {
                    id: 'THIRD_B',
                    title: '三级乙等'
                },
                {
                    id: 'THIRD_C',
                    title: '三级丙等'
                },
                {
                    id: 'OTHER',
                    title: '其他'
                }
            ],
            departmentList: [
                {
                    id: 'CLINIC',
                    label: '门诊科'
                },
                {
                    id: 'PSYCHIATRY',
                    label: '精神科'
                },
                {
                    id: 'PSYCHOLOGY',
                    label: '心理科'
                },
                {
                    id: 'NEUROLOGY',
                    label: '神经内科'
                },
                {
                    id: 'PAIN_TREATMENT',
                    label: '疼痛科'
                },
                {
                    id: 'ENDOCRINOLOGY',
                    label: '内分泌科'
                },
                {
                    id: 'COMMUNITY',
                    label: '社区医院'
                },
                {
                    id: 'PHYSICAL',
                    label: '体检中心'
                }
            ],
            tableData: []
        }
    },
    created () {

    },
    methods: {
        searchClear () {
            this.form.pageable.page = 0
            this.form.keyword = null
            this.getData()
        },
        getRankValue (rank) {
            for (let index in this.rankList) {
                let item = this.rankList[index]
                if (item.id === rank) {
                    return item.title
                }
            }
            return ''
        },
        getDepartmentValue (departmentEnum) {
            for (let index in this.departmentList) {
                let item = this.departmentList[index]
                if (item.id === departmentEnum) {
                    return item.label
                }
            }
            return ''
        },
        getData () {
            getPage(this.form).then((res) => {
                this.tableData = res.data
                this.$refs.ptPagination.totalMethod(res)
            })
        },
        formatDateObject (val) {
            return formatDateObject(val)
        },
        paginationchange (pageable) {
            this.form.pageable = pageable
            this.getData()
        },
        add () {
            this.$router.push('/institutionsedit?type=add')
        },
        seniorSearch () {
            this.$refs.searchSrawer.show()
        },
        search () {
            this.form.pageable.page = 0
            this.getData()
        },
        searchForm (form) {
            let formValue = JSON.parse(JSON.stringify(form))
            formValue.keyword = this.form.keyword
            this.form = { ...formValue, pageable: this.form.pageable }
            this.getData()
        },
        detail (row) {
            this.$router.push('/institutionsdetail?id=' + row.id)

        },
        edit (row) {
            this.$router.push('/institutionsedit?type=edit&id=' + row.id)
        }
    }

}
</script>
<style lang="less">
@import "~less/var.less";
.pt-institutions__withData{
  max-width: 100%;
  padding: 0 20px 20px 20px;
	height: 100%;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	padding-bottom: 20px;
	// position: relative;
	.pt-institutions-table-div{
		display: flex;
		flex: 1;
		overflow-y: hidden;
	}
	.el-table{
		height: 100%;
		overflow: auto;
		padding-bottom: 20Px;
	}
  .flex-class{
      display: flex;
      width: 100%;
      height: 38PX;
			align-items: center;
  }
  .el-button--primary{
      background-color: @mainColor;
  }
  .input-content-class{
      width: 200px;
      margin-right: 20px;
  }
  .del-btn-class{
    cursor: pointer;
    color: @warningColor;
  }
  .edit-btn-class{
    cursor: pointer;
    color: @mainColor;
  }
  .search-class{
    margin-right: 0;
  }
  .senior-search-class{
    margin-right: 20px;
    margin-left: auto !important;
  }
}
</style>