<template>
    <el-dialog class="is-dialog-class" :visible.sync="dialogVisible" :show-close='false'
        width="90%">
        <template slot="title">
            <pageheader title="选择客户机构">
                <div class="flex-class">
                    <div class="input-content-class">
                        <el-input class="input-class" placeholder='请输入关键字' v-model="form.keyword"
                            :clearable="true"></el-input>
                    </div>
                    <el-button type="primary" class="search-button" @click="search">搜索</el-button>
                </div>
            </pageheader>
        </template>
        <el-row>
            <el-col :span='18'>
                <el-form :model="form" :inline="true" label-width="73px">
                    <el-row>
                        <el-col :span='8'>
                            <el-form-item label="机构类型:">
                                <el-select v-model="form.type" placeholder="请选择">
                                    <el-option key="HOSPITAL" label="医疗机构"
                                        value="HOSPITAL"></el-option>
                                    <el-option key="ORGANIZATION" label="非医疗机构"
                                        value="ORGANIZATION"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span='8'>
                            <el-form-item label="所属地区:">
                                <addressSelect
                                    :showElement='{ province: true,city: false,district: false,postAddress: false}'
                                    :key='addressSelectKey' @change='regionChange' />
                            </el-form-item>
                        </el-col>
                        <el-col :span='8'>
                            <el-form-item label="机构等级:">
                                <el-select v-model="form.rank" placeholder="请选择">
                                    <el-option v-for="item in rankList" :key="item.id"
                                        :label="item.title" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
            <el-col :span='6'>
                <div class="flex-class">
                    <el-button type="primary" @click="search">搜索</el-button>
                    <el-button @click="clear">清除筛选条件</el-button>
                </div>
            </el-col>
        </el-row>
        <el-table ref='tableref' highlight-current-row style="width: 100%;margin-top:20px;"
            :stripe="true" :data="tableData" @row-click="handleRowClick">
            <el-table-column prop="name" label="机构名称"></el-table-column>
            <el-table-column prop="departmentStr" label="科室"></el-table-column>
            <el-table-column prop="rank" label="等级">
                <template slot-scope="scope">
                    <span>{{getRankValue(scope.row.rank)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="region" label="地区">
                <template slot-scope="scope">
                    <span>{{scope.row.region.city}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="uniformCode" label="企业统一社会信用代码" width='170px'> </el-table-column>
            <el-table-column prop="contacts" label="联系人"> </el-table-column>
            <el-table-column prop="contactsPhone" label="手机号"> </el-table-column>
        </el-table>
        <ptPagination ref="ptPagination" @paginationchange='paginationchange'></ptPagination>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确认选择</el-button>
        </span>
    </el-dialog>
</template>
<script>
import pageheader from '../base/pt-page-header/index'
import ptPagination from '../base/pt-pagination/index'
import { getPage } from '../../utils/institutionsService'
import addressSelect from '../base/pt-address-select/withData'
import { departmentEnumList } from "../../utils/total-message"
export default {
    components: {
        pageheader,
        ptPagination,
        addressSelect
    },
    props: {
        selectList: {
            type: Array,
            default () {
                return []
            }
        }
    },
    model: {
        prop: 'selectList',
        event: 'selectList'
    },
    data () {
        return {
            dialogVisible: false,
            addressSelectKey: 1,
            form: {
            },
            maxSel: 1,
            rankList: [
                {
                    id: 'FIRST_A',
                    title: '一级甲等'
                },
                {
                    id: 'FIRST_B',
                    title: '一级乙等'
                },
                {
                    id: 'FIRST_C',
                    title: '一级丙等'
                },
                {
                    id: 'SECOND_A',
                    title: '二级甲等'
                },
                {
                    id: 'SECOND_B',
                    title: '二级乙等'
                },
                {
                    id: 'SECOND_C',
                    title: '二级丙等'
                },
                {
                    id: 'THIRD_A',
                    title: '三级甲等'
                },
                {
                    id: 'THIRD_B',
                    title: '三级乙等'
                },
                {
                    id: 'THIRD_C',
                    title: '三级丙等'
                },
                {
                    id: 'OTHER',
                    title: '其他'
                }
            ],
            tableData: []
        }
    },
    methods: {
        regionChange (val) {
            if (val.provinceGbCode) {
                this.form.region = {}
                this.form.region.provinceGbCode = 0
                this.form.region.cityGbCode = 0
                this.form.region.gbCode = val.provinceGbCode
                this.form.region.province = val.provinceName
            } else {
                if (this.form.region) {
                    this.$delete(this.form, 'region')
                }
            }

        },
        getRankValue (rank) {
            for (let index in this.rankList) {
                let item = this.rankList[index]
                if (item.id === rank) {
                    return item.title
                }
            }
            return ''
        },
        handleRowClick (val) {
            this.$refs.tableref.setCurrentRow()
            let isFind = false
            for (let index in this.selectList) {
                let item = this.selectList[index]
                if (item.id === val.id) {
                    isFind = true
                    this.selectList = []
                }
            }
            if (isFind === false) {
                this.selectList = [val]
                this.$refs.tableref.setCurrentRow(val)
            }
            this.$emit('selectList', this.selectList)
        },
        getData () {
            getPage(this.form).then((res) => {
                this.tableData = res.data
                this.tableData.forEach(element => {
                    departmentEnumList.forEach(item => {
                        if (element.departmentEnum === item.id) {
                            element.departmentName = item.label
                        }
                    });
                });
                this.$refs.ptPagination.totalMethod(res)
            })
        },
        paginationchange (pageable) {
            this.form.pageable = pageable
            this.getData()
        },
        show () {
            this.dialogVisible = true
        },
        clear () {
            this.addressSelectKey += 1
            this.$delete(this.form, 'keyword')
            this.$delete(this.form, 'rank')
            this.$delete(this.form, 'region')
            this.$delete(this.form, 'type')
            this.getData()
        },
        search () {
            this.getData()
        }
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.is-dialog-class{
    .el-dialog__header{
        text-align: left;
    }
    .el-form--inline .el-form-item__content{
        width: 150px;
    }
    .el-table th.el-table__cell.is-leaf{
        border-bottom:0px solid #F1F1F1;
    }
    .el-table__body tr.current-row>td{
        background-color: @mainColor !important;
        color: #fff;
    }
    .flex-class{
        display: flex;
        .input-content-class{
            width: 200px;
        }
				.search-button{
					margin-left: 10px;
				}
    }
    .wt-address-select .province-district .province-city .el-select{
        width: 100%;
    }
    .wt-address-select .province-district .province-city{
         width: 100%;
    }
}
</style>