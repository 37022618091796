import Vue from 'vue'
import 'wt-ui/lib/less/common.less'
import { View, Avatar, Grid, Box, Row, Text, Col} from 'wt-ui'
import {
  MenuTree, Selector, Radio, Tab, ReRequestTip, Table, PagedTable, Input, FormItem, UploadFile,
  RadioSlot, Checkbox
} from 'wt-ui/lib/pc'

/*wt-ui(pc)*/
Vue.use(MenuTree)
Vue.use(Tab)
Vue.use(Selector)
Vue.use(Radio)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(UploadFile)
Vue.use(RadioSlot)
Vue.use(Checkbox)
/*wt-ui(commen)*/
Vue.use(View)
Vue.use(Avatar)
Vue.use(Grid)
Vue.use(Box)
Vue.use(Col)
Vue.use(Text)
Vue.use(Row)
Vue.use(Table)
Vue.use(ReRequestTip)
Vue.use(PagedTable)

