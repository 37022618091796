<template>
  <el-card class="pt-abstract-class">
    <div class="sub-title-class">上传数据</div>
    <el-divider></el-divider>
    <el-form ref="ruleForm" label-position='left' :model="form"  size="medium" :rules="rules">
      <el-form-item label="选择数据文件:" prop='filePath'>
				<el-input  v-model="form.filePath" v-show="false"></el-input>
        <uploadBigFile ref="uploadBigFile" resourceConfig="releases" v-model="form.filePath" data-vv-as="上传数据文件" v-validate="'required'" @isUpload="isUpload" :isPathUrlAddTime="true" @input='fileInput'></uploadBigFile>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <div class="flex-class fotter-class">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')">确认提交</el-button>
    </div>
  </el-card>
</template>
<script>
import { addCaseHost } from '../../../utils/hostServices'
import { formatDateObject } from '../../../utils/formatUtils/date'
import uploadBigFile from '../../base/pt-upload-big-file'
import { Loading } from 'element-ui'
export default {
    components: {
        uploadBigFile
    },
    props:{
        item: {
            type: Object,
            default(){
                return {}
            }
        }
    },
    data(){
        return{
            dataList: [],
            modelList: [],
            form: {
							filePath:null
						},
            loadingInstance: null,
						rules:{
							filePath:[
							{ required: true, message: '请选择数据文件',  trigger: 'change'}
						]
						}
        }
    },
    mounted(){
        
    },
    methods:{
        cancel(){
            this.$emit('click', 0)
        },
        submitForm(formName){
            this.form.deviceBaseInfoId = this.item.deviceBaseInfoId ? this.item.deviceBaseInfoId : this.item.id
						this.$refs[formName].validate((valid) => {
							if(valid){
             this.loadingInstance = Loading.service({text: '请稍等'})
								addCaseHost(this.form).then((res)=>{
										this.$refs.uploadBigFile.clearFiles()
										this.$emit('click', 1)
										this.$nextTick(() => {
												this.loadingInstance.close();
												this.form.filePath=[]
										});
								})
							}else{
								return false
							}
						})
        },
        formatDateObject(val){
            return formatDateObject(val)
        },
        fileInput(val){
            this.form.filePath = val.url
            this.form.fileName = val.name
        },
		},
		beforeDestroy(){
		}
}
</script>
<style lang="less">
@import "~less/var.less";
.pt-abstract-class{
    height: 100%;
		.el-upload{
			width: 100%!important;
		}
    .sub-title-class{
        text-align: left;
        font-size: 14px;
        font-weight: 600;
    }
    .fotter-class{
        margin-top: 40px;
    }
}
</style>