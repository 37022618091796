import * as fetch from '../utils/action'
export default {
	getManagerGroups(){
		return fetch.getManagerGroups().then(res=>{
			return res.data
		})
	},
	getLogin(ctx,data){
		return fetch.getLogin(data).then(res=>{
			return res.data
		})
	},
	editCurrentPassword(ctx,data){
		return fetch.editCurrentPassword(data).then(res=>{
			return res.data
		})
    },
    getDepartmentsByConfigured (ctx) {
        return fetch.getDepartmentsByConfigured().then(res => {
            return res.data
        })
    },
    addDepartments (ctx, data) {
        return fetch.addDepartments(data).then(res => {
            return res.data
        })
    },
    deleteDepartment (ctx, id) {
        return fetch.deleteDepartment(id).then(res => {
            return res.data
        })
    },
    renewAuthorizeInfosRemark (ctx, data) {
        return fetch.renewAuthorizeInfosRemark(data).then(res => {
            return res.data
        })
    },
    getDeviceVersions (ctx, data) {
        return fetch.getDeviceVersions(data).then(res => {
            return res.data
        })
    },
    getTerminalsVersions (ctx, data) {
        return fetch.getTerminalsVersions(data).then(res => {
            return res.data
        })
    },
    addHardwareHost (ctx, data) {
        return fetch.addHardwareHost(data).then(res => {
            return res.data
        })
    },
    addDeviceVersions (ctx, data) {
        return fetch.addDeviceVersions(data).then(res => {
            return res.data
        })
    },
    getDeviceSoftwareVersionList (ctx) {
        return fetch.getDeviceSoftwareVersionList().then(res => {
            return res.data
        })
    }
}