<template>
    <el-card class="pt-abstract-class">
        <describe :item='item' />
        <el-divider></el-divider>
        <div class="flex-class tm-top-content-class">
            <div class="sub-title-class">终端管理</div>
            <el-button class="bind-btn-class" type="primary" icon='el-icon-plus'
                @click="binding">绑定终端硬件</el-button>
            <div class="sub-desc-class">终端上限:</div>
            <div class="tm-count-class">{{item.terminalMaxCount ? item.terminalMaxCount : 0}}</div>
            <div class="sub-desc2-class"> / 已绑定终端:</div>
            <div class="tm-count-class">{{item.terminalCount ? item.terminalCount : 0}}</div>
        </div>
        <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="dataList">
            <el-table-column type='index'></el-table-column>
            <el-table-column prop="sn" min-width="120" label="终端硬件序列号"></el-table-column>
            <el-table-column prop="hardwareVersion" label="硬件版本"></el-table-column>
            <el-table-column prop="softwareVersion" label="软件版本"></el-table-column>
            <el-table-column prop="createdDate" label="绑定时间">
                <template slot-scope="scope">
                    <span>{{formatDateObject(scope.row.createdDate)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="createUserName" label="用户"> </el-table-column>
            <el-table-column prop="" label="操作">
                <template slot-scope="scope">
                    <div class="del-btn-class" @click="unbinding(scope.row)">解绑</div>
                </template>
            </el-table-column>
        </el-table>
        <ptPagination ref="ptPagination" @paginationchange='paginationchange'></ptPagination>
        <inputDialog ref="inputDialog" title='绑定终端硬件' inputTitle='终端硬件序列号'
            @submit='bindingSubmit' />
    </el-card>
</template>
<script>
import describe from './describe'
import ptPagination from '../../base/pt-pagination/index'
import { deviceTerminalsPage, bindingTerminals, unbindingTerminals } from '../../../utils/hostServices'
import { formatDateObject } from '../../../utils/formatUtils/date'
import inputDialog from '../../base/pt-input-dialog/input-dialog'
import { Loading } from 'element-ui'
export default {
    components: {
        describe,
        ptPagination,
        inputDialog
    },
    props: {
        item: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    data () {
        return {
            loadingInstance: null,
            form: {},
            dataList: []
        }
    },
    mounted () {

    },
    methods: {
        binding () {
            this.$refs.inputDialog.show()
        },
        bindingSubmit (inputValue) {
            this.loadingInstance = Loading.service({ text: '请稍等' })
            bindingTerminals({ deviceBaseInfoId: this.item.deviceBaseInfoId ? this.item.deviceBaseInfoId : this.item.id, sn: inputValue }).then((res) => {
                this.$refs.inputDialog.hidden()
                this.$nextTick(() => {
                    this.loadingInstance.close();
                });
                this.paginationchange({ page: 0, size: 10 })
                this.$emit('upload')
            }).catch(err => {
                this.$nextTick(() => {
                    this.loadingInstance.close();
                });
                this.$message.error(err || '绑定失败')
            })
        },
        unbinding (row) {
            this.$confirm('确认解绑该设备?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loadingInstance = Loading.service({ text: '请稍等' })
                unbindingTerminals(row.id).then((res) => {
                    this.$message({
                        message: '解绑成功',
                        type: 'success'
                    });
                    this.$nextTick(() => {
                        this.loadingInstance.close();
                    });
                    this.paginationchange({ page: 0, size: 10 })
                    this.$emit('upload')
                }).catch(err => {
                    this.$nextTick(() => {
                        this.loadingInstance.close();
                    });
                    this.$message.error(err || '解绑失败')
                })
            })
        },
        paginationchange (pageable) {
            this.form.pageable = pageable
            this.getData()
        },
        getData () {
            this.form.example = { deviceBaseInfoId: this.item.deviceBaseInfoId ? this.item.deviceBaseInfoId : this.item.id }
            deviceTerminalsPage(this.form).then((res) => {
                this.dataList = res.data
                this.$refs.ptPagination.totalMethod(res)
            })
        },
        formatDateObject (val) {
            return formatDateObject(val)
        }
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.pt-abstract-class{
    height: 100%;
    .sub-title-class{
        text-align: left;
        font-size: 14px;
        font-weight: 600;
    }
    .del-btn-class{
        cursor: pointer;
        color: @warningColor;
    }
    .tm-top-content-class{
        align-items: center;
    }
    .bind-btn-class{
        margin-left: 20px;
    }
    .sub-desc-class{
        margin-left: 20px;
        font-size: 14px;
    }
    .sub-desc2-class{
        font-size: 14px;
    }
    .tm-count-class{
        color: @mainColor;
        font-size: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
}
</style>