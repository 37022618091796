<template>
    <div class="pt-host__withData">
        <pageheader title="客户主机管理">
            <div class="flex-class top-content-class">
                <el-button type="primary" icon='el-icon-plus' @click="add">新增主机</el-button>
                <tab class="host-tab-class" :dataList='tabList' @change='tabChange' />
                <el-button class="senior-search-class" type="primary"
                    @click="seniorSearch">高级检索</el-button>
                <div class="input-content-class">
                    <el-input class="input-class" placeholder='请输入关键字' v-model="keyword"
                        :clearable="true" @clear="searchClear"></el-input>
                </div>
                <el-button class="search-class" type="primary" @click="search">搜索</el-button>
            </div>
        </pageheader>
        <div v-if="isAuthPage" class="table-div">
            <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="tableData2"
                @sort-change="sortChange2" v-loading="loading2">
                <el-table-column prop="organizationName" label="客户名称">
                    <template slot-scope="scope">
                        {{ scope.row.organizationName }}
                    </template>
                </el-table-column>
                <el-table-column prop="city" label="地区">
                    <template slot-scope="scope">{{ scope.row.city }}</template>
                </el-table-column>
                <el-table-column prop="terminalCount" label="终端数量">
                    <template slot-scope="scope">{{ scope.row.terminalCount }}</template>
                </el-table-column>
                <el-table-column prop="deviceSn" label="主机序列号" width='120'> </el-table-column>
                <el-table-column prop="authorizationType" label="授权类型" width='120'>
                    <template slot-scope="scope">
                        <span>{{getTypeValue(scope.row.authorizationType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="effectiveDate" label="授权生效" width='100' sortable="custom">
                    <template slot-scope="scope">
                        <span>{{formatDateObject(scope.row.effectiveDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="invalidDate" label="授权失效" width='100' sortable="custom">
                    <template slot-scope="scope">
                        <span>{{getInvalidDateValue(scope.row)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="functionConfig" label="授权型号">
                    <template slot-scope="scope">
                        <span>{{scope.row.functionConfig.model}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createdDate" label="创建日期" width='100' sortable="”custom">
                    <template slot-scope="scope">
                        <span>{{formatDateObject(scope.row.createdDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="surplus" label="可用次数">
                    <template slot-scope="scope">
                        <span>{{getCaseCountValue(scope.row)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">
                        <template v-if="scope.row.status == 0">未生效</template>
                        <template v-if="scope.row.status == 1">有效</template>
                        <template v-if="scope.row.status == 2">已完成</template>
                    </template>
                </el-table-column>
                <el-table-column label="授权人员" prop="authUserName"></el-table-column>
                <el-table-column prop="" label="操作">
                    <template slot-scope="scope">
                        <div class="del-btn-class" v-if="canDeleteAuthorization"
                            @click="delHost(scope.row)">删除</div>
                    </template>
                </el-table-column>
            </el-table>
            <ptPagination ref="ptPagination2" @changePage='paginationchange2'
                :page="form2.pageable.page" :page-size="form.pageable.size"></ptPagination>
        </div>
        <div v-else class="table-div">
            <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="tableData"
                @sort-change="sortChange">
                <el-table-column label="客户名称">
                    <template slot-scope="scope">
                        {{scope.row.organizationInfo&&scope.row.organizationInfo.name}}
                        <el-popover :ref="scope.row.id" placement="top-start"
                            v-if="scope.row.authorizeStatus!=='normal'" trigger="hover"
                            :content="(scope.row.organizationInfo&&scope.row.organizationInfo.name||'')+scope.row.authorizeStatusText">
                        </el-popover>
                        <i class="el-icon-warning" v-if="scope.row.authorizeStatus!=='normal'"
                            :style="{color:authorizeStatusColor(scope.row.authorizeStatus)}"
                            v-popover="scope.row.id"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="类型">
                    <template slot-scope="scope">
                        <span
                            v-if="scope.row.organizationInfo && scope.row.organizationInfo.type === 'HOSPITAL'">医疗机构</span>
                        <span
                            v-if="scope.row.organizationInfo && scope.row.organizationInfo.type !== 'HOSPITAL'">非医疗机构</span>
                    </template>
                </el-table-column>
                <el-table-column prop="city" label="地区">
                    <template slot-scope="scope">
                        <span
                            v-if="scope.row.organizationInfo && scope.row.organizationInfo.region">{{scope.row.organizationInfo.region.city}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="sn" label="主机序列号" width='200'> </el-table-column>
                <el-table-column label="终端序列号" width="215">
                    <template slot-scope="scope">
                        <template v-if="scope.row.terminalList&&scope.row.terminalList.length">
                            <div class="terminal-list-td">
                                <div v-for="(item,index) in scope.row.terminalList" :key="index">
                                    {{item}}
                                </div>
                            </div>
                        </template>
                        <div v-else>--</div>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="硬件版本" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.lastHardwareVersion||scope.row.initHardwareVersion}}
                    </template>
                </el-table-column> -->
                <el-table-column label="软件版本" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.lastSoftwareVersion||scope.row.initSoftwareVersion}}
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="运行状态" sortable="custom">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 0">正常</span>
                        <span v-else style="color:red">异常</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createdDate" label="运行时间" width='150'>
                    <template slot-scope="scope">
                        <span>{{formatDateObject2(scope.row.lastModifiedDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="操作">
                    <template slot-scope="scope">
                        <div class="edit-btn-class" @click="detail(scope.row)">详情</div>
                    </template>
                </el-table-column>
            </el-table>
            <ptPagination ref="ptPagination" @changePage='paginationchange'
                :page="form.pageable.page" :page-size="form.pageable.size"></ptPagination>
        </div>
        <searchSrawer ref="searchSrawer" @search='searchForm' :selectTab="selectTab" />
    </div>
</template>

<script>
import pageheader from '../base/pt-page-header/index'
import { getPage, getPage2, delAuthorize } from '../../utils/hostServices'
import { formatDateObject } from '../../utils/formatUtils/date'
import ptPagination from '../base/pt-pagination/index'
import searchSrawer from './searchSrawer'
import tab from '../base/pt-tab/index'
import { authorizeStatusObj } from "../../utils/status"
export default {
    name: 'pt-host__withData',
    components: {
        tab,
        pageheader,
        ptPagination,
        searchSrawer
    },
    data () {
        return {
            keyword: '',
            form: {
                example: {},
                pageable: {
                    page: 0,
                    size: 10
                }
            },
            total: 0,
            form2: {
                example: {},
                pageable: {
                    page: 0,
                    size: 10
                }
            },
            total2: 0,
            tabList: [
                {
                    id: 'host',
                    title: '主机列表'
                },
                {
                    id: 'auth',
                    title: '授权列表'
                }
            ],
            selectTab: 'host',
            tableData: [],
            tableData2: [],
            loading: false,
            loading2: false
            // selectedTab:'host'
        }
    },
    computed: {
        isHostPage () {
            return this.selectTab === 'host'
        },
        isAuthPage () {
            return this.selectTab === 'auth'
        },
        //只有超级管理员可以删除授权
        canDeleteAuthorization () {
            return this.$store.getters.isSuporAdmin
        }
    },
    created () {
        this.getData()

    },
    methods: {
        filterTag (value, row) {
            return row.status === value
        },
        searchClear () {
            this.form.example.keyword = null
            this.form2.example.keyword = null
            this.form.pageable.page = 0
            this.form2.pageable.page = 0
            this.getData()
            this.getData2()
        },
        authorizeStatusColor (status) {
            let color
            switch (status) {
                case 'no_authorize':
                    color = '#606266';
                    break;
                case 'expire':
                    color = '#F56C6C';
                    break;
                case 'soon_expire':
                    color = '#E6A23C';
                    break;
                default:
                    color = '#606266';
                    break;
            }
            return color
        },
        sortChange (data) {
            this.sortData(data, this.form)
            this.getData()
        },
        sortChange2 (data) {
            this.sortData(data, this.form2)
            this.getData2()
        },
        sortData (data, form) {
            let sort
            let value
            switch (data.order) {
                case 'ascending':
                    sort = 'asc'
                    break;
                case 'descending':
                    sort = 'desc'
                    break
                default:
                    break;
            }
            switch (data.column.label) {
                case '硬件版本':
                    value = 'lastHardwareVersion'
                    break
                case '软件版本':
                    value = 'lastSoftwareVersion'
                    break
                case '运行状态':
                    value = 'status'
                    break
                case '授权生效':
                    value = 'effectiveDate'
                    break
                case '授权失效':
                    value = 'invalidDate'
                    break
                case '创建日期':
                    value = 'createdDate'
                    break
                case '状态':
                    value = 'status'
                    break
                default:
                    break
            }
            if (sort) {
                form.pageable.sort = [value + ',' + sort]
            }
        },
        tabChange (index, item) {
            this.selectTab = item.id
            this.keyword = ''
            this.form.example = {}
            this.form2.example = {}
            this.form.pageable.page = 0
            this.form2.pageable.page = 0
            this.form.pageable.size = 10
            this.form2.pageable.size = 10
            if (this.isAuthPage) {
                this.getData2()
            } else {
                this.getData()
            }
        },
        getData () {
            this.loading = true
            getPage(this.form).then((res) => {
                this.tableData = res.data
                if (this.tableData && this.tableData.length) {
                    this.tableData.forEach(item => {
                        item.authorizeStatusText = authorizeStatusObj[item.authorizeStatus]
                        item.terminalText = ''
                        if (item.terminalList && item.terminalList.length) {
                            item.terminalList.forEach((etem, index) => {
                                item.terminalText = index === 0 ? etem : (item.terminalText + '/' + etem)
                            })
                        }
                    })
                }
                this.$refs.ptPagination && this.$refs.ptPagination.totalMethod(res)
                this.loading = false
            }).catch(err => {
                this.loading = false
                this.$message.error(err || '获取主机列表失败')
            })
        },
        getData2 () {
            this.loading2 = true
            getPage2(this.form2).then((res) => {
                this.tableData2 = res.data
                this.loading2 = false
                this.$refs.ptPagination2 && this.$refs.ptPagination2.totalMethod(res)
            }).catch(err => {
                this.loading2 = false
                this.$message.error(err || '获取授权列表失败')
            })
        },
        formatDateObject (val) {
            return formatDateObject(val, 'date')
        },
        formatDateObject2 (val) {
            return formatDateObject(val, 'full')
        },
        paginationchange (pageable) {
            this.form.pageable = { ...this.form.pageable, ...pageable }
            this.getData()
        },
        paginationchange2 (pageable) {
            this.form2.pageable = { ...this.form2.pageable, ...pageable }
            this.getData2()
        },
        add () {
            this.$router.push('/hostadd?type=add')
        },
        seniorSearch () {
            this.$refs.searchSrawer.show()
        },
        search () {
            if (this.isAuthPage) {
                this.form2.example.keyword = this.keyword
                this.form2.pageable.page = 0
                this.getData2()
            } else {
                this.form.example.keyword = this.keyword
                this.form.pageable.page = 0
                this.getData()
            }
        },
        detail (row) {
            this.$router.push('/hostdetail?id=' + row.id)
        },
        delHost (row) {
            this.$confirm('确认删除该授权?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delAuthorize(row.id).then(res => {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    // this.form2.pageable = { page: 0, size: 10 }
                    this.getData2()
                }).catch(err => {
                    this.$message.error(err || '删除失败')
                })
            })
        },
        getTypeValue (type) {
            if (type === 'EXAMINATION_TIMES') {
                return '按检查次数'
            }
            if (type === 'PERIOD_OF_VALIDITY') {
                return '按有效期限'
            }
            if (type === 'UNLIMITED') {
                return '无限制使用授权'
            }
            return ''
        },
        getCaseCountValue (row) {
            if (row.authorizationType === 'PERIOD_OF_VALIDITY') {
                return '无限制'
            }
            if (row.authorizationType === 'UNLIMITED') {
                return '无限制'
            }
            return row.surplusCaseCount
        },
        getInvalidDateValue (row) {
            if (row.authorizationType === 'UNLIMITED') {
                return '永久有效'
            }
            return String(this.formatDateObject(row.invalidDate))
        },
        searchForm (form) {
            let formValue = JSON.parse(JSON.stringify(form))
            if (this.isAuthPage) {
                this.form2.example = formValue
                this.form2.example.keyword = this.keyword
                this.form2.pageable.page = 0
                this.getData2()
            } else {
                this.form.example = formValue
                this.form.example.keyword = this.keyword
                this.form.pageable.page = 0
                this.getData()
            }

        }
    }

}
</script>
<style lang="less">
@import "~less/var.less";
.el-popover{
    p{
        margin-block-start:0;
        margin-block-end:0
    }
}
.pt-host__withData{
  max-width: 100%;
  padding: 0 20px 20px 20px;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 20px;
	box-sizing: border-box;
    .el-icon-warning{
        display: flex;
        align-items: center;
        margin-left:5px
    }
    .el-table__cell{
    .el-icon-arrow-down{
        font-size: 18px;
    }
        .cell{
            display: flex!important;
            align-items: center;
        }
        .el-table__column-filter-trigger{
            display: flex;
        }
    }


    .terminal-list-td{
        .cell{
            display:block!important
        }
    }
	.table-div{
		overflow-y: hidden;
    display: flex;
    flex-direction: column;
	}
	.el-table{
		height: 100%;
		overflow: auto;
		padding-bottom: 20Px;
	}
  .flex-class{
      display: flex;
      width: 100%;
      height: 38PX;
  }
  .top-content-class{
    align-items: center;
  }
  .host-tab-class{
    margin-left: 20px;
  }

  .el-button--primary{
      background-color: @mainColor;
  }
  .input-content-class{
      width: 200px;
      margin-right: 20px;
  }
  .del-btn-class{
    cursor: pointer;
    color: @warningColor;
  }
  .edit-btn-class{
    cursor: pointer;
    color: @mainColor;
  }
  .search-class{
    margin-right: 0;
  }
  .senior-search-class{
    margin-right: 20px;
    margin-left: auto !important;
  }
}
</style>