<template>
<div>
  <pt-releaseadd class="pt-releaseadd__page"></pt-releaseadd>
</div>
</template>

<script>
import PtReleaseadd from 'cps/pt-release/add'
export default {
  name: 'pt-releaseadd__view',
  components: {
    'pt-releaseadd': PtReleaseadd
  }
}
</script>

