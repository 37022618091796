<template>
<div>
  <pt-institutionsedit class="pt-institutionsedit__page"></pt-institutionsedit>
</div>
</template>

<script>
import PtInstitutionsEdit from 'cps/pt-institutions/edit'
export default {
  name: 'pt-institutionsedit__view',
  components: {
    'pt-institutionsedit': PtInstitutionsEdit
  }
}
</script>

