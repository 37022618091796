<template>
    <div class="tab-content-class">
        <div class="tab-item-class" v-for="(item, index) in dataList" :key='index'>
            <el-divider direction="vertical" v-if="index !== 0"></el-divider>
            <div :class="selectIndex === index ? 'sel-class' : 'normal-class'" @click="clickItem(index,item)">{{item.title}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        dataList:{
            type: Array,
            default(){
                return []
            }
        }
    },
    data(){
        return{
            selectIndex: 0
        }
    },
    methods:{
        clickItem(index,item){
            this.selectIndex = index
            this.$emit('change', index,item)
        }
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.tab-content-class{
    display: flex;
    .tab-item-class{
        display: flex;
        font-size: 14px;
        cursor: pointer;
        .sel-class{
            color: @defaultFontColor;
        }
        .normal-class{
            color: @mainColor;
        }
    }
}
</style>