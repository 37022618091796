/*
 * actions - 封装系统里各种数据请求接口。
 */
import Vue from "vue";
import qs from "qs";
import _ from "formats/typeof";

/**
 * 根据传入的url、method以及data数据发起请求，并返回一个promise对象。
 * @param {请求地址} url
 * @param {请求方法，'GET'或者'POST'或者'PUT'} method
 * @param {请求数据，应该为json对象} data
 */
export default function request(
  url,
  method = "GET",
  data,
  option = {
    headers: { "content-type": "application/json;charset=UTF-8" },
    responseType: "",
  },
  extra = { options: { url: "", method: "" } }, // 这个字段用于方便plugins中拓展一些功能
  isCompleteErr = false //是否显示完整报错信息（包含报错code）
) {
  if (!_.isObject(extra)) {
    return console.error("参数extra必须为对象 at src/request/index.js");
  }
  let payload = extra.options || {};
  payload.url = url;
  payload.method = method;
  payload.headers = payload.headers || option.headers;
  let headers = payload.headers;
  if (!payload.params && !payload.data) {
    // 如果extra.options中没有指定参数放置位置，使用默认策略
    if (method.toLocaleLowerCase() === "get") {
      // get方法默认将参数放到params（即用？的形式跟参数）
      payload.params = data;
    } else if (method === "multipleFILE") {
      // 多文件上传，已经是form-data
      return Vue.prototype.$http.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } else {
      if (option.headers["content-type"] !== "application/json;charset=UTF-8") {
        payload.data = qs.stringify(data);
      } else {
        payload.data = data;
      }
      // 下载文件时，特殊处理数据
      let responseType = option.responseType;
      if (responseType === "arraybuffer" || responseType === "blob") {
        headers["Content-Type"] = option.headers["content-type"];
        if (option.headers.filename) {
          headers["filename"] = option.headers.filename;
        }
        headers["responseType"] = responseType;
        return Vue.prototype.$http.post(url, data, headers).catch((err) => {
          return handleError(err, isCompleteErr);
        });
      }
    }
  }
  return Vue.prototype.$http(payload).catch((err) => {
    return handleError(err, isCompleteErr);
  });
}

export function handleError(err, isCompleteErr) {
  let message = "";
  console.error(err.response);
  let data = err.response;
  message = isCompleteErr ? { data: parseError(data), code: data.status } :
    parseError(data);
  return Promise.reject(message);
}

export function parseError(data) {
  let { status } = data;
  let { detail } = data.data;
  let title = data.data.title;
    detail = detail || title || "请求错误";
    console.log('status',status)
  if (status >= 500) {
    return "好像出了点意外，请稍后重试";
  } else if (status === 400) {
    return detail;
  } else if (status === 401) {
      console.log('Vue.$auth.logout()',Vue.auth)
        Vue.auth.logout()
      return "您未登录或登录已过期，请重新登录";
  } else if (status === 403) {
    return "您没有访问该信息的权限，请切换账号或联系系统管理员";
  } else if (status === 404) {
    return "未找到相关资源";
  } else if (status === 405) {
    return "该接口不支持指定的方法";
  }
  return "未知错误";
}