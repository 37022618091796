<template>
    <div>
        <el-card v-show="!isAdd">
            <div class="pt-case-data-class">
                <div class="flex-class cd-top-content-class">
                    <div class="sub-title-class">案例数据</div>
                    <el-button class="upload-btn-class" type="primary" icon='el-icon-plus' @click="uploadCase">上传案例</el-button>
                    <el-button class="download-btn-class" type="primary" icon='el-icon-plus' @click="downloadCases">批量下载</el-button>
                    <div class="input-content-class">
                        <el-input class="input-class" placeholder='请输入关键字' v-model="form.example.keyword" :clearable="true"></el-input>
                    </div>
                    <el-button class="search-class" type="primary" @click="search">搜索</el-button>
                </div>
                <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="tableData" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column prop="fileName" label="文件名" width='300'></el-table-column>
                    <el-table-column prop="caseCount" label="病例数量"> </el-table-column>
                    <el-table-column prop="fileSize" label="文件大小"> </el-table-column>
                    <el-table-column prop="createdDate" label="上传时间">
                        <template slot-scope="scope">
                            <span>{{formatDateObject(scope.row.createdDate)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="操作">
                        <template slot-scope="scope">
                            <div class="flex-class">
                                <div class="del-btn-class" @click="delCase(scope.row)">删除</div>
                                <el-divider direction="vertical"></el-divider>
                                <div class="edit-btn-class" @click="downloadCase(scope.row)">下载</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <ptPagination ref="ptPagination" @paginationchange='paginationchange'></ptPagination>
            </div>
        </el-card>
        <caseDataAdd v-show="isAdd" v-if="isAdd" :item='item' @click="caseDataAdd"/>
    </div>
</template>

<script>
import pageheader from '../../base/pt-page-header/index'
import { caseHost, delCaseHost } from '../../../utils/hostServices'
import { formatDateObject } from '../../../utils/formatUtils/date'
import ptPagination from '../../base/pt-pagination/index'
import caseDataAdd from './case-data-add'
import { Loading } from 'element-ui'
export default {
  components: {
    pageheader,
    ptPagination,
    caseDataAdd
  },
  props:{
        item: {
            type: Object,
            default(){
                return {}
            }
        }
    },
    data(){
        return{
            loadingInstance: null,
            isAdd: false,
            form: {
                pageable:{
                    page: 0,
                    size: 10
                },
                example:{}
            },
            total: 0,
            multipleSelection: [],
            tableData: []
        }
    },
    created (){
        
    },
    methods:{
        getData(){
            this.form.example.deviceBaseInfoId = (this.item.deviceBaseInfoId ? this.item.deviceBaseInfoId : this.item.id)
            caseHost(this.form).then((res)=>{
                this.tableData = res.data
                this.$refs.ptPagination.totalMethod(res)
            })
        },
        formatDateObject(val){
            return formatDateObject(val)
        },
        paginationchange(pageable){
            this.form.pageable = pageable
            this.getData()
        },
        search(){
            this.form.pageable.page = 0
            this.getData()
        },
        uploadCase(){
            this.isAdd = true
        },
        caseDataAdd(val){
            this.isAdd = false
            if (val === 1){
                this.form.pageable = {page: 0, size: 10}
                this.getData()
            }
        },
        delCase(row){
            this.$confirm('确认删除该案例?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loadingInstance = Loading.service({text: '请稍等'})
                delCaseHost(row.id).then(res=>{
                    this.$message({
                    message: '删除成功',
                    type: 'success'
                    });
                    this.$nextTick(() => {
                        this.loadingInstance.close();
                    });
                    this.form.pageable = {page: 0, size: 10}
                    this.getData()
                }).catch(err=>{
                    this.$nextTick(() => {
                      this.loadingInstance.close();
                    });
                    this.$message.error(err||'删除失败')
                })
            })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        downloadCases(){
            for (let index in this.multipleSelection){
                let item = this.multipleSelection[index]
                this.downloadCase(item)
            }
        },
        downloadCase(row){
            if (row.filePath){
                let url = row.filePath
                window.open(this.$privateResourcesUrlDownload + url)
                // fetch(url).then(res => res.blob()).then(blob => {
                //     let a = document.createElement("a");
                //     a.href = URL.createObjectURL(blob)
                //     a.download = url.split('/')[url.split('/').length -1]
                //     document.body.appendChild(a)
                //     a.click()
                // })
            }
        }
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.pt-case-data-class{
max-width: 100%;
  padding: 20px;
  .flex-class{
      display: flex;
  }
  .cd-top-content-class{
      align-items: center;
      .upload-btn-class{
          margin-left: 20px;
      }
  }
  .sub-title-class{
        text-align: left;
        font-size: 14px;
        font-weight: 600;
    }
  .el-button{
      background-color: @mainColor;
  }
  .input-content-class{
      width: 200px;
      margin-right: 20px;
  }
  .del-btn-class{
    cursor: pointer;
    color: @warningColor;
  }
  .edit-btn-class{
    cursor: pointer;
    color: @mainColor;
  }
  .search-class{
    margin-right: 0;
  }
  .download-btn-class{
    margin-right: 20px;
    margin-left: auto !important;
  }
}
</style>