<template>
    <el-card class="pt-abstract-class">
        <div class="sub-title-class">客户机构</div>
        <el-table style="width: 100%;margin-top:20px;" :stripe="true" :data="dataList">
            <el-table-column prop="name" label="机构名称"></el-table-column>
            <el-table-column prop="organizationInfo" label="类型">
                <template slot-scope="scope">
                    <span v-if="scope.row.type === 'HOSPITAL'">医疗机构</span>
                    <span v-else>非医疗机构</span>
                </template>
            </el-table-column>
            <el-table-column prop="uniformCode" label="统一信用代码"> </el-table-column>
            <el-table-column prop="region" label="地区">
                <template slot-scope="scope">
                    <span>{{scope.row.region.province}}/{{scope.row.region.city}}/{{scope.row.region.district}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="地址"></el-table-column>
            <el-table-column prop="" label="操作">
                <template slot-scope="scope">
                    <div class="flex-class">
                        <div class="edit-btn-class" @click="detail(scope.row)">详情</div>
                        <el-divider direction="vertical"></el-divider>
                        <div class="edit-btn-class" @click="deleteIns(scope.row)">移除</div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-divider></el-divider>
        <describe :item='item' />
        <el-divider></el-divider>
        <el-button @click="back">返回</el-button>
        <el-button type="primary" @click="edit">编辑</el-button>
    </el-card>
</template>
<script>
import describe from './describe'
import { edit } from '../../../utils/hostServices'
export default {
    components:{
        describe
    },
    props:{
        item: {
            type: Object,
            default(){
                return {}
            }
        }
    },
    data(){
        return{
            dataList: []
        }
    },
    watch:{
        item:{
            handler: function (val) {
                if (this.item.organizationInfo){
                    this.dataList = [this.item.organizationInfo]
                } else {
                    this.dataList = []
                }
            }
        }
    },
    mounted(){
        
    },
    methods:{
        detail(row){
            let deviceBaseInfoId = this.item.deviceBaseInfoId ? this.item.deviceBaseInfoId : this.item.id
            this.$router.push('/institutionsdetail?type=host&id=' + row.id + '&deviceBaseInfoId=' + deviceBaseInfoId)
        },
        deleteIns(row){
            this.$confirm('确定移除该主机机构?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = JSON.parse(JSON.stringify(this.item))
                data.organizationInfo = null
                edit(data).then(res=>{
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.$emit('upload')
                }).catch(err=>{
                    this.$message.error(err||'删除失败')
                })
            })
        },
        back(){
            this.$router.push('/host')
        },
        edit(){
            let id = this.item.deviceBaseInfoId ? this.item.deviceBaseInfoId : this.item.id
            this.$router.push('/hostadd?type=edit&id=' + id)
        }
    }
}
</script>
<style lang="less">
@import "~less/var.less";
.pt-abstract-class{
    height: 100%;
    .el-table th.el-table__cell.is-leaf{
        border-bottom:0px solid #F1F1F1;
    }
    .el-table__body tr.current-row>td{
        background-color: @mainColor !important;
        color: #fff;
    }
    .flex-class{
        display: flex;
				align-items: center;
				.edit-btn-class{
					display: flex;
					align-items: center;
				}
				.el-divider--vertical{
					display: flex;
					align-items: center;
				}
    }
    .sub-title-class{
        text-align: left;
        font-size: 14px;
        font-weight: 600;
    }

	.el-table th.el-table__cell.is-leaf{
		border-bottom:solid 1Px #bdbdbd;
		border-top:solid 1Px #bdbdbd;
		border-bottom-color: rgba(0, 0, 0, 0.1);
		border-top-color: rgba(0, 0, 0, 0.1);
		background: rgba(0,0,0,0.04);
		color: #282828;
	}
}
</style>