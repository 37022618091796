<template>
<div>
  <pt-useredit class="pt-useredit__page"></pt-useredit>
</div>
</template>

<script>
import PtUserEdit from 'cps/pt-user/edit'
export default {
  name: 'pt-useredit__view',
  components: {
    'pt-useredit': PtUserEdit
  }
}
</script>

