<template>
  <div class="pt-useredit__withData">
    <pageheader :title="title"></pageheader>
    <el-card>
      <div class="form-content-class">
        <el-form ref="ruleForm" label-position='left' :model="form" :rules="rules"
          label-width="150px" size="medium">
          <el-form-item label="用户名:" prop='username'>
            <el-input placeholder='请输入用户名' v-model="form.username"
              @keyup.native="onkeyup('username')"></el-input>
          </el-form-item>
          <el-form-item label="手机号:" prop='mobile'>
            <el-input placeholder='请输入手机号' onkeyup="value=value.replace(/[^\d]/g,'')"
              v-model="form.mobile" @keyup.native="onkeyup('mobile')"></el-input>
          </el-form-item>
          <el-form-item label="姓名:" prop='firstName'>
            <el-input placeholder='请输入姓名' v-model="form.firstName"
              @keyup.native="onkeyup('firstName')"></el-input>
          </el-form-item>
          <el-form-item label="性别:" prop='gender'>
            <el-radio-group v-model="form.gender">
              <el-radio label="MALE">男</el-radio>
              <el-radio label="FEMALE">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="员工编号:" prop="serialNumber" class="no-requery-left-class">
            <el-input placeholder='请输入员工编号' v-model="form.serialNumber"
              @keyup.native="onkeyup('serialNumber')"></el-input>
          </el-form-item>
          <el-form-item label="新密码:" prop='password'
            :class="type==='edit'?'no-requery-left-class':''">
            <el-input placeholder='请输入新密码' v-model="form.password" class="password-show" type="text"
              @keyup.native="onkeyup('password')"></el-input>
          </el-form-item>
          <el-form-item label="请再次输入新密码:" prop='passwordagain'
            :class="type==='edit'?'no-requery-left-class':''">
            <el-input placeholder='请再次输入新密码' v-model="form.passwordagain" class="password-show"
              type="text" @keyup.native="onkeyup('passwordagain')"></el-input>
          </el-form-item>
          <el-form-item label="用户角色:" prop='groups'>
            <el-select v-model="form.groups" placeholder="请选择">
              <el-option v-for="item in roleList" :key="item.id" :label="item.displayName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button v-if="type === 'edit'" @click="delForm('ruleForm')">删除</el-button>
            <el-button @click="resetForm('ruleForm')">取消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import pageheader from '../base/pt-page-header/index'
import { getGroupList, getGroupDetail, delGroupDetail, createGroupDetail, updateGroupDetail } from '../../utils/userService'
import { Loading } from 'element-ui'
import validate from '../../utils/formatUtils/validate'
var phone = (rule, value, callback) => {
  if (/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(value)) {
    callback();
  } else {
    callback(new Error('请输入正确格式的手机号'));
  }
}
var password = (rule, value, callback) => {
  if (/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*.`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*.`~()-+=]+$)(?![0-9\\W_!@#$%^&*.`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*.`~()-+=]{8,30}$/.test(value) || !value) {
    callback();
  } else {
    callback(new Error('请输入8至32位至少包含大写、小写字母，数字和特殊字符其中三种作为密码'));
  }
}
var serialNumber = (rule, value, callback) => {
  if (/[A-Za-z0-9]$/.test(value) || !value) {
    callback();
  } else {
    callback(new Error('员工编号只能包含数字和字母'));
  }
}
export default {
  name: 'pt-user__withData',
  components: {
    pageheader
  },
  data () {
    return {
      loadingInstance: null,
      type: 'add',
      title: '新增系统用户',
      rules: {
        username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          { pattern: /^[0-9a-zA-Z_]{3,20}$/g, message: '只能由3-20位字母、数字、下划线组成', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { validator: phone, trigger: 'blur' }
        ],
        firstName: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        gender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        password: [
          { required: true, message: '新密码不能为空', trigger: 'blur' },
          { validator: password, trigger: 'blur' }
        ],
        passwordagain: [
          { required: true, message: '请重新输入新密码', trigger: 'blur' },
          { validator: password, trigger: 'blur' }
        ],
        groups: [
          { required: true, message: '请选择用户角色', trigger: 'change' }
        ],
        serialNumber: [
          { validator: serialNumber, trigger: 'blur' }
        ]
      },
      roleList: [],
      id: '',
      form: {}
    }
  },
  created () {
    this.type = this.$route.query.type
    if (this.type === 'add') {
      this.title = '新增系统用户'
    } else if (this.type === 'edit') {
      this.title = '编辑系统用户'
      this.id = this.$route.query.id
      this.rules.password = [
        { validator: password, trigger: 'blur' }
      ]
      this.rules.passwordagain = [
        { validator: password, trigger: 'blur' }
      ]
      // 获取用户详情
      this.getDetail()
    }
    this.getGroup()
  },
  methods: {
    onkeyup (name) {
      let vm = this
      setTimeout(function () {
        vm.$refs.ruleForm.validateField(name)
      }, 1000);
    },
    getDetail () {
      getGroupDetail(this.id).then(res => {
        this.form = res.data
        this.form.groups = _.isArray(this.form.groups) ? (this.form.groups && this.form.groups[0]) : this.form.groups
      }).catch(err => {
        this.$message.error(err || '获取数据失败')
      })
    },
    getGroup () {
      getGroupList().then((res) => {
        this.roleList = res.data
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitData()
        } else {
          return false;
        }
      })
    },
    submitData () {
      if (this.form.password !== this.form.passwordagain) {
        this.$message({
          message: '两次密码不一致',
          type: 'error'
        });
        return
      }
      if (this.type === 'add') {
        this.loadingInstance = Loading.service({ text: '请稍等' })
        let parame = { doctor: this.form, password: this.form.password }
        createGroupDetail(parame).then(res => {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.$nextTick(() => {
            this.loadingInstance.close();
          });
          this.$router.push('/user')
        }).catch(err => {
          this.$nextTick(() => {
            this.loadingInstance.close();
          });
          this.$message.error(err || '保存失败')
        })
      } else {
        this.loadingInstance = Loading.service({ text: '请稍等' })
        let parame = { doctor: this.form, password: this.form.password }
        updateGroupDetail(parame).then(res => {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.$nextTick(() => {
            this.loadingInstance.close();
          });
          this.$router.push('/user')
        }).catch(err => {
          this.$nextTick(() => {
            this.loadingInstance.close();
          });
          this.$message.error(err || '保存失败')
        })
      }

    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
      this.$router.push('/user')
    },
    delForm (formName) {
      this.$confirm('确认删除该用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除用户
        this.loadingInstance = Loading.service({ text: '请稍等' })
        delGroupDetail(this.id).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.$nextTick(() => {
            this.loadingInstance.close();
          });
          this.$router.push('/user')
        }).catch(err => {
          this.$nextTick(() => {
            this.loadingInstance.close();
          });
          this.$message.error(err || '删除失败')
        })
      })
    }
  }

}
</script>
<style lang="less">
@import "~less/var.less";
.pt-useredit__withData{
max-width: 100%;
  padding: 0 20px 20px 20px;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  .el-card{
    overflow-y: auto!important;
  }
	.el-form-item__error{
		width: 240%!important;
	}
	.password-show{
		-webkit-text-security:disc
	}
}
.el-form-item--medium .el-form-item__content, .el-form-item--medium .el-form-item__label{
    margin-left: 0 !important;
}
.el-form-item__error{
    width: 150% !important;
    text-align: left;
}
</style>